import { ContentBoxHead, ContentBoxBody, ContentBox } from 'sg-ui-components';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

/**********************************************************************
 * Component:  CantFindConfirmation
 * Purpose:    Simple Component that tells user to call customer service
 *             if they can't find their confirmation email after the timeout
 *             period
 *
 * Props:      none
 *
 * APIs used:   None
 */
export const CantFindConfirmation = () => {
    return (
        <ContentBox variant='theme-blue mx-2'>
            <ContentBoxHead>Can&apos;t Find Your Confirmation Email?</ContentBoxHead>
            <ContentBoxBody>
                <img src='jurisdiction_logo.png' className='img-fluid mx-auto my-3' />
                <p className='text-center'>
                    It seems like you can&apos;t find or did not receive your confirmation email. Fill our a help form and we will contact you to verify.
                </p>
                <p className='text-center'>
                    <HashLink to='/feedback#contact'>
                        <button type='button' className='btn theme-btn theme-primary btn-lg'>
                            Contact Us
                        </button>
                    </HashLink>
                </p>
            </ContentBoxBody>
        </ContentBox>
    );
};
