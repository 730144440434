import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import getCookieByName from '../../utils/getCookieByName';
import { setCookieValueByName } from '../../utils/setCookieValueByName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../utils/fontAwesome';
function getByPropertyValue(arr, property, value) {
    var result = arr === null || arr === void 0 ? void 0 : arr.filter(function (item) {
        return item[property] === value;
    });
    return result !== null && result !== void 0 ? result : null;
}
// return an array of objects where the current date and time
// falls inside the start and end dates if it is supposed to show all day
// otherwise, return objects where the current time falls inside the
// current time of day and we are inside the
function getByDateRange(arr) {
    if (arr === null) {
        return undefined;
    }
    var now = new Date();
    var nowTime = now.getTime();
    var result = arr === null || arr === void 0 ? void 0 : arr.filter(function (item) {
        var thisStartDate = new Date(item.startDate);
        var thisEndDate = new Date(item.endDate);
        var nowHour = now.getHours();
        var nowMin = now.getMinutes();
        var thisStartTime = new Date(item.startTime);
        var thisEndTime = new Date(item.endTime);
        var startHour = thisStartTime.getHours();
        var startMin = thisStartTime.getMinutes();
        var endHour = thisEndTime.getHours();
        var endMin = thisEndTime.getMinutes();
        // if the item is flagged to be shown all day, check that we are in the
        // date and time range and return the object
        if (item.allDay) {
            return thisStartDate.getTime() <= nowTime && nowTime <= thisEndDate.getTime();
        }
        else {
            // if the item is not flagged to be shown all day, check that we are
            // in the date and time range, and that the current time of day
            // falls within the start and end times
            return (thisStartDate.getTime() <= nowTime &&
                nowTime <= thisEndDate.getTime() &&
                startHour <= nowHour &&
                startMin <= nowMin &&
                (endHour > nowHour || (endHour === nowHour && endMin >= nowMin)));
        }
    });
    return result;
}
// take the array of notices and filter out those with id values that
// are in the cull array we got from the cookie
function cullDismissed(inputArray, cullArray) {
    var result = inputArray === null || inputArray === void 0 ? void 0 : inputArray.filter(function (item) {
        return !cullArray.includes(item.id);
    });
    return result;
}
// return the window / documentElement / body width in pixels
var getWidth = function () {
    if (![0, NaN].includes(window.innerWidth)) {
        return window.innerWidth;
    }
    if (![0, NaN].includes(document === null || document === void 0 ? void 0 : document.documentElement.clientWidth)) {
        return document === null || document === void 0 ? void 0 : document.documentElement.clientWidth;
    }
    return document === null || document === void 0 ? void 0 : document.body.clientWidth;
};
// this component is called from the CMSContent component
var WebNoticeComponent = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(false), displayNow = _f[0], setDisplayNow = _f[1];
    var _g = useState(0), addedPaddingTop = _g[0], setAddedPaddingTop = _g[1];
    var _h = useState(0), addedPaddingBottom = _h[0], setAddedPaddingBottom = _h[1];
    var _j = useState(getWidth()), width = _j[0], setWidth = _j[1];
    var noticeRef = useRef(null);
    // if the value for the padding to add to the top changes, apply that
    // new style value to the #root element
    useEffect(function () {
        var rootElement = document === null || document === void 0 ? void 0 : document.getElementById('root');
        if (rootElement != null) {
            rootElement.style.paddingTop = "".concat(addedPaddingBottom, "px");
        }
    }, [addedPaddingTop]);
    // if the value for the padding to add to the bottom changes, apply that
    // new style value to the #root element
    useEffect(function () {
        var rootElement = document === null || document === void 0 ? void 0 : document.getElementById('root');
        if (rootElement != null) {
            rootElement.style.paddingBottom = "".concat(addedPaddingBottom, "px");
        }
    }, [addedPaddingBottom]);
    // put a listener on the window resize to adjust the height added to
    // the #root element when the window's width changes
    useEffect(function () {
        // timeoutId for debounce mechanism
        var timeoutId = null;
        var resizeListener = function () {
            // prevent execution of previous setTimeout
            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(function () {
                setWidth(getWidth());
            }, 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
        // clean up function
        return function () {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, [width]);
    // if the notice parent container changes in height, update the padding value
    // for either the top or bottom of the #root element
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (((_a = noticeRef === null || noticeRef === void 0 ? void 0 : noticeRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) != null && ((_c = (_b = props === null || props === void 0 ? void 0 : props.className) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 0) {
            if (((_d = props === null || props === void 0 ? void 0 : props.className) === null || _d === void 0 ? void 0 : _d.includes('fixed-top')) === true) {
                setAddedPaddingTop(noticeRef.current.clientHeight);
            }
            else if (((_e = props === null || props === void 0 ? void 0 : props.className) === null || _e === void 0 ? void 0 : _e.includes('fixed-bottom')) === true) {
                setAddedPaddingBottom(noticeRef.current.clientHeight);
            }
        }
    }, [(_a = noticeRef === null || noticeRef === void 0 ? void 0 : noticeRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight]);
    // use a delay to allow the CMS content to be retrieved before showing
    // children. This keep us from having a flash of the children before
    // they are replaced by the notice
    useEffect(function () {
        var timer = setTimeout(function () {
            setDisplayNow(true);
        }, props.delayToShow);
        return function () {
            clearTimeout(timer);
        };
    }, [props.delayToShow]);
    var dismissNoticeHandler = function (noticeId, parentId) {
        var _a;
        var noticeElement = document.getElementById(noticeId);
        var parentElement = document.getElementById(parentId);
        // get cookie with array of dismissed notices, or create new empty array
        var dismissedNotices = !document.cookie.includes('dismissedNotices=') ? [] : JSON.parse((_a = getCookieByName('dismissedNotices')) !== null && _a !== void 0 ? _a : '');
        // add this notice to the array of dismissed notices
        dismissedNotices.push(noticeId);
        // write the updated array to the cookie
        setCookieValueByName('dismissedNotices', JSON.stringify(dismissedNotices));
        // remove the DOM element
        noticeElement === null || noticeElement === void 0 ? void 0 : noticeElement.remove();
        // get the new height of the parent container element
        var parentElementHeight = parentElement === null || parentElement === void 0 ? void 0 : parentElement.clientHeight;
        // adjust the padding to the new height of the parent element
        if ((parentElement === null || parentElement === void 0 ? void 0 : parentElement.className.includes('fixed-top')) === true && parentElementHeight != null) {
            setAddedPaddingTop(parentElementHeight);
        }
        else if ((parentElement === null || parentElement === void 0 ? void 0 : parentElement.className.includes('fixed-bottom')) === true && parentElementHeight != null) {
            setAddedPaddingBottom(parentElementHeight);
        }
    };
    // if there are no notices at all, return any passed children
    if (((_d = (_c = (_b = props.noticeData) === null || _b === void 0 ? void 0 : _b.notices) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) === 0) {
        // once the delay has elapsed, return the children
        return displayNow ? _jsx(_Fragment, { children: props.children }) : null;
    }
    else {
        // start thinning out the array of notices
        // only look at active notices, only look at notices that match the requested placement,
        // only look at the notices that correctly contain the current date and time range
        var matchingArray = getByPropertyValue(props.noticeData.notices, 'active', true);
        matchingArray = getByPropertyValue(matchingArray, 'noticePlacement', props.noticePlacement);
        matchingArray = getByDateRange(matchingArray);
        // if the dismissedNotices cookie exists, remove the dismissed notices from the matching array
        if (document.cookie.includes('dismissedNotices=')) {
            matchingArray = cullDismissed(matchingArray, JSON.parse((_e = getCookieByName('dismissedNotices')) !== null && _e !== void 0 ? _e : ''));
        }
        if (matchingArray === undefined || matchingArray.length === 0) {
            // if there are no notices that match, return any passed children
            // once the delay has elapsed
            return displayNow ? _jsx(_Fragment, { children: props.children }) : null;
        }
        else {
            return (_jsx("div", { ref: noticeRef, id: props.noticePlacement, className: props.className, children: matchingArray.map(function (notice) {
                    var noticeId = notice.id.replace(/\s+/g, '-').toLowerCase();
                    var parentId = props.noticePlacement;
                    return (_jsxs("div", { id: noticeId, className: "alert web-notice ".concat(notice.noticeStyle, " ").concat(notice.dismissible ? 'alert-dismissible' : ''), role: 'alert', children: [_jsxs("div", { className: 'web-notice-heading', children: [(notice === null || notice === void 0 ? void 0 : notice.noticeIcon) !== undefined && _jsx(FontAwesomeIcon, { icon: notice.noticeIcon }), _jsx("h4", { className: 'alert-heading', children: notice.noticeHeading }), notice.dismissible && (_jsx("button", { type: 'button', title: 'close', className: 'close', "aria-label": 'Close', onClick: function () {
                                            dismissNoticeHandler(noticeId, parentId);
                                        }, children: _jsx("span", { "aria-hidden": 'true', children: "\u00D7" }) }))] }), _jsx("hr", {}), _jsx("div", { className: 'web-notice-body', dangerouslySetInnerHTML: { __html: notice.noticeMessage } })] }, noticeId));
                }) }));
        }
    }
};
export default WebNoticeComponent;
