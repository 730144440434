import React from 'react';
import { connect } from 'react-redux';
import { FeedbackForm, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const ContactUsPage = ({ user, config, cmsSourceFirebase, actions, match, feedbackCategories, promotion, loading, feedback }) => {
    const feedbackFormTelescript = getCMSObject('data.components.teleScripts.feedback.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <FeedbackForm
                user={user}
                actions={actions}
                feedbackCategories={feedbackCategories}
                promotion={promotion}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                feedback={feedback}
                telescript={feedbackFormTelescript}
                options={{
                    variant: 'theme-primary',
                    hasCollapser: false,
                    hash: '',
                }}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
