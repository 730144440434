import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import LotteryPromotionDetails from '../Components/Promotions/LotteryPromotionDetails';
import Layout from '../Components/Layout';

const LotteryPromotionDetailsPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <LotteryPromotionDetails />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryPromotionDetailsPage);
