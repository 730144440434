import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import LotteryDrawingTable from './LotteryDrawingTable';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isBrowser } from 'sg-ui-components';

const LotteryPromotionDetails = ({ config, promotion, actions }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [preselection, setPreselection] = useState({ promotionId: null });

    useEffect(() => {
        //* Retrieve ID from url params
        const queryString = isBrowser() ? window.location.search : '';
        const urlParams = new URLSearchParams(queryString);

        setPreselection({
            promotionId: parseInt(urlParams.get('id')),
        });

        async function fetchData() {
            if (!pageLoaded) {
                if (promotion?.externalPromotions?.length === 0) {
                    let baseUrl = config?.config?.lottery_host;
                    const endpointUrl = 'api/promotions';

                    if (baseUrl) {
                        await actions.promotionActions.getExternalPromotions({
                            base_url: baseUrl,
                            endpoint_url: endpointUrl,
                            auth: false,
                        });

                        setPageLoaded(true);
                    }
                }
            }
        }

        fetchData();
    }, [config?.config?.lottery_host]);

    //* Validation check
    if (!promotion?.externalPromotions?.length) return null;

    const lotteryPromotion = promotion.externalPromotions.filter((s) => s.id === preselection.promotionId)[0];
    if (!lotteryPromotion) return null;

    const image = lotteryPromotion.PromotionImage ?? null;
    const description = lotteryPromotion.LongDescription ?? null;
    const drawings = lotteryPromotion.Drawings ?? null;
    const prizes = lotteryPromotion.Prizes ?? null;
    const faqs = lotteryPromotion.FAQ ?? null;
    const rules = lotteryPromotion.Rules ?? null;

    return (
        <div className='promotion-details'>
            <div className='mb-3'>
                {image ? (
                    <Row>
                        <Col lg={4} className='my-3'>
                            <img src={image} alt={lotteryPromotion.title} className='w-100 rounded-image' />
                        </Col>
                        <Col lg={8} className='my-3'>
                            <h1 className='title'>{lotteryPromotion.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={12} className='my-3'>
                            <h3>{lotteryPromotion.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Col>
                    </Row>
                )}
            </div>

            <ContentBox className='promotion-page-section my-5'>
                <ContentBoxHead>
                    Drawings
                    <ContentCollapser />
                </ContentBoxHead>
                <ContentBoxBody>
                    <LotteryDrawingTable drawings={drawings} />
                </ContentBoxBody>
            </ContentBox>

            {prizes && (
                <ContentBox className='promotion-page-section my-5'>
                    <ContentBoxHead>
                        Prizes
                        <ContentCollapser />
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div dangerouslySetInnerHTML={{ __html: prizes }}></div>
                    </ContentBoxBody>
                </ContentBox>
            )}

            {faqs && (
                <ContentBox className='promotion-page-section my-5'>
                    <ContentBoxHead>
                        FAQs
                        <ContentCollapser />
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div dangerouslySetInnerHTML={{ __html: faqs }}></div>
                    </ContentBoxBody>
                </ContentBox>
            )}

            {rules && (
                <ContentBox className='promotion-page-section my-5'>
                    <ContentBoxHead>
                        Rules
                        <ContentCollapser />
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div dangerouslySetInnerHTML={{ __html: rules }}></div>
                    </ContentBoxBody>
                </ContentBox>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryPromotionDetails);
