import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent } from 'sg-ui-components';
import * as yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { CheckPassword } from '../ui-components/CheckPassword';

import { RegistrationOptins } from '../Optins/optin_form';
import { PasswordField } from './password_field';
import { validatePassword } from '../../validationRules';
import { errorMessage } from '../errorMessages';
import SiteConfig from '../../promotion_config';

/**********************************************************************
 * Component:  StepOneTemplate
 * Purpose:    Allows users to enter in username/password for an "account"
 *             an RAF code (from URL or handtyped) may be provided to give the user a reward once
 *             completely confirmed.
 *
 * Props:       step -  counter of what step we are in the registration process
 *              setStep -  a method to change steps in the process
 *              verifyFields -  fields that we will verify upon completion of form
 *              setVerifyFiels - method to set the verification fields
 *              user -  user data store
 *              actions - store actions (apis)
 *              config - config store
 *
 *  Other:    code -  RAF code that can come in from the URL.
 *
 * APIs used:   userActions.register
 *
 *  Notes:
 */
const StepOneTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, actions, cmsSourceFirebase, config }) => {
    const initialStepFields = {
        email: user.player.email,
        confirm_email: '',
        password: '',
        confirm: '',
        rafcode: '',
        ageAgreement: false,
        agreement: false,
        optins: [],
    };

    // Validation Schemas for registration fields
    const schema = yup.object().shape({
        email: yup.string().email('Email is invalid.').required('Email is required.').max(200, 'Email cannot exceed 200 characters.'),

        confirm_email: yup
            .string()
            .required('Email confirmation required.')
            .oneOf([yup.ref('email'), null], 'Email address must match.'),

        password: validatePassword,
        confirm: yup
            .string()
            .required('Password Confirmation is required.')
            .oneOf([yup.ref('password'), null], 'Passwords must match.'),
        ageAgreement: yup.boolean().oneOf([true], 'Please certify that you are over 18 years old.'),
        agreement: yup.boolean().oneOf([true], 'Please agree to the terms before continuing.'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [passwordStatus] = useState('initial');
    const [localErrors, setLocalErrors] = useState({});

    //***********************************************************************************
    // Cleans input from the email and confirm email fields
    const cleanInput = (name, value) => {
        let output = value;

        // remove all whitespaces
        if (['email', 'confirm_email'].includes(name)) {
            output = output.replace(/\s/gi, '');
        }

        return output;
    };

    //***********************************************************************************
    // Handle if the the form field changes, change the store values when needed.
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : cleanInput(target.name, target.value);
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'password') {
            CheckPassword(value);
        }
    };

    //***********************************************************************************
    //  ParentCallback for the OptinForm -  gets the optin ids and if they are
    //  selected or not and then filters the ones that are selected to pass
    //  in the registration payload.
    const handleOptinChangeCb = (optinData) => {

        if (optinData) {
            optinData.forEach((value) => {
                if (value.checkedState === false) {
                    let index = stepFields.optins.indexOf(value.optin);
                    if (index != - 1) {
                        stepFields.optins.splice(index, 1);
                    }
                } else {
                    // only push if its not already there.
                    if (!stepFields.optins.some((obj) => obj.type === value.optin.type)) {
                        stepFields.optins.push(value.optin);
                    }
                }
            });
        } else {
            console.log('optinData not set');
        }
    }; // end handleOptinChangeCb

    //***********************************************************************************
    //  Send initial registration payload
    //
    const initialRegister = async () => {
        await actions.userActions.register(stepFields);
    }; // end initialRegister

    //***********************************************************************************
    //  Validate the form fields based on the schema, report errors or proceed to initial
    //  registration
    //
    const firstStepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);
        if (valid) {
            setError('');
            setLocalErrors({});
            setVerifyFields({
                ...verifyFields,
                email: stepFields.email,
            });
            await initialRegister();
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
        if(document.getElementById('alert')) {
            document.getElementById('alert').scrollIntoView(true);
        }
    }; // end firstStepSubmit

    //***********************************************************************************
    //  Gets any validation errors and updates the user
    //
    const getValidationErrors = (err) => {
        const validationErrors = {};
        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    }; // end getValidationErrors

    //***********************************************************************************
    //  Callback on initial registration.   Sets any errors if registration fails,
    //  otherwise logs user in.
    //
    const success = async () => {
        if (!user.registerSuccess) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.registerSuccess) {
                setError('');
                setLocalErrors({});
                await actions.userActions.clearErrors();
                await actions.userActions.login({
                    email: stepFields.email,
                    password: stepFields.password,
                });
            }
        }
    }; // end success

    //***********************************************************************************
    // Callback on login success. Sets any errors if login fails,
    // otherwise moves to registration step 2.
    //
    const loginSuccess = async () => {
        if (!user.loggedIn) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.loggedIn) {
                setError('');
                setLocalErrors({});
                setVerifyFields({
                    ...verifyFields,
                    email: user.email,
                });
                actions.userActions.clearErrors();
                setStep({ step: 2 });
            }
        }
    };

    useEffect(success, [user]);
    useEffect(loginSuccess, [user.loggedIn]);

    // Get the RAF field from URL if there is one present.
    useEffect(() => {
        async function clearErrors() {
            await actions.userActions.clearErrors();
            setLocalErrors('');
        }

        clearErrors();
        const rafFromUrl = new URLSearchParams(document.location.search).get('rafcode');

        if (rafFromUrl) {
            setStepFields({
                ...stepFields,
                rafcode: rafFromUrl,
            });
        }
    }, []);

    if (step.step == 1) {
        return (
            <div className='row justify-content-center'>
                <div className='col-12 col-md-10 col-lg-8'>
                    <ContentBox variant='theme-blue mx-2'>
                        <ContentBoxHead>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.registrationItems.registrationStepOne.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div className='form-step'>
                                <div className='inner-step'>
                                    <div className='col'>
                                        <CMSContent
                                            localStorageObject='webContent'
                                            contentPath='data.registrationItems.registrationStepOne.contentHTML'
                                            cmsSourceFirebase={cmsSourceFirebase}
                                        />
                                    </div>
                                    {error && localErrors && Object.entries(localErrors).length > 0 ? (
                                        <Alert id='alert' variant='danger'>
                                            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                            <ul className='alert-text'>
                                                {Object.values(localErrors).map((err, index) => {
                                                    return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                                })}
                                            </ul>
                                        </Alert>
                                    ) : null}

                                    {error && localErrors && Object.entries(localErrors).length === 0 ? (
                                        <Alert id='alert' variant='danger'>
                                            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                            <div className='alert-text' dangerouslySetInnerHTML={{ __html: errorMessage(error) }} />
                                        </Alert>
                                    ) : null}
                                    <div className='form-group'>
                                        <label htmlFor='enterEmail'>Email</label>
                                        <input
                                            type='email'
                                            className='form-control theme-input'
                                            id='enterEmail'
                                            value={stepFields.email}
                                            name='email'
                                            onChange={handleChange}
                                            aria-describedby='emailHelp'
                                            placeholder='Email'
                                        />
                                        <small id='emailHelp' className='form-text theme-text-muted'>
                                            username@domain.com
                                        </small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='confirmEmail'>Confirm Email</label>
                                        <input
                                            type='email'
                                            className='form-control theme-input'
                                            id='confirmEmail'
                                            value={stepFields.confirm_email}
                                            name='confirm_email'
                                            onChange={handleChange}
                                            aria-describedby='confirmEmailHelp'
                                            placeholder='Confirm Email'
                                        />
                                        <small id='confirmEmailHelp' className='form-text theme-text-muted'>
                                            Please retype your email address.
                                        </small>
                                    </div>

                                    <div className='form-group password-field'>
                                        <label htmlFor='password'>Password</label>
                                        <small id='passwordHelp' className='form-text theme-text-muted'>
                                            Your Password must be at least 10 characters long and include - 1 uppercase letter, 1 lowercase letter, 1 number,
                                            and 1 special character.
                                        </small>
                                        <PasswordField
                                            name='password'
                                            value={stepFields.password}
                                            handleChange={handleChange}
                                            placeholder='Password'
                                            newPassword={true}
                                        />
                                    </div>

                                    <div className='form-group password-field'>
                                        <label htmlFor='confirmPassword'>Confirm Password</label>
                                        <PasswordField
                                            name='confirm'
                                            value={stepFields.confirm}
                                            handleChange={handleChange}
                                            placeholder='Confirm Password'
                                            newPassword={true}
                                        />
                                        <small id='confirmHelp' className='form-text theme-text-muted'>
                                            Please retype your password.
                                        </small>
                                    </div>

                                    <div className='step-divider' />

                                    <div className='form-group'>
                                        <label htmlFor='rafcode'>Promotional Code ( optional )</label>
                                        <input
                                            type='rafcode'
                                            className='form-control theme-input'
                                            id='rafcode'
                                            defaultValue={stepFields.rafcode}
                                            name='rafcode'
                                            onChange={handleChange}
                                            placeholder=''
                                        />
                                        <small id='confirmRafHelp' className='form-text theme-text-muted'>
                                            Did a friend refer you? Enter your code above.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-section text-center mb-3'>
                                <div className='row justify-content-center'>
                                    <div className='col-8 text-left'>
                                        <RegistrationOptins parentCallback={handleOptinChangeCb} />
                                        <div className='form-check my-3'>
                                            <input
                                                type='checkbox'
                                                id='ageAgreement'
                                                name='ageAgreement'
                                                className='form-check-input'
                                                value={stepFields.ageAgreement}
                                                onChange={handleChange}
                                                checked={stepFields.ageAgreement}
                                            />
                                            <label htmlFor='ageAgreement' className='form-check-label'>
                                                I certify that I am at least 18 years old.
                                            </label>
                                        </div>
                                        <div className='form-check my-3'>
                                            <input
                                                type='checkbox'
                                                id='agreement'
                                                name='agreement'
                                                className='form-check-input'
                                                value={stepFields.agreement}
                                                onChange={handleChange}
                                                checked={stepFields.agreement}
                                            />
                                            <label htmlFor='agreement' className='form-check-label'>
                                                I agree to the
                                                <a href={`${config.config.lottery_host}/terms-conditions`} target='_blank' rel='noreferrer'>
                                                    {' '}
                                                    Terms and Conditions
                                                </a>{' '}
                                                of the {SiteConfig.jurisdictionName}.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-block my-3'>
                                    <button type='button' className='btn theme-btn theme-secondary' onClick={firstStepSubmit}>
                                        Continue Registration
                                    </button>
                                </div>
                                <div className='d-block'>
                                    <Link to='/login'>Already have an account? Login Now</Link>
                                </div>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepOne = connect(mapStateToProps, mapDispatchToProps)(StepOneTemplate);

export { StepOne };
