import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { TableMobile, TableDesktop } from 'sg-ui-components';

const LotteryDrawingTable = ({ drawings }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setPageLoaded(true);
    }, []);

    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    const entriesTable = (data) => {
        const entries = data ?? [];
        let displayHeaders = {
            entryStart: 'ENTRY START',
            entryDeadline: 'ENTRY DEADLINE',
            drawingDate: 'DRAWING DATE',
        };
        const displayRows = entries.map((entry) => ({
            ...entry,
            fields: {
                entryStart: entry.field_entry_start ?? 'N/A',
                entryDeadline: entry.field_entry_deadline ?? 'N/A',
                drawingDate: entry.field_drawing_date ?? 'N/A',
            },
        }));

        const displayOptions = {
            tableId: 'entryTable',
            isLoaded: pageLoaded,
            messageNoRows: 'No drawings',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
            </>
        );
    };

    return (
        <div className='row'>
            <div className='col'>{entriesTable(drawings)}</div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryDrawingTable);
