var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
export var defaultState = {
    pointsForDrawings: [],
    playerPFDTransactions: {
        current_page: undefined,
        data: [],
        total: undefined,
        transactionsIds: {},
    },
};
// REDUCERS
// basic actions that affect this object only
var getPointsForDrawingsReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), pointsForDrawings: action.payload });
};
var getPlayerPFDTransactionsReducer = function (state, action) {
    var _a, _b, _c;
    if (!(action === null || action === void 0 ? void 0 : action.payload)) {
        return state;
    }
    var _d = action.payload, newData = _d.data, current_page = _d.current_page, total = _d.total;
    if (newData && Array.isArray(newData)) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), playerPFDTransactions: __assign(__assign({}, state.playerPFDTransactions), { data: __spreadArray(__spreadArray([], ((_b = (_a = state.playerPFDTransactions) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : []), true), ((_c = newData === null || newData === void 0 ? void 0 : newData.filter(function (transaction) { return !state.playerPFDTransactions.transactionsIds[String(transaction === null || transaction === void 0 ? void 0 : transaction.id)]; })) !== null && _c !== void 0 ? _c : []), true), current_page: current_page, total: total, transactionsIds: __assign(__assign({}, state.playerPFDTransactions.transactionsIds), newData.reduce(function (acc, cur) {
                    var _a;
                    return (__assign(__assign({}, acc), (_a = {}, _a[String(cur === null || cur === void 0 ? void 0 : cur.id)] = true, _a)));
                }, {})) }) });
    }
    return state;
};
var redeemPFDPointsReducer = function (state, action) {
    return __assign(__assign({}, state), { lastPFDTxnDate: Date.now(), lastPFDTxnId: action === null || action === void 0 ? void 0 : action.txn_id, lastPFDTxnState: action === null || action === void 0 ? void 0 : action.state });
};
export var pointsForDrawingsSlice = createSlice({
    name: 'pointsForDrawings',
    initialState: defaultState,
    reducers: {
        getPointsForDrawings: getPointsForDrawingsReducer,
        getPlayerPFDTransactions: getPlayerPFDTransactionsReducer,
        redeemPFDPoints: redeemPFDPointsReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function () { return defaultState; },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getPointsForDrawings = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPointsForDrawings' }));
                return [4 /*yield*/, apiClient().getPointsForDrawings()];
            case 1:
                result = _a.sent();
                dispatch(pointsForDrawingsSlice.actions.getPointsForDrawings(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPointsForDrawings' }));
                return [2 /*return*/];
        }
    });
}); };
var getPlayerPFDTransactions = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerPFDTransactions' }));
                return [4 /*yield*/, apiClient().getPlayerPFDTransactions(payload)];
            case 1:
                result = _a.sent();
                dispatch(pointsForDrawingsSlice.actions.getPlayerPFDTransactions(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerPFDTransactions' }));
                return [2 /*return*/];
        }
    });
}); };
var redeemPFDPoints = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'redeemPFDPoints' }));
                return [4 /*yield*/, apiClient().redeemPFDPoints(payload)];
            case 1:
                result = _a.sent();
                dispatch(pointsForDrawingsSlice.actions.redeemPFDPoints(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'redeemPFDPoints' }));
                return [2 /*return*/, result];
        }
    });
}); };
//actions that get exported to the views
export var pointsForDrawingsActions = function (dispatch, apiClient) {
    return {
        getPointsForDrawings: function (payload) { return getPointsForDrawings(dispatch, payload, apiClient); },
        getPlayerPFDTransactions: function (payload) { return getPlayerPFDTransactions(dispatch, payload, apiClient); },
        redeemPFDPoints: function (payload) { return redeemPFDPoints(dispatch, payload, apiClient); },
    };
};
