export var setCookieValueByName = function (cookieName, cookieValue) {
    var getTopLevelDomain = function (domain) {
        return domain.split('.').slice(-2).join('.');
    };
    if (typeof document === 'undefined') {
        return;
    }
    document.cookie = "".concat(cookieName, "=").concat(cookieValue, "; Path=/; Domain=").concat(getTopLevelDomain(document.location.hostname));
    return cookieValue;
};
export default setCookieValueByName;
