import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { autoTab } from '../../../index';
var ScratchFieldInput = React.forwardRef(function (_a, ref) {
    var field = _a.field, handleFieldChange = _a.handleFieldChange, elemRefs = _a.elemRefs;
    return (_jsxs("div", { className: 'd-inline-block ticket-number-inputs', children: [_jsx("input", { type: 'text', id: field.name, "data-index": field.dataindex, ref: ref, className: 'theme-input', placeholder: field.placeholder, size: field.size, maxLength: field.size, defaultValue: field.value, onChange: function (event) { return handleFieldChange(event, field); }, onKeyUp: function (event) {
                    autoTab(event, elemRefs);
                }, required: true }), _jsx("span", { className: 'dash', children: "\u2014" })] }));
});
ScratchFieldInput.displayName = 'ScratchFieldInput';
export default ScratchFieldInput;
