var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import TeleScript from '../../Components/TeleScript/TeleScript';
var PlayerPointsLimiters = function (_a) {
    var points = _a.points, actions = _a.actions, telescript = _a.telescript, options = _a.options;
    var _b = useState(false), hasPFPLimiter = _b[0], setHasPFPLimiter = _b[1];
    var _c = useState(false), hasPFDLimiter = _c[0], setHasPFDLimiter = _c[1];
    var _d = useState('n/a'), pfpLimit = _d[0], setPFPLimit = _d[1];
    var _e = useState('n/a'), pfdLimit = _e[0], setPFDLimit = _e[1];
    var _f = useState('n/a'), pfpBalance = _f[0], setPFPBalance = _f[1];
    var _g = useState('n/a'), pfdBalance = _g[0], setPFDBalance = _g[1];
    var _h = useState('n/a'), pfpQuantum = _h[0], setPFPQuantum = _h[1];
    var _j = useState('n/a'), pfdQuantum = _j[0], setPFDQuantum = _j[1];
    var _k = useState(false), isUserPFPLimited = _k[0], setIsUserPFPLimited = _k[1];
    var _l = useState(false), isUserPFDLimited = _l[0], setIsUserPFDLimited = _l[1];
    //***********************************************************************************
    // Gets the Points Limiters and current user points.
    //
    var getPointsLimiters = function () { return __awaiter(void 0, void 0, void 0, function () {
        var requestTimeout, timeoutValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestTimeout = new Date();
                    requestTimeout.setMinutes(requestTimeout.getMinutes() - 1);
                    timeoutValue = requestTimeout.valueOf();
                    if (!(points.lastRequested < timeoutValue)) return [3 /*break*/, 2];
                    return [4 /*yield*/, actions.pointActions.getPoints()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getPointsLimiters();
    }, []);
    //***********************************************************************************
    // Parse the points object to get the relevant data.   See what limiters are
    // currently running and see if the user has hit any of them.
    //
    useEffect(function () {
        if (points && points.totals) {
            var pfpInfo = points.totals.find(function (o) { return o.name === 'pfp'; });
            var pfdInfo = points.totals.find(function (o) { return o.name === 'pfd'; });
            if (pfpInfo && pfpInfo.limit && pfpInfo.limit.quantum != null) {
                setHasPFPLimiter(true);
                if (pfpInfo.limit.limit !== null)
                    setPFPLimit(pfpInfo.limit.limit.toString());
                if (pfpInfo.limit.value !== null)
                    setPFPBalance(pfpInfo.limit.value);
                if (pfpInfo.limit.quantum !== null)
                    setPFPQuantum(pfpInfo.limit.quantum);
                if (pfpInfo.limit.limited)
                    setIsUserPFPLimited(true);
            }
            if (pfdInfo && pfdInfo.limit && pfdInfo.limit.quantum != null) {
                setHasPFDLimiter(true);
                if (pfdInfo.limit.limit !== null)
                    setPFDLimit(pfdInfo.limit.limit);
                if (pfdInfo.limit.value !== null)
                    setPFDBalance(pfdInfo.limit.value);
                if (pfdInfo.limit.quantum !== null)
                    setPFDQuantum(pfdInfo.limit.quantum);
                if (pfdInfo.limit.limited)
                    setIsUserPFDLimited(true);
            }
        }
    }, [points]);
    var LimiterInfo = function (_a) {
        var isLimited = _a.isLimited, limitType = _a.limitType, limit = _a.limit, balance = _a.balance, quantum = _a.quantum;
        var Remaining = (Number(limit) - Number(balance)).toString();
        return (_jsx("div", { children: isLimited ? (_jsx("p", { className: 'point-limit-info theme-danger', children: _jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.youHaveReachedYourLimit, replace: {
                        '%LIMIT_TYPE%': limitType,
                        '%LIMIT%': limit,
                        '%BALANCE%': balance,
                        '%QUANTUM%': quantum,
                        '%REMAINING%': Remaining,
                    }, children: ["You have reached your ", quantum, " ", limitType, " Limit"] }) })) : (_jsx("p", { className: 'point-limit-info', children: _jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.youMayEarnMoreTowardsYourMaximumLimit, replace: {
                        '%LIMIT_TYPE%': limitType,
                        '%LIMIT%': limit,
                        '%BALANCE%': balance,
                        '%QUANTUM%': quantum,
                        '%REMAINING%': Remaining,
                    }, children: ["You may earn", ' ', _jsxs("strong", { children: [Remaining, " more ", limitType] }), ' ', "towards your", ' ', _jsxs("strong", { children: [limit, " ", quantum, " maximum limit."] })] }) })) }));
    };
    return (_jsxs("div", { children: [hasPFPLimiter && (_jsx(LimiterInfo, { limitType: "".concat((options === null || options === void 0 ? void 0 : options.pointReference) || 'Points', " For Prizes"), isLimited: isUserPFPLimited, limit: pfpLimit, balance: pfpBalance, quantum: pfpQuantum })), hasPFDLimiter && (_jsx(LimiterInfo, { limitType: "".concat((options === null || options === void 0 ? void 0 : options.pointReference) || 'Points', " For Drawings"), isLimited: isUserPFDLimited, limit: pfdLimit, balance: pfdBalance, quantum: pfdQuantum }))] }));
};
export default PlayerPointsLimiters;
