import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getCMSObject, TeleScript } from 'sg-ui-components';
const winnerMessage = getCMSObject('data.components.teleScripts.viewWinnersMessaging.jsonBlock');

const WinnerLinkWrapper = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleKeyShow = (event) => {
        if (event.key === 'Enter') {
            setShow(true);
        }
    };

    return (
        <>
            <a id={`winners-link`} className='winners-link' role='button' tabIndex='0' onKeyDown={handleKeyShow} onClick={handleShow}>
                View Winners
            </a>
            <Modal id={`winners-modal`} dialogClassName='winners-modal' backdropClassName='winners-modal-backdrop' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <TeleScript line={winnerMessage?.header}></TeleScript>
                </Modal.Header>

                <Modal.Body>
                    <TeleScript line={winnerMessage.winnerBodyMesage}>
                        <h5>
                            Points for Drawings winners will receive an email from <br /> <a href='mailto:Promotions@molottery.com'>Promotions@molottery.com</a>
                        </h5>
                        <p>By law, the Missouri Lottery can no longer publish the names of winners without prior consent.</p>
                        <p>To make sure youre not missing emails from us, its important to keep your email address and contact information up to date.</p>
                        <p>
                            You may also want to check your spam/junk/trash folder and add <br />
                            <a href='mailto:Promotions@molottery.com'>Promotions@molottery.com</a> to your contact list.
                        </p>
                    </TeleScript>
                </Modal.Body>
                <Modal.Footer>
                    <TeleScript line={winnerMessage?.footnote}></TeleScript>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default WinnerLinkWrapper;
