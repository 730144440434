var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TableMobile, TableDesktop, formatDate, formatDateTBD } from '../../../index';
var DrawingGroupTable = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var drawingGroup = _a.drawingGroup, loggedIn = _a.loggedIn, telescript = _a.telescript, WinnerLink = _a.WinnerLink;
    var _h = useState(false), pageLoaded = _h[0], setPageLoaded = _h[1];
    useEffect(function () {
        setPageLoaded(true);
    }, []);
    var group_name = (_b = drawingGroup.name) !== null && _b !== void 0 ? _b : '';
    var drawings = (_c = drawingGroup.drawings) !== null && _c !== void 0 ? _c : [];
    var getDrawingEntries = function (drawing) {
        var _a, _b, _c;
        if (!loggedIn)
            return (_a = telescript === null || telescript === void 0 ? void 0 : telescript.notAvailable) !== null && _a !== void 0 ? _a : 'N/A';
        if (drawing.drawing_summaries && drawing.drawing_summaries.length && drawing.drawing_summaries[0]) {
            return (_c = (_b = drawing.drawing_summaries[0]) === null || _b === void 0 ? void 0 : _b.entry_count) !== null && _c !== void 0 ? _c : '0';
        }
        return '0';
    };
    var displayHeaders = __assign({ entryStart: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntryStart) !== null && _d !== void 0 ? _d : 'ENTRY START', entryDeadline: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntryDeadline) !== null && _e !== void 0 ? _e : 'ENTRY DEADLINE', drawingDate: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawingDate) !== null && _f !== void 0 ? _f : 'DRAWING DATE' }, (loggedIn && { entries: (_g = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntries) !== null && _g !== void 0 ? _g : 'ENTRIES' }));
    var getDisplayRows = function (entries) {
        return entries.map(function (entry) {
            var _a, _b, _c;
            return (__assign(__assign({}, entry), { fields: __assign(__assign({ entryStart: entry.quantum_start ? formatDate.monthDayYear(entry.quantum_start) : (_a = telescript === null || telescript === void 0 ? void 0 : telescript.notAvailable) !== null && _a !== void 0 ? _a : 'N/A', entryDeadline: formatDateTBD.monthDayYear(entry.quantum_end) }, (loggedIn && { entries: (_c = (_b = getDrawingEntries(entry)) !== null && _b !== void 0 ? _b : telescript === null || telescript === void 0 ? void 0 : telescript.notAvailable) !== null && _c !== void 0 ? _c : 'N/A' })), { drawingDate: (_jsxs(_Fragment, { children: [_jsx("div", { children: formatDateTBD.monthDayYear(entry.draw_date) }), entry.certified && (_jsx("div", { children: _jsx(WinnerLink, { selected: entry }) }))] })) }) }));
        });
    };
    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    var entriesTable = function (data) {
        var _a;
        var entries = data !== null && data !== void 0 ? data : [];
        var displayOptions = {
            tableId: 'entryTable',
            isLoaded: pageLoaded,
            messageNoRows: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _a !== void 0 ? _a : 'No drawings',
        };
        var displayRows = getDisplayRows(entries);
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsx("div", { className: 'row', children: _jsxs("div", { className: 'col', children: [_jsx("div", { className: 'theme-table-group-title', children: group_name }), entriesTable(drawings)] }) }));
};
export default DrawingGroupTable;
