import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
var replaceAll = function (line, replace) {
    var regexPattern = new RegExp(Object.keys(replace).join('|'), 'gi');
    return line.replace(regexPattern, function (template) {
        var _a;
        return (_a = replace[template]) !== null && _a !== void 0 ? _a : template;
    });
};
/**
 *
 * Telescript is a solution what can help us displaying different messages for different customers (Georgia / Delaware / South Carolina ...). The imported components are exactly the same as code level, but because every customer has a different CMS endpoint, we can set custom text overrides. Please see adjecent README file for more details.
 */
var TeleScript = function (_a) {
    var line = _a.line, _b = _a.replace, replace = _b === void 0 ? {} : _b, children = _a.children;
    //* Replace template variables, like: %TEMPLATE_DATE%
    if (line && Object.keys(replace).length) {
        line = replaceAll(line, replace);
    }
    if (line) {
        return _jsx("div", { dangerouslySetInnerHTML: { __html: line } });
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
export default TeleScript;
