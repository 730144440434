var scrollToHashElement = function () {
    var _a;
    var hash = window.location.hash;
    var elementToScroll = document.getElementById(hash === null || hash === void 0 ? void 0 : hash.replace('#', ''));
    var layoutOffsetElement = (_a = Array.from(document.querySelectorAll('.layout-scroll-offset')).filter(function (s) { return window.getComputedStyle(s).getPropertyValue('display') != 'none'; })[0]) !== null && _a !== void 0 ? _a : [];
    var extraOffsetPadding = 20; //? for a better look and user experience
    var layoutOffsetHeight = (layoutOffsetElement === null || layoutOffsetElement === void 0 ? void 0 : layoutOffsetElement.offsetHeight) ? (layoutOffsetElement === null || layoutOffsetElement === void 0 ? void 0 : layoutOffsetElement.offsetHeight) + extraOffsetPadding : 0;
    if (!elementToScroll)
        return;
    window.scrollTo({
        top: elementToScroll.offsetTop - layoutOffsetHeight,
        behavior: 'smooth',
    });
};
export default scrollToHashElement;
