var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var POST_ANOMALY_PATH = '/api/anomaly';
var JS_ERROR_CODE = 'JAVASCRIPT_ERROR';
var isErrorCodeACircuitBreaker = function (errorCode) { var _a, _b; return (_b = JSON.parse((_a = localStorage.getItem('anomalyCircuitBreaker')) !== null && _a !== void 0 ? _a : '{}')) === null || _b === void 0 ? void 0 : _b[errorCode]; };
var setCircuitBreaker = function (errorCode, circuitBreakerStatus) {
    var _a;
    var _b;
    console.log('🔥 Setting circuit breaker status', { errorCode: errorCode, circuitBreakerStatus: circuitBreakerStatus });
    var anomalyCircuitBreaker = JSON.parse((_b = localStorage.getItem('anomalyCircuitBreaker')) !== null && _b !== void 0 ? _b : '{}');
    localStorage.setItem('anomalyCircuitBreaker', JSON.stringify(__assign(__assign({}, anomalyCircuitBreaker), (_a = {}, _a[errorCode] = circuitBreakerStatus, _a))));
};
export var logError = function (_a) {
    var _b = _a.errorCode, errorCode = _b === void 0 ? JS_ERROR_CODE : _b, user = _a.user, config = _a.config, _c = _a.category, category = _c === void 0 ? 'generic' : _c, _d = _a.severity, severity = _d === void 0 ? 'critical' : _d, details = _a.details;
    return __awaiter(void 0, void 0, void 0, function () {
        var fetchPath, fetchOptions, response, jsonResponse, error_1;
        var _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (isErrorCodeACircuitBreaker(errorCode)) {
                        console.log("\uD83D\uDD25 Alert: Not logging since circuit breaker reached for ".concat(errorCode, "."), { user: user, config: config, details: details });
                        return [2 /*return*/];
                    }
                    fetchPath = "".concat(POST_ANOMALY_PATH, "?code=").concat(errorCode);
                    fetchOptions = {
                        method: 'POST',
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            registrar_id: (_e = user === null || user === void 0 ? void 0 : user.player) === null || _e === void 0 ? void 0 : _e.registrar_id,
                            category: category,
                            code: errorCode,
                            severity: severity,
                            details: __assign({ url: window.location.href, user: user, config: config }, details),
                        }),
                    };
                    console.log('🔥 Calling error logger with ', { fetchPath: fetchPath, fetchOptions: fetchOptions });
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(fetchPath, fetchOptions)];
                case 2:
                    response = _f.sent();
                    console.log('🔥 Response from anomaly POST', response);
                    return [4 /*yield*/, response.json()];
                case 3:
                    jsonResponse = _f.sent();
                    console.log('🔥 Json response', jsonResponse);
                    setCircuitBreaker(errorCode, jsonResponse.circuit_breaker);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _f.sent();
                    console.log('🔥 Caught error when calling POST anomaly', error_1);
                    return [3 /*break*/, 5];
                case 5:
                    console.log('🔥 Finished calling logger');
                    return [2 /*return*/];
            }
        });
    });
};
