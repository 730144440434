import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Carousel } from 'react-bootstrap';
/**
 * A component that displays a carousel of clickable images.
 */
var BannerCarousel = function (_a) {
    var _b;
    var banners = _a.banners, bannerName = _a.bannerName, _c = _a.ProgressiveJackpot, ProgressiveJackpot = _c === void 0 ? function () { return _jsx(_Fragment, {}); } : _c;
    var carousel = (_b = banners[bannerName]) !== null && _b !== void 0 ? _b : [];
    return (_jsx(Carousel, { "data-testid": 'carousel-inner', className: 'overflow-hidden', children: carousel.map(function (item, index) {
            var _a, _b, _c, _d, _e;
            var jackpot_widget = (_b = (_a = item === null || item === void 0 ? void 0 : item.extra) === null || _a === void 0 ? void 0 : _a.jackpot_widget) !== null && _b !== void 0 ? _b : [];
            var jackpots = Array.isArray(jackpot_widget) ? jackpot_widget : (_c = JSON.parse(jackpot_widget)) !== null && _c !== void 0 ? _c : [];
            return (_jsx(Carousel.Item, { "data-testid": "carousel-inner-item-".concat(index), className: 'text-center', children: _jsxs("div", { className: 'progressive-jackpot-container-banner position-relative', children: [(jackpots === null || jackpots === void 0 ? void 0 : jackpots.length) ? _jsx(ProgressiveJackpot, { jackpots: jackpots }) : null, _jsx("a", { href: item.onclick, target: item.new_window === '1' ? '_blank' : '_self', rel: 'noreferrer', children: _jsx("img", { className: 'img-fluid', src: item.url, alt: (_e = (_d = item.alt_text) !== null && _d !== void 0 ? _d : item.name) !== null && _e !== void 0 ? _e : 'carousel item' }) })] }) }, index));
        }) }));
};
export default BannerCarousel;
