import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import Menu from '../../Menu.js';
import HomeHeroBanner from '../../HomeHeroBanner';
import ExpirationBanner from '../../Login/ExpirationBanner';
import { replaceUrlSigils } from '../../../utils/replaceUrlSigils';
import { getCMSObject, CMSContent } from 'sg-ui-components';

const Header = () => {
    return (
        <div className='page-header'>
            <header>
                <div>
                    <HeaderNav />
                    <div className='header border-bottom shadow-sm pb-3'>
                        <PrimaryNav />
                    </div>

                    <div>
                        <HomeHeroBanner />
                    </div>

                    <div className='toast-holder'>
                        <ExpirationBanner notify={20} warn={10} />
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;

//***********************************************************************************
// Get the Primary Navigation via a JSON string provided by the CMS
// the Primary Navigation is a series of drop-downs for MO.
//
const PrimaryNavTemplate = ({ cmsSourceFirebase, children }) => {
    const localStoragePrimaryMenu = getCMSObject('primaryMenu', 'webMenu');

    const LotteryLogoTemplate = () => {
        return (
            <div className='header-lottery-logo-container'>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.sitewideSettings.headerLogo.image'
                    className='img-fluid'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </div>
        );
    };

    return (
        <Navbar className='nav-primary' expand='xl'>
            <Container>
                <Navbar.Brand>
                    <LotteryLogoTemplate />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='primary-navbar-nav' />
                <Navbar.Collapse id='primary-navbar-nav'>
                    <Menu menu_json={localStoragePrimaryMenu} className='text-center text-xl-left' />
                </Navbar.Collapse>
                {children}
            </Container>
        </Navbar>
    );
};

const PrimaryNav = connect(mapStateToProps, mapDispatchToProps)(PrimaryNavTemplate);

//***********************************************************************************
// The Header Nav is a sub navigation the appears above the primary navigation
// this will be built from the CMS in the header_nav section of Pages.
//
const HeaderNavTemplate = ({ user, config, children }) => {
    const localStorageHeaderMenu = getCMSObject('headerMenu', 'webMenu');
    let responsibleGamingUrl = getCMSObject('responsibleGamingMenu', 'webMenu');
    responsibleGamingUrl = replaceUrlSigils(config, responsibleGamingUrl?.[0]?.webLinkUrl ?? '');

    return (
        <div className={`header-nav ${user.loggedIn ? 'logged-in' : ''}`}>
            <div className='container'>
                <Navbar bg='transparent' className='row justify-content-end'>
                    <Menu menu_json={localStorageHeaderMenu} className='header-links text-center text-xl-left' />
                    <a className='play-responsibly d-sm-block' href={responsibleGamingUrl} target='_blank' rel='noopener noreferrer'>
                        <img src='/playresponsibly_v2.png' alt='Play Responsibly' />
                    </a>
                    {children}
                </Navbar>
            </div>
        </div>
    );
};

const HeaderNav = connect(mapStateToProps, mapDispatchToProps)(HeaderNavTemplate);
