import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import alarm from './alarm.png';
import TeleScript from '../TeleScript/TeleScript';
var SweepstakeCategories = function (_a) {
    var _b;
    var _c = _a.category, category = _c === void 0 ? 'current' : _c, _d = _a.telescript, telescript = _d === void 0 ? {} : _d;
    // Validation: only display when the promotion is expired
    if (category !== 'expired')
        return null;
    return (_jsx("div", { className: 'sweepstake-categories-element position-absolute', children: _jsxs("div", { className: 'sweepstake-categories-label', children: [_jsx("img", { src: alarm, alt: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.categoryExpiredIcon) !== null && _b !== void 0 ? _b : 'Alarm clock icon' }), _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.categoryExpired, children: "Promotion Closed" })] }) }));
};
export default SweepstakeCategories;
