var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { stripsTagsAndSlashes } from '../../index';
var interpolateString = function (val, replacements) {
    var _a;
    return ((_a = replacements === null || replacements === void 0 ? void 0 : replacements.reduce(function (acc, cur) {
        var find = cur.find, replace = cur.replace;
        return acc.replace(find, replace);
    }, val)) !== null && _a !== void 0 ? _a : {});
};
var interpolate = function (data, replacements) {
    if (typeof data === 'string') {
        return interpolateString(data, replacements);
    }
    else if (data && Array.isArray(data)) {
        return data.map(function (value) { return interpolate(value, replacements); });
    }
    else if (data && typeof data === 'object') {
        return Object.keys(data).reduce(function (acc, cur) {
            var _a;
            var node = data === null || data === void 0 ? void 0 : data[cur];
            return __assign(__assign({}, acc), (_a = {}, _a[cur] = interpolate(node, replacements), _a));
        }, {});
    }
    else {
        return data !== null && data !== void 0 ? data : {};
    }
};
var interpolateReplacementsIntoPageData = function (page, replacements) {
    var _a;
    if (((_a = replacements === null || replacements === void 0 ? void 0 : replacements.length) !== null && _a !== void 0 ? _a : 0) === 0) {
        return page;
    }
    return interpolate(page, replacements);
};
var getInterpolatedPageData = function (routes, match, replacements) {
    var _a, _b;
    var path = (_a = match === null || match === void 0 ? void 0 : match.path) !== null && _a !== void 0 ? _a : '';
    var pageData = (_b = (routes && routes[stripsTagsAndSlashes(path)])) !== null && _b !== void 0 ? _b : {};
    var interpolatedPageData = interpolateReplacementsIntoPageData(pageData, replacements);
    return interpolatedPageData;
};
export default getInterpolatedPageData;
