var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
import { bonusingSlice } from './BonusingStore';
//? API test samples
// import { sampleExternalLotteryPromotionsMRB as sampleExternalLotteryPromotions } from '../samples/api/external-lottery-promotions';
// import { samplePromotions as samplePromotions } from '../samples/api/query-promotions';
export var defaultState = {
    lastRequested: null, // possibly use this to trigger reloading?
    promotions: [],
    detailId: null,
    externalPromotions: [],
    playerPromotions: [],
    errors: {},
    lastSocialClicked: null,
};
// REDUCERS
// basic actions that affect this object only
var getPromotionsReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), promotions: action.payload });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultState);
};
var getExternalPromotionsReducer = function (state, action) {
    var _a;
    var data = (_a = action.payload) !== null && _a !== void 0 ? _a : [];
    //? for using samples: data = sampleExternalLotteryPromotions;
    return __assign(__assign({}, state), { lastRequested: Date.now(), externalPromotions: data });
};
var getPlayerPromotionsReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), playerPromotions: action.payload });
};
var setRequestErrorReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true });
};
var submitSocialMediaClickReducer = function (state, action) {
    var payload = action.payload;
    if (payload.isError) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), errors: payload.error, lastSocialClicked: null });
    }
    else {
        return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true, errors: {}, lastSocialClicked: payload.site });
    }
};
var resetSocialActionsReducer = function (state, action) {
    return __assign(__assign({}, state), { errors: {}, lastSocialClicked: null });
};
export var promotionSlice = createSlice({
    name: 'promotion',
    initialState: defaultState,
    reducers: {
        getPromotions: getPromotionsReducer,
        getExternalPromotions: getExternalPromotionsReducer,
        getPlayerPromotions: getPlayerPromotionsReducer,
        setRequestError: setRequestErrorReducer,
        submitSocialMediaClick: submitSocialMediaClickReducer,
        resetSocialActions: resetSocialActionsReducer,
        reset: resetReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function () { return defaultState; },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getPromotions = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPromotions' }));
                return [4 /*yield*/, apiClient().getPromotions()];
            case 1:
                result = _a.sent();
                dispatch(promotionSlice.actions.getPromotions(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPromotions' }));
                return [2 /*return*/];
        }
    });
}); };
var getExternalPromotions = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getExternalPromotions' }));
                return [4 /*yield*/, apiClient().callExternalEndpoint(payload)];
            case 1:
                result = _a.sent();
                dispatch(promotionSlice.actions.getExternalPromotions(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getExternalPromotions' }));
                return [2 /*return*/];
        }
    });
}); };
var getPlayerPromotions = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerPromotions' }));
                return [4 /*yield*/, apiClient().getPlayerPromotions()];
            case 1:
                result = _a.sent();
                dispatch(promotionSlice.actions.getPlayerPromotions(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerPromotions' }));
                return [2 /*return*/];
        }
    });
}); };
var submitSocialMediaClick = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'submitSocialMediaClick' }));
                return [4 /*yield*/, apiClient().submitSocialMediaClick(payload)];
            case 1:
                result = _a.sent();
                if (result.bonus) {
                    dispatch(bonusingSlice.actions.setBarObject(result.bonus));
                }
                dispatch(promotionSlice.actions.submitSocialMediaClick(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'submitSocialMediaClick' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var promotionActions = function (dispatch, apiClient) {
    return {
        getPromotions: function (payload) { return getPromotions(dispatch, payload, apiClient); },
        getExternalPromotions: function (payload) { return getExternalPromotions(dispatch, payload, apiClient); },
        getPlayerPromotions: function (payload) { return getPlayerPromotions(dispatch, payload, apiClient); },
        submitSocialMediaClick: function (payload) { return submitSocialMediaClick(dispatch, payload, apiClient); },
        resetSocialActions: function (payload) { return dispatch(promotionSlice.actions.resetSocialActions()); },
        reset: function (payload) { return dispatch(promotionSlice.actions.reset(payload)); },
    };
};
