import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Helper component to display Password Status
 *
 * @param {string} status - The status of the password.
 * @returns {JSX.Element|null} The rendered password status text alert.
 */
var PasswordStatusForm = function (status) {
    if (status === 'initial')
        return null;
    return (_jsx(_Fragment, { children: status === 'valid' ? (_jsxs("div", { className: 'd-flex align-items-center pt-1 theme-text-success', role: 'alert', children: [_jsx(FontAwesomeIcon, { icon: 'fa-regular fa-circle-check' }), _jsx("small", { className: 'ml-2', children: "Your password matches the criteria." })] })) : (_jsxs("div", { className: 'd-flex align-items-center pt-1 theme-text-danger', role: 'alert', children: [_jsx(FontAwesomeIcon, { icon: 'fa-regular fa-circle-xmark' }), _jsx("small", { className: 'ml-2', children: status })] })) }));
};
export default PasswordStatusForm;
