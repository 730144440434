import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';
import { CantFindConfirmation } from './cant-find-confirmation';
import { ReadyToGo } from './ready-to-go';
import { ContentBox, ContentBoxBody, ContentBoxHead } from 'sg-ui-components';
import { HashLink } from 'react-router-hash-link';

/**********************************************************************
 * Component:  AwaitAccountConfirmation
 * Purpose:    Simple Component that tells user to wait for their
 *             account confirmation email.    The user has the option to
 *             resend the email if it hasn't come in.
 *
 * Props:       step -  counter of what step we are in the registration process
 *              setStep -  a method to change steps in the process
 *              user -  user data store
 *              actions - store actions (apis)
 *
 *  Other:    code -  RAF code that can come in from the URL.
 *
 * APIs used:   TBD - to resend the confirmation emails
 *
 *  Notes:  ToDo -  add timer to check if user changed.
 */
const AwaitAccountConfirmation = ({ user, actions }) => {
    const [seconds, setSeconds] = useState(0);
    const [timerActive, setTimerActive] = useState(true);
    const [timeoutHit, setTimeoutHit] = useState(false);
    const [accountConfirmed, setAccountConfirmed] = useState(false);
    const timeout = 1200; // 20 min -- ToDo - configurable time?

    //***********************************************************************************
    // Request a resend of the confirmation email and reset timer to 0
    const resendConfirmationEmail = async (e) => {
        e.preventDefault();
        await actions.loadingActions.setLoading({ action: 'confirmResend' });
        await actions.userActions.updateSection({ section: 'resend-confirm-email', status: 'initial' });
        const payload = {
            registrar_id: user.player.registrar_id ?? '',
        };
        await actions.userActions.confirmResend(payload);
        setSeconds(0);
    };

    //***********************************************************************************
    // Check to see if the actions table for the user has changed, if so and they
    // no longer have the account-confirm action - load up Your Ready to Go component
    const checkVerificationStatus = async () => {
        // This is the only place we should call getPlayerData to manually get a new SPAT as
        // we are checking to see if a non-webapp action is changing the user's status.
        await actions.userActions.getPlayerData();
        if (user?.player?.actions?.length) {
            if (!user.player.actions.includes('account-confirm')) {
                setTimerActive(false);
                setAccountConfirmed(true);
            }
        }
    };

    //***********************************************************************************
    // On Load, set up timer and check validation status on regular basis (10 seconds).
    // if past timeout, direct user to call customer service.
    useEffect(() => {
        let interval = null;
        if (timerActive && seconds < timeout) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 10);
                checkVerificationStatus();
            }, 10000);
        } else if (!timerActive && seconds !== 0) {
            clearInterval(interval);
        } else if (seconds >= timeout) {
            setTimerActive(false);
            clearInterval(interval);
            setTimeoutHit(true);
        }
        return () => clearInterval(interval);
    }, [timerActive, seconds]);

    if (accountConfirmed) {
        return <ReadyToGo />;
    } else if (timeoutHit) {
        return <CantFindConfirmation />;
    } else {
        return (
            <ContentBox variant='form-step theme-blue mx-2'>
                <ContentBoxHead>Welcome!</ContentBoxHead>
                <ContentBoxBody>
                    <div className='text-center'>
                        <img src='players-club-logo.png' className='img-fluid mx-auto my-3' />
                    </div>
                    <p className='text-center'>
                        Your account has been created. Follow the link sent to <strong>{user.player.email}</strong> to confirm your registration and begin
                        earning Rewards. <br />
                        Please allow up to 15 minutes to receive your email.
                    </p>
                    <p className='text-center'>
                        Didn&apos;t receive your confirmation email? <Link onClick={resendConfirmationEmail}>Click here to resend</Link>
                        <br />
                        Wrong email? <HashLink to='/feedback#contact'>Click here to edit.</HashLink>
                    </p>
                </ContentBoxBody>
            </ContentBox>
        );
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AwaitAccountConfirmation);
