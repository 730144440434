import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';
import promotion from '../../promotion_config';
import { useEffect } from 'react';

/**********************************************************************
 * Component:  Logout
 * Purpose:    Logs the user out, performs cleanup operations and then
 *             sends the user back to the configured logout destination
 *
 * Props:       config - config data store
 *              actions - store actions (apis)
 *
 * APIs used:   userActions.logout() - ends user session
 *
 *  Notes:
 */
const Logout = ({ config, actions }) => {
    useEffect(() => {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotion.getLogoutDestination(myHost, config.config.logout_host_uri);

        actions.userActions.logout();
        window.location.search = '';
        window.location.href = logoutDestination;
    }, []); // end useEffect

    return <></>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
