var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, TeleScript, TableDesktop, TableMobile, formatDate, formatDateTBD, calculateContentBoxShow, } from '../../../index';
var MyDrawingEntries = function (_a) {
    var _b, _c, _d, _e, _f;
    var winner = _a.winner, actions = _a.actions, _g = _a.telescript, telescript = _g === void 0 ? {} : _g, WinnerLink = _a.WinnerLink, _h = _a.options, options = _h === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        showByDefault: false,
        hash: 'drawing-entries',
        pageSize: 5,
        promotionDetailsLink: '/second-chance-promotion',
    } : _h;
    var hash = (_b = options === null || options === void 0 ? void 0 : options.hash) !== null && _b !== void 0 ? _b : 'drawing-entries';
    var promotionDetailsLink = (_c = options === null || options === void 0 ? void 0 : options.promotionDetailsLink) !== null && _c !== void 0 ? _c : '/second-chance-promotion';
    var _j = useState(false), pageLoaded = _j[0], setPageLoaded = _j[1];
    var _k = useState(1), page = _k[0], setPage = _k[1];
    var _l = useState((_d = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _d !== void 0 ? _d : 5), pageSize = _l[0], setPageSize = _l[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.winnerActions.getCurrentDrawings({ entry_type: 'promotion' })];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        // Reset state on unmount.
        return function () {
            setPage(1);
            setPageLoaded(false);
            setPageSize(5);
        };
    }, []);
    var drawings = ((_e = winner === null || winner === void 0 ? void 0 : winner.currentDrawings) === null || _e === void 0 ? void 0 : _e.length) ? winner.currentDrawings : [];
    var loadMoreData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, actions.winnerActions.getCurrentDrawings({ page: page + 1, pagesize: pageSize })];
                case 1:
                    _a.sent();
                    setPage(page + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    // if there are more rewards, show the link for more
    var loadMoreLink = function () {
        return drawings.length > 0 && drawings.length === pageSize * page ? (_jsx("div", { className: 'text-center my-2', children: _jsx(Button, { variant: 'outline-secondary', className: 'btn theme-btn theme-outline', onClick: function () { return loadMoreData(); }, children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.seeMoreLinkText, children: "See More" }) }) })) : null;
    };
    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    var entriesTable = function (data) {
        var _a, _b, _c, _d, _e, _f;
        var entries = data !== null && data !== void 0 ? data : [];
        var displayHeaders = {
            drawing: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawing) !== null && _a !== void 0 ? _a : 'Second-Chance Promotion',
            entryStart: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntryStart) !== null && _b !== void 0 ? _b : 'Entry Start',
            entriesDeadline: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntriesDeadline) !== null && _c !== void 0 ? _c : 'Entry Deadline',
            myEntries: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntriesMyEntries) !== null && _d !== void 0 ? _d : 'My Entries',
            drawDate: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawDate) !== null && _e !== void 0 ? _e : 'Draw Date',
        };
        var displayRows = __spreadArray([], entries, true).map(function (drawing) {
            var _a;
            return (__assign(__assign({}, drawing), { fields: {
                    drawing: (_jsxs(Link, { to: "".concat(promotionDetailsLink, "?id=").concat(drawing.sweepstake_id), children: [drawing.sweepstake, " - ", drawing.drawing_group] })),
                    entryStart: formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithDayOfTheWeek(drawing.quantum_start),
                    entriesDeadline: formatDateTBD === null || formatDateTBD === void 0 ? void 0 : formatDateTBD.dateTimeWithDayOfTheWeek(drawing.quantum_end),
                    myEntries: (_a = drawing.entry_count) !== null && _a !== void 0 ? _a : 0,
                    drawDate: (_jsxs(_Fragment, { children: [_jsx("div", { children: formatDateTBD === null || formatDateTBD === void 0 ? void 0 : formatDateTBD.monthDayYear(drawing.draw_date) }), drawing.certified && (_jsx("div", { children: _jsx(WinnerLink, { selected: drawing }) }))] })),
                } }));
        });
        var displayOptions = {
            tableId: 'drawing-entries-table',
            isLoaded: pageLoaded,
            messageNoRows: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.messageNoRows) !== null && _f !== void 0 ? _f : 'You currently have no entries into a second-chance promotion.',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsxs(ContentBox, { variant: (_f = options === null || options === void 0 ? void 0 : options.variant) !== null && _f !== void 0 ? _f : 'theme-primary', id: hash, show: calculateContentBoxShow(__assign(__assign({}, options), { hash: hash })), children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "My Drawing Entries" }), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true ? _jsx(ContentCollapser, {}) : ''] }), _jsx(ContentBoxBody, { children: _jsx("div", { className: 'row', children: _jsxs("div", { className: 'col', children: [entriesTable(drawings), loadMoreLink()] }) }) })] }));
};
export default MyDrawingEntries;
