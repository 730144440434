import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { Redirect } from 'react-router-dom';
import { autoTab, CMSContent, ErrorMessage, getCMSObject, TeleScript, FindDrawNumbers, DisplayContent, AlertBox } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validateDrawEntry } from '../../validationRules';
import { Button, Modal } from 'react-bootstrap';
import Scanner from './Scanner';
import promotionConfig from '../../promotion_config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parsePrepopulatedTicket } from '../../utils/parsePrepopulatedTicketNumber';
import { replaceBadTicketInput } from '../../utils/replaceBadTicketInput';

/**********************************************************************
 * Component:  DrawTicketForm
 * Purpose:    Allows for the entry of Draw Tickets
 *
 * Props:       user - the user data store
 *              actions - store actions (apis)
 *              drawTicket - the draw ticket data store
 *
 * APIs used:   drawTicketActions.enterDrawTicket
 *
 *  Notes:  For MO, does not check Winning Status, only for
 *          bonusing rewards.
 */
const DrawTicketForm = ({ user, config, actions, drawTicket, prepopulatedTicket, cmsSourceFirebase }) => {
    // Format of the Draw Tickets
    const initialDrawFields = promotionConfig.drawFields;
    const ticketEntryTeleScript = getCMSObject('data.ticketEntry');

    const [drawFields, setDrawFields] = useState(initialDrawFields);
    const [ticketError, setTicketError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const elemRefs = [];

    useEffect(() => {
        if (prepopulatedTicket) {
            let currentNumber = prepopulatedTicket;
            const updatedFields = initialDrawFields.map((field) => {
                const newField = { ...field };
                currentNumber = parsePrepopulatedTicket(currentNumber, newField);
                return newField;
            });
            setDrawFields(updatedFields);
        }
    }, [prepopulatedTicket]);
    //***********************************************************************************
    // Processes the ticket status and if the ticket was a success, redirect to
    // Post Claim.
    // If Ticket fails, report the error.  If failue was due to Player Lockout, prevent
    // further submitting of tickets.
    //
    useEffect(() => {
        if (disabled) {
            if (drawTicket.success) {
                updateDashboard();
                setTicketSuccess(true);
            } else {
                setTicketError(drawTicket.message);
            }
            setDisabled(false);
        }
        if (['_PLAYER_CONSECUTIVE_LOCKEDOUT', '_PLAYER_LOCKOUT'].includes(drawTicket.message)) {
            setDisabled(true);
        }
    }, [drawTicket]);

    useEffect(() => {
        return () => {
            drawFields.forEach((field) => {
                field.value = '';
            });

            setDrawFields([]);
            setTicketError('');
            setValidationError('');
            setDisabled(false);
            setTicketSuccess(false);
            setShowModel(false);
        };
    }, []);

    //***********************************************************************************
    // Updates the dashboard on a ticket entry, see if points have been updated
    //
    const updateDashboard = async () => {
        if (user.loggedIn && user.player.actions.length === 0) {
            await actions.userActions.getDashboard();
        }
    };

    //***********************************************************************************
    // Cycle through the draw fields and set up the input boxes.
    // Autotabbing should be allowed to go from input to input.
    const DrawFieldInputs = () => {
        return drawFields.map((field, index) => {
            const ref = React.createRef();
            elemRefs.push(ref);
            return (
                <div className='d-inline ticket-number-inputs' key={`draw-fields--${index}`}>
                    <input
                        type='text'
                        data-index={index}
                        ref={ref}
                        id={field.name}
                        className='theme-input mb-2 mb-sm-0'
                        placeholder={field.placeholder}
                        size={field.size}
                        maxLength={field.size}
                        defaultValue={field.value}
                        onChange={(event) => handleFieldChange(event, field)}
                        onKeyUp={(event) => autoTab(event, elemRefs)}
                        required
                    />
                    <span className='dash'>&mdash;</span>
                </div>
            );
        });
    }; // end DrawFieldInputs

    //***********************************************************************************
    // onChange callback for the input fields.    Sets the state of drawFields.
    //
    const handleFieldChange = async (event, field) => {
        setTicketError('');
        setValidationError('');
        let fields = drawFields;

        const userInput = replaceBadTicketInput(event.target);
        const fieldIndex = fields.findIndex((obj) => obj.name == field.name);
        fields[fieldIndex].value = userInput.value;
        setDrawFields(fields);
    }; // end handleFieldChange

    //***********************************************************************************
    // Handle the submit of the draw ticket.  Combine all the tickets fields into
    // one string object that is passed to the DrawTicket API and disable submit
    // until we get a response.
    //
    const handleDrawSubmit = async () => {
        setDisabled(true);
        setValidationError('');
        setTicketError('');
        setTicketSuccess(false);
        let ticketString = '';

        drawFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        const drawEntrySchema = validateDrawEntry;
        const valid = await drawEntrySchema.isValid(ticketString);

        if (valid) {
            await actions.drawTicketActions.enterDrawTicket({
                ticket_number: ticketString,
                entry_method: 'manual',
                ticket_type: 'draw',
            });
        } else {
            await drawEntrySchema.validate(ticketString, { abortEarly: true }).catch(function (err) {
                setValidationError(err.message);
            });
        }
        setDisabled(false);
    }; // end handleDrawSubmit

    const showDrawTooltip = () => {
        setShowModel(true);
    };

    const handleModalClose = () => {
        setShowModel(false);
    };

    return (
        <div>
            {ticketSuccess ? <Redirect to={{ pathname: '/post-claim', state: { motive: 'TicketEntry' }, motive: 'TicketEntry' }} /> : null}

            <DisplayContent note='Display local errors when validation fails.' isVisible={validationError}>
                <AlertBox message={validationError} />
            </DisplayContent>

            <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />

            <Scanner />

            <div className='enter-form text-center mt-3 mb-1'>
                <DrawFieldInputs />
                <FontAwesomeIcon icon='fa-solid fa-circle-info' className='ticket-tooltip' onClick={() => showDrawTooltip()} />
            </div>

            <div className='enter-form text-center theme-text-default mb-3'>
                <TeleScript line={ticketEntryTeleScript?.drawTicketEntryInstructions?.contentHTML}>
                    Enter {config?.web?.drawTicketLength ?? 25}-digit alpha-numeric Webcode in box on ticket.
                </TeleScript>
            </div>

            <div className='enter-form text-center'>
                <button type='submit' className='btn theme-btn theme-secondary' disabled={disabled} onClick={() => handleDrawSubmit()}>
                    Submit
                </button>
                <HashLink to='/my-activity#ticket-history' className='view-history-link d-block mt-1'>
                    <TeleScript line={ticketEntryTeleScript?.ticketHistoryLink?.contentHTML}>View your ticket history</TeleScript>
                </HashLink>
            </div>

            <Modal show={showModel} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.ticketEntry.findDrawNumbers.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FindDrawNumbers.DrawNumbersExample cmsSourceFirebase={cmsSourceFirebase} />
                </Modal.Body>

                <Modal.Footer className='mx-auto'>
                    <Button className='btn theme-btn theme-secondary' onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    ); // end return
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawTicketForm);
