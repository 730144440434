module.exports = {
    routes: {
        '': {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        home: {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        logout: {
            title: 'Logout',
            pageHeader: 'Logout',
            className: 'logout',
        },
        enter: {
            title: 'Ticket Entry',
            className: 'enter-container',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Enter Tickets', path: '/enter' },
            ],
        },
        feedback: {
            className: 'feedback-container',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Help', path: '*' },
            ],
        },

        lotterypromotion: {
            title: 'Lottery Promotion',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions' },
                { name: 'Lottery Promotion', path: '/lottery-promotion' },
            ],
        },
        promotion: {
            title: '{{PROMOTION_NAME}}',
            className: 'promotion-details',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#my-rewards' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
            path: 'promotion',
        },
        promotions: {
            title: 'Promotions',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions' },
            ],
        },
        referafriend: {
            title: 'Refer a Friend',
            className: 'refer-a-friend',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Refer a Friend', path: '/refer-a-friend' },
            ],
        },
        secondchancepromotion: {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'second-chance-promotion',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#second-chance-promotions' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
        },
        terms: {
            title: 'Terms of Use',
            className: 'terms',
        },
        updatepassword: {
            title: 'Update Password',
            className: 'update-password',
            loggedInOnly: true,
        },
        login: {
            title: 'Login in',
            className: 'login-template',
        },
        myprofile: {
            title: 'My Profile',
            pageHeader: 'My Profile',
            className: 'my-profile',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Profile', path: '/my-profile' },
            ],
            loggedInOnly: true,
            currentSection: 'my-profile',
            disableRedirect: 'change-password',
        },
        survey: {
            title: 'Survey',
            pageHeader: 'Survey',
            className: 'survey',
            loggedInOnly: true,
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Survey', path: '/survey' },
            ],
        },
        myactivity: {
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Activity', path: '#' },
            ],
        },
        forgotpassword: {
            title: 'Forgot Password',
            className: 'forgot-password',
        },
        drawings: {
            title: 'Points For Drawings',
            pageHeader: 'Points For Drawings',
            className: 'my-profile',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Drawings', path: '/drawings' },
            ],
        },
        'pointsfordrawingdetails:sweepId': {
            title: '{{PROMOTION_NAME}}',
            className: 'points-for-drawing-details',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Drawings', path: '/drawings' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
        },
        register: {
            title: 'Registration',
            className: 'register',
        },
        registerverificationerror: {
            title: 'Register Verification Error',
            className: 'register-verification-error',
        },
        awaitaccountconfirmation: {
            title: 'Account Confirmation',
            className: 'await-account-confirmation',
        },
        accountconfirm: {
            title: 'Account Confirm',
            pageHeader: 'Account Confirm',
            className: 'account-confirm',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Account Confirm', path: '/account-confirm' },
            ],
        },
        postclaim: {
            title: 'Post Claim',
            className: 'post-claim',
        },
    },
    title: 'MO Ensemble',
    seoDefaultTitle: 'MO Lottery',
    promotion_id: '',
    jurisdiction: 'mo',
    jurisdictionName: 'Missouri Lottery',
    promotion: 'ensemble_site',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `/login`,
    getLoginLink: (app, redirect) => (app && redirect ? `` : '/login'),
    defaultRegisterLink: '/register',
    getRegisterLink: (app, redirect) => (app && redirect ? `` : this.defaultRegisterLink),
    getLogoutDestination: (hostURL, ptaLogout) => (ptaLogout != undefined ? ptaLogout : hostURL + '/home'),
    forgot: '/users/forgot',
    resolveCurrency: (name) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: 'sweepstake',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: 'lotteryproducts',
                showQuantity: false,
                isPence: false,
                resolve: () => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: 'promocash',
                showQuantity: true,
                isPence: true,
                resolve: (amount) => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: 'default',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter((item) => {
            return item.name === name || item.name === 'default';
        })[0];
    },
    drawTicketLength: '25',
    drawTicketFormat: /.{1,5}/g,
    drawFields: [
        { name: 'field1', placeholder: 'xxxxx', size: '5', value: '' },
        { name: 'field2', placeholder: 'xxxxx', size: '5', value: '' },
        { name: 'field3', placeholder: 'xxxxx', size: '5', value: '' },
        { name: 'field4', placeholder: 'xxxxx', size: '5', value: '' },
        { name: 'field5', placeholder: 'xxxxx', size: '5', value: '' },
    ],
    badTicketInputReplacement: /[o,O]/g, // Regex to remove bad input from ticket fields.
    scratchTicketLengthRegex: '^(?=[0-9]*$)(?:.{22}|.{26})$',
    ticketLengthRegex: '^(?:(?=\\d*$).{22}|(?=\\d*$).{26}|[A-Za-z0-9]{25})$',
    scratchTicketFormatCutoffs: [4, 3, 6, 3, 10],
    numPromosToDisplayByDefault: 3,
    scratchTicketFields: {
        gameNumberFields: [
            {
                name: 'gameNum',
                placeholder: 'GGG',
                size: '3',
                value: '',
                dataindex: '0',
                side: 'back',
            },
        ],
        fieldGroups: [
            {
                validRanges: [['000', '999']],
                frontNumberFields: [
                    {
                        name: 'frontNum',
                        placeholder: '0000000000',
                        size: '10',
                        value: '',
                        dataindex: '3',
                        side: 'front',
                    },
                ],
                backNumberFields: [
                    {
                        name: 'packNum',
                        placeholder: 'BBBBBB',
                        size: '6',
                        value: '',
                        dataindex: '1',
                        side: 'back',
                    },
                    {
                        name: 'ticketNum',
                        placeholder: 'TTT',
                        size: '3',
                        value: '',
                        dataindex: '2',
                        side: 'back',
                    },
                ],
            },
        ],
    },
    banners: {
        homeCarousel: 'mrb_home_page_carousel',
    },
    testing: {},
    components: {
        recentDrawings: {
            defaultPageSize: 10,
        },
        rewardsHistory: {
            defaultPageSize: 5,
        },
        ticketHistory: {
            defaultPageSize: 5,
        },
        drawingEntries: {
            defaultPageSize: 5,
        },
        pointsForDrawingsDetailsView: {
            loginUrl: '/login',
            registerUrl: '/register',
            defaultNumEntries: 1,
        },
    },
    epochEndDate: 2147385600, // Jan 18, 2038 Midnight
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'ctlEnsemble.getConfig',
            login: 'ctlEnsemble.login',
            logout: 'ctlEnsemble.logout',
            checkTicket: 'mrbEnsemble.checkTicket',
            enterDrawTicket: 'mrbEnsemble.enterTicket',
            enterScratchTicket: 'mrbEnsemble.enterTicket',
            enterFastPlayTicket: 'mrbEnsemble.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerData: 'ctlEnsemble.getPlayerData',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'ctlEnsemble.update',
            register: 'mrbEnsemble.register',
            verify: 'ctlEnsemble.verify',
            confirm: 'ctlEnsemble.confirm',
            confirmResend: 'ctlEnsemble.confirmResend',
            winners: 'ctlEnsemble.winners',
            postFeedback: 'ctlEnsemble.postFeedback',
            getFeedbackCategories: 'ctlEnsemble.getFeedbackCategories',
            forgotPassword: 'ctlEnsemble.forgotPassword',
            recoverPassword: 'ctlEnsemble.recoverPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'ctlEnsemble.getAchievements',
            getPoints: 'ctlEnsemble.getPoints',
            getActivity: 'ctlEnsemble.getActivity',
            getReward: 'ctlEnsemble.getReward',
            getCoupons: 'mrbEnsemble.getCoupons',
            getPromotions: 'ctlEnsemble.getPromotions',
            getPointsForDrawings: 'loyalty2.getPointsForDrawings',
            getPlayerPFDTransactions: 'loyalty2.getPlayerPFDTransactions',
            redeemPFDPoints: 'loyalty2.redeemPFDPoints',
            getPlayerPromotions: 'ctlEnsemble.getPlayerPromotions',
            getSweeps: 'ctlEnsemble.getSweeps',
            getSweepDetails: 'mrbEnsemble.getSweepDetails',
            allocateEntries: 'mrbEnsemble.allocateEntries',
            getActiveSweeps: 'ctlEnsemble.getActiveSweeps',
            getCurrentSweeps: 'ctlEnsemble.getCurrentSweeps',
            getExpiredSweeps: 'ctlEnsemble.getExpiredSweeps',
            getRevealToken: 'ctlEnsemble.getRevealToken',
            getBanner: 'ctlEnsemble.getBanner',
            expiredDrawings: 'ctlEnsemble.expiredDrawings',
            currentDrawings: 'ctlEnsemble.currentDrawings',
            nextDrawings: 'ctlEnsemble.nextDrawings',
            getAllOptins: 'mrbEnsemble.getAllOptins',
            getOptins: 'mrbEnsemble.getOptins',
            optinSubscribe: 'mrbEnsemble.optinSubscribe',
            optinUnsubscribe: 'mrbEnsemble.optinUnsubscribe',
            sendRafInvite: 'mrbEnsemble.sendRafInvite',
            getRafCode: 'mrbEnsemble.getRafCode',
            getActiveSurveys: 'mrbEnsemble.getActiveSurveys',
            getSurveyStatus: 'mrbEnsemble.getSurveyStatus',
            getSurveyQuestions: 'mrbEnsemble.getSurveyQuestions',
            getNamedSurveyQuestions: 'mrbEnsemble.getNamedSurveyQuestions',
            setSurveyAnswer: 'mrbEnsemble.setSurveyAnswer',
            completeSurvey: 'mrbEnsemble.completeSurvey',
            getDashboard: 'mrbEnsemble.getDashboard',
            submitSocialMediaClick: 'mrbEnsemble.submitSocialMediaClick',
            getPlayerAllocatableEntries: 'mrbEnsemble.getPlayerAllocatableEntries',
            getPointsHistory: 'mrbEnsemble.getPointsHistory',
            actionComplete: 'mrbEnsemble.actionComplete',
            callExternalEndpoint: 'mrbEnsemble.callExternalEndpoint',
            getPlayerPrizes: 'mrbEnsemble.getPlayerPrizes',
            getPlayerWinnings: 'mrbEnsemble.getPlayerWinnings',
            getThirdPartyPrizes: 'mrbEnsemble.getThirdPartyPrizes',
            getThirdPartyPrizeBarcode: 'mrbEnsemble.getThirdPartyPrizeBarcode',
            getTicketActivity: 'mrbEnsemble.getTicketActivity',
            updateEmail: 'mrbEnsemble.updateEmail',
            updatePassword: 'mrbEnsemble.updatePassword',
            deletePlayerAccount: 'loyalty2.deletePlayerAccount',
        },
    },
};
