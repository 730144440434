var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ContentBox, ContentBoxHead, ContentBoxBody } from '../ContentBox/ContentBox';
import TeleScript from '../TeleScript/TeleScript';
/**
 * This component is mostly used on the Drawings & Winners pages.
 *
 * It shows the next N drawings with a carousel.
 *
 * It's partially similar to the NextDrawing component, but there are some major differences and I expect more differences to come in the future.
 */
var NextDrawing = function (_a) {
    var _b, _c;
    var user = _a.user, winner = _a.winner, actions = _a.actions, _d = _a.formatDate, formatDate = _d === void 0 ? function () { return ''; } : _d, _e = _a.telescript, telescript = _e === void 0 ? {} : _e, _f = _a.options, options = _f === void 0 ? {
        variant: 'theme-primary',
    } : _f;
    var _g = useState(false), pageLoaded = _g[0], setPageLoaded = _g[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            setPageLoaded(true);
                            if (!(winner.nextDrawings.length === 0)) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.winnerActions.getNextDrawings()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, []);
    var formatDrawings = function (drawings, telescript) {
        if (!drawings || !Array.isArray(drawings))
            return [];
        return drawings.map(function (val) {
            return __assign(__assign({}, val), { promotion: val.sweepstake, drawing_date: (_jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.nextDrawingDate, replace: { '%TEMPLATE_DATE%': formatDate(val.draw_date) }, children: ["The Next Drawing is on ", formatDate(val.draw_date)] })), has_winners: val.certified });
        });
    };
    var dataFormatted = formatDrawings(winner.nextDrawings, telescript);
    var nextDrawing = dataFormatted
        .filter(function (val) {
        return val.draw_date != null;
    })
        .sort(function (a, b) {
        return a.id - b.id;
    })
        .sort(function (a, b) {
        return a.draw_date - b.draw_date; // TODO: change to make a proper comparisson of strings
    })[0];
    //? For debug: console.log('📃 Next Drawings:', winner.nextDrawings)
    //? For debug: console.log('🎁 Filtered and sorted:', nextDrawings);
    if (nextDrawing) {
        return (_jsxs(ContentBox, { variant: (_b = options === null || options === void 0 ? void 0 : options.variant) !== null && _b !== void 0 ? _b : 'theme-primary', children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "NEXT DRAWING" }) }), _jsx(ContentBoxBody, { children: _jsxs("div", { className: 'next-drawing-card', children: [_jsx("h3", { children: nextDrawing.promotion }), _jsx("div", { children: nextDrawing.drawing_date }), _jsx("div", { className: 'more-chances', children: user.loggedIn ? (_jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.checkTheWinners, children: [_jsxs("p", { children: ["Are you a winner? ", _jsx("br", {}), " Follow the link below to find out."] }), _jsx(Link, { className: 'btn theme-btn btn-block', to: '/winners', children: "View all Drawings & Winners" })] })) : (_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.logInToCheck, children: _jsx(Link, { className: 'are-you-entered', to: '/login', children: _jsxs("p", { children: ["Are you entered?", _jsx("br", {}), "Login to find out. Good luck!"] }) }) })) })] }) })] }));
    }
    else
        return (_jsxs(ContentBox, { variant: (_c = options === null || options === void 0 ? void 0 : options.variant) !== null && _c !== void 0 ? _c : 'theme-primary', children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "NEXT DRAWING" }) }), _jsx(ContentBoxBody, { children: _jsxs("div", { className: 'next-drawing-card', children: [_jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.noPromotions, children: [_jsx("h3", { children: "Stay Tuned!" }), _jsxs("div", { children: ["More drawings coming your way.", _jsx("br", {}), "Check back often for a 2nd Chance to Win!"] })] }), _jsx("div", { className: 'more-chances', children: user.loggedIn ? (_jsxs(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.moreNotification, children: [_jsxs("p", { children: ["Want updates on promotions and drawings?", _jsx("br", {}), "Opt-in receive 2nd Chance notification."] }), _jsx(HashLink, { className: 'btn theme-btn btn-block', to: 'my-profile#notification', children: "Notification Preferences" })] })) : (_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.logInToCheck, children: _jsx(Link, { className: 'are-you-entered', to: '/login', children: _jsxs("p", { children: ["Are you entered?", _jsx("br", {}), "Login to find out. Good luck!"] }) }) })) })] }) })] }));
};
export default NextDrawing;
