import { getCMSObject } from 'sg-ui-components';

const UNEXPECTED_ERROR =
    'Unexpected Server Error. Please try again. If you still see the error, then please submit <a href="/feedback#contact">Feedback</a> form for further assistance.';

// Error Messages have mostly been moved to the CMS, here are the current error codes that are known to map
// back to an UNEXPECTED_ERROR as these will NOT be in the CMS.
//
/*
_NOT_TESTABLE: UNEXPECTED_ERROR,
_BAD_ACCT_TYPE: UNEXPECTED_ERROR,
_MISSING_DATA: UNEXPECTED_ERROR,
_NOSVCCONFIG: UNEXPECTED_ERROR,
_AUTH_MISSING: UNEXPECTED_ERROR,
_QUERY_NOT_FOUND: UNEXPECTED_ERROR,
_MISSING_PLAYER: UNEXPECTED_ERROR,
_HTTP_REMOTE: UNEXPECTED_ERROR,
_MISSING_2FA: UNEXPECTED_ERROR,
_EXPIRED_2FA: UNEXPECTED_ERROR,
_CLASS_METHOD: UNEXPECTED_ERROR,
_HTTP_FAIL: UNEXPECTED_ERROR,
_NO_TOS_PLAYER: UNEXPECTED_ERROR,
_NO_PLAYER_ACTION: UNEXPECTED_ERROR,
_UPS_FAIL: UNEXPECTED_ERROR,
_NO_PLAYER_ID: UNEXPECTED_ERROR,
_DEPRECATED: UNEXPECTED_ERROR,
_DUPLICATE_FAIL: UNEXPECTED_ERROR,
_EXPIRED_BLACKLIST: UNEXPECTED_ERROR,
_MISSING_REGISTER_BLACKLIST: UNEXPECTED_ERROR,
_DUP_REGISTER_EXCLUDE: UNEXPECTED_ERROR,
_NO_SYNC_NO_ID: UNEXPECTED_ERROR,

// Entry Errors
_GAUGE_PLAYER: UNEXPECTED_ERROR,
_BAD_GAUGE_CULL: UNEXPECTED_ERROR,
_BAD_CULL_AMOUNT: UNEXPECTED_ERROR,
_NOSVCCONFIG: UNEXPECTED_ERROR,
_QUERY_NOT_FOUND: UNEXPECTED_ERROR,
_HTTP_REMOTE: UNEXPECTED_ERROR,
_MISSING_INPUT: UNEXPECTED_ERROR,
_OBJECT_NOT_FOUND: UNEXPECTED_ERROR,
_BAD_ATTRIBUTE: UNEXPECTED_ERROR,
_REWARD_NOT_FAILED: UNEXPECTED_ERROR,
_PRIZE_DUPLICATE: UNEXPECTED_ERROR,
_PRIZE_INUSE: UNEXPECTED_ERROR,
_EMPTY_GAUGE_NAME: UNEXPECTED_ERROR,
_BAD_GAUGE_LIMIT: UNEXPECTED_ERROR,
_RULE_ELEMENTS: UNEXPECTED_ERROR,
_RULE_OPERATOR: UNEXPECTED_ERROR,
_RULE_HAS_ARRAY: UNEXPECTED_ERROR,
_HASANY_HASALL: UNEXPECTED_ERROR,
_BAD_OPERAND_COUNT: UNEXPECTED_ERROR,
_ARRAY_OPERAND: UNEXPECTED_ERROR,
_OPCODE_MISMATCH: UNEXPECTED_ERROR,
_CLASS_METHOD: UNEXPECTED_ERROR,
_EXECUTING: UNEXPECTED_ERROR,
_BAG_INUSE: UNEXPECTED_ERROR,
_TEMPLATE_FAILED: UNEXPECTED_ERROR,

// ELFE Errors
_CLASS_METHOD: UNEXPECTED_ERROR,
_HTTP_FAIL: UNEXPECTED_ERROR,

_NO_META_TYPE: UNEXPECTED_ERROR,

//Player Verification Errors
_NOSVCCONFIG: UNEXPECTED_ERROR,
_AUTH_MISSING: UNEXPECTED_ERROR,
_QUERY_NOT_FOUND: UNEXPECTED_ERROR,
_MISSING_PLAYER: UNEXPECTED_ERROR,
_HTTP_REMOTE: UNEXPECTED_ERROR,
_OBJECT_NOT_FOUND: UNEXPECTED_ERROR,
// Surveyor Errors
_MISSING_INPUT: UNEXPECTED_ERROR,
_NOSVCCONFIG: UNEXPECTED_ERROR,
_AUTH_MISSING: UNEXPECTED_ERROR,
_QUERY_NOT_FOUND: UNEXPECTED_ERROR,
_MISSING_PLAYER: UNEXPECTED_ERROR,
_HTTP_REMOTE: UNEXPECTED_ERROR,
_DUP_QUESTION: UNEXPECTED_ERROR,
_INLINE_NOT_FOUND: UNEXPECTED_ERROR,
_INLINE_NO_COMPLETE: UNEXPECTED_ERROR,
//  Global Errors
_UNKNOWN: UNEXPECTED_ERROR,
_QUERY: UNEXPECTED_ERROR,
_NOT_FOUND_MODEL: UNEXPECTED_ERROR,
*/

const interpolateDataIntoTemplate = (template, values) => {
    return template.replace(/<(\w+)>/g, (_, key) => values?.[key] || `<${key}>`);
  }

export const errorMessage = (code, data) => {
    const cmsErrorMessages = getCMSObject('data.messages.errorMessages.jsonBlock');
    let result = '';
    if (code) {
        result = cmsErrorMessages[code] || UNEXPECTED_ERROR;
        if (data) {
            result = interpolateDataIntoTemplate(result, data);
        }
        return result;
    }
};
