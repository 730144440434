var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { GamePlay } from '../../../index';
export var SetupRevealGames = function (_a) {
    var _b, _c, _d, _e, _f;
    var bonusing = _a.bonusing, revealToken = _a.revealToken, config = _a.config, additionalData = _a.additionalData;
    if (((_b = bonusing === null || bonusing === void 0 ? void 0 : bonusing.promosWithReveals) === null || _b === void 0 ? void 0 : _b.length) && ((_c = revealToken === null || revealToken === void 0 ? void 0 : revealToken.revealToken) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        var gamesData_1 = [];
        bonusing.promosWithReveals.map(function (currentPromotion) {
            var _a, _b, _c, _d, _e;
            if (((_a = currentPromotion === null || currentPromotion === void 0 ? void 0 : currentPromotion.reveal) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                var currentReward = currentPromotion.promoReward;
                // only show the game if we have more than one award and the reward is not a failure
                // or partial reward.
                if (((_b = currentReward === null || currentReward === void 0 ? void 0 : currentReward.reward) === null || _b === void 0 ? void 0 : _b.state) === 'failed' || // @ts-expect-error strange comparison here as currentReward?.awards?.length would be more appropriate but don't want to change any logic to avoid changing behavior during TS conversion
                    (currentReward === null || currentReward === void 0 ? void 0 : currentReward.awards) <= 1 ||
                    parseInt((_c = currentReward === null || currentReward === void 0 ? void 0 : currentReward.reward) === null || _c === void 0 ? void 0 : _c.actual_amount) < parseInt((_d = currentReward === null || currentReward === void 0 ? void 0 : currentReward.reward) === null || _d === void 0 ? void 0 : _d.intent_amount)) {
                    return;
                }
                // pick a reveal from the array of available reveals
                var reveals = currentPromotion.reveal;
                var randomReveal = reveals[Math.floor(Math.random() * (reveals === null || reveals === void 0 ? void 0 : reveals.length))];
                var outcomeData_1 = [];
                (_e = currentReward === null || currentReward === void 0 ? void 0 : currentReward.awards) === null || _e === void 0 ? void 0 : _e.forEach(function (award) {
                    outcomeData_1.push({
                        name: award.name,
                        amount: award.amount,
                        value: award.value,
                        selected: award.selected ? award.selected : false,
                    });
                });
                var additionalDataWithTicketDetails = __assign(__assign({}, additionalData), { promotionName: currentPromotion === null || currentPromotion === void 0 ? void 0 : currentPromotion.promotionName, ticketDetails: bonusing === null || bonusing === void 0 ? void 0 : bonusing.ticketDetails });
                var gameData = {
                    gameId: randomReveal,
                    outcomeData: outcomeData_1,
                    additionalData: additionalDataWithTicketDetails,
                };
                gamesData_1.push(gameData);
            }
        });
        return _jsx(GamePlay, { gameServer: (_e = (_d = config === null || config === void 0 ? void 0 : config.config) === null || _d === void 0 ? void 0 : _d.reveal_host) !== null && _e !== void 0 ? _e : '', gamesData: gamesData_1 !== null && gamesData_1 !== void 0 ? gamesData_1 : [], token: (_f = revealToken.revealToken) !== null && _f !== void 0 ? _f : '' });
    }
    else {
        return null;
    }
}; // end showReveal
SetupRevealGames.defaultProps = {
    // @ts-expect-error Don't want to change any logic to avoid changing behavior during TS conversion
    bonusing: {}, // @ts-expect-error Don't want to change any logic to avoid changing behavior during TS conversion
    revealToken: {}, // @ts-expect-error Don't want to change any logic to avoid changing behavior during TS conversion
    config: {},
    additionalData: {},
};
export default SetupRevealGames;
