import { stripsTagsAndSlashes } from '../../index';
var generatePathAndPage = function (routes, match) {
    var _a, _b, _c;
    var path = (_a = match === null || match === void 0 ? void 0 : match.path) !== null && _a !== void 0 ? _a : '';
    var data = routes && routes[stripsTagsAndSlashes(path)] ? routes[stripsTagsAndSlashes(path)] : match !== null && match !== void 0 ? match : {};
    var page = {
        title: (_b = data === null || data === void 0 ? void 0 : data.title) !== null && _b !== void 0 ? _b : '',
        className: (_c = data === null || data === void 0 ? void 0 : data.className) !== null && _c !== void 0 ? _c : '',
    };
    var siteData = {};
    siteData[stripsTagsAndSlashes(path)] = data;
    return [path, page, siteData];
};
export default generatePathAndPage;
