import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import DrawTicketForm from './DrawTicketForm';
import ScratchTicketForm from './ScratchTicketForm';
import { ContentBox, ContentBoxHead, ContentBoxBody, getCMSObject, CMSContent, IfLoggedIn, ErrorBoundary, TeleScript } from 'sg-ui-components';
import promotionConfig from '../../promotion_config';
import siteConfig from '../../promotion_config';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';

/**********************************************************************
 * Component:  EnterTicketTabs
 * Purpose:    Simple tabbed component for Draw/Scratch and or
 *             other ticket types.
 *
 *             Will NOT show if user is not logged in.
 *
 * Props:       none
 *
 * APIs used:   none
 *
 *  Notes:
 */
const EnterTicketTabs = ({ cmsSourceFirebase, user, page, config, prepopulatedTicket }) => {
    const [activeTab, setActiveTab] = useState('scratch');
    const [drawPrepopulated, setDrawPrepopulated] = useState('');
    const enterTicketTeleScript = getCMSObject('data.components.teleScripts.enterTickets.jsonBlock');

    useEffect(() => {
        if (prepopulatedTicket && prepopulatedTicket.length === parseInt(siteConfig.drawTicketLength)) {
            setDrawPrepopulated(prepopulatedTicket);
            setActiveTab('draw');
        }
        return () => {
            setDrawPrepopulated('');
            setActiveTab('scratch');
        };
    }, []);

    return (
        <IfLoggedIn user={user}>
            <div className='enter-tickets-area'>
                <ContentBox variant='theme-primary'>
                    <ContentBoxHead>Enter Tickets</ContentBoxHead>
                    <ContentBoxBody>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.webNotices'
                            className=''
                            noticePlacement='web-notice-ticket-entry'
                            cmsSourceFirebase={cmsSourceFirebase}
                            delayToShow={1000}>
                            <Tabs defaultActiveKey='scratch' id='enterTicketTabs' activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)} justify>
                                {promotionConfig.scratchTicketFields ? (
                                    <Tab eventKey='scratch' title={enterTicketTeleScript?.instantTixTabName ?? 'Scratchers'}>
                                        <ErrorBoundary
                                            fallback={<TeleScript line={enterTicketTeleScript?.scratchFallback} />}
                                            details={{ page, user, config, ticketType: 'scratch' }}>
                                            <ScratchTicketForm/>
                                        </ErrorBoundary>
                                    </Tab>
                                ) : null}
                                {promotionConfig.drawFields ? (
                                    <Tab eventKey='draw' title={enterTicketTeleScript?.drawTixTabName ?? 'Draw Games'}>
                                        <ErrorBoundary
                                            fallback={<TeleScript line={enterTicketTeleScript?.drawFallback} />}
                                            details={{ page, user, config, ticketType: 'draw' }}>
                                            <DrawTicketForm prepopulatedTicket={drawPrepopulated} />
                                        </ErrorBoundary>
                                    </Tab>
                                ) : null}
                            </Tabs>
                        </CMSContent>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </IfLoggedIn>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterTicketTabs);
