import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
var IfLoggedIn = function (_a) {
    var user = _a.user, children = _a.children;
    if (user === null || user === void 0 ? void 0 : user.loggedIn) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return null;
    }
};
export default IfLoggedIn;
