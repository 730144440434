import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../utils/fontAwesome';
import './password-field.scss';
/**
 * Simple component that displays a password field that can toggle between hidden (dots) and visible text.
 */
var PasswordField = function (_a) {
    var name = _a.name, value = _a.value, handleChange = _a.handleChange, _b = _a.placeholder, placeholder = _b === void 0 ? '' : _b, className = _a.className, handleKeyUp = _a.handleKeyUp;
    var _c = useState('password'), type = _c[0], setType = _c[1];
    var handleClick = function () { return setType(type === 'text' ? 'password' : 'text'); };
    var cssClass = className ? className : 'form-control theme-input password-field';
    return (_jsxs("div", { className: className, children: [_jsx("input", { type: type, className: cssClass, id: name, value: value, name: name, onChange: handleChange, onBlur: handleKeyUp, "aria-describedby": "".concat(name, "Help"), placeholder: placeholder }), _jsx("span", { className: 'password-show', onClick: handleClick, children: type === 'text' ? (_jsx("span", { "data-testid": 'eye-slash-icon', className: 'oi oi-eye', children: _jsx(FontAwesomeIcon, { icon: 'eye-slash' }) })) : (_jsx("span", { "data-testid": 'eye-icon', className: 'oi oi-eye', children: _jsx(FontAwesomeIcon, { icon: 'eye' }) })) })] }));
};
export default PasswordField;
