var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
export var defaultNotificationState = {
    notification: '',
};
// REDUCERS
var setNotificationReducer = function (state, action) {
    return {
        notification: action.payload.notification,
    };
};
var unsetNotificationReducer = function (state, action) {
    return {
        notification: '',
    };
};
var resetReducer = function (state, action) {
    return __assign({}, defaultNotificationState);
};
export var notificationSlice = createSlice({
    name: 'notification',
    initialState: defaultNotificationState,
    reducers: {
        // add an action to chceck localstorage
        // for existing session
        setNotification: setNotificationReducer,
        unsetNotification: unsetNotificationReducer,
        reset: resetReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var setNotification = function (dispatch, payload) {
    dispatch(notificationSlice.actions.setNotification(payload));
    setTimeout(function () {
        dispatch(notificationSlice.actions.unsetNotification(payload));
    }, 5000);
};
var unsetNotification = function (dispatch, payload) {
    dispatch(notificationSlice.actions.unsetNotification(payload));
};
export var notificationActions = function (dispatch) {
    return {
        // dispatching plain actions
        setNotification: function (payload) { return setNotification(dispatch, payload); },
        unsetNotification: function (payload) { return unsetNotification(dispatch, payload); },
        reset: function (payload) { return dispatch(notificationSlice.actions.reset(payload)); },
    };
};
