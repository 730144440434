import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    BoxedContent,
    CMSContent,
    UpdatePassword,
    UpdateEmail,
    UpdateIdentification,
    UpdateContactInformation,
    hasCMSContent,
    getCMSObject,
    DeleteAccount,
    DisplayContent,
    ErrorBoundary,
    TeleScript,
} from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';

import Layout from '../Components/Layout';
import { OptinAccordian } from '../Components/Optins/optin_form';
import getConfigForComponent from '../utils/getConfigForComponent';

const MyProfilePage = ({ user, loading, config, cmsSourceFirebase, actions, match }) => {
    useEffect(() => {
        actions?.userActions?.updateSection?.({ section: null, status: null, code: null });
    }, []);
    const updateIdentificationTelescript = getCMSObject('data.components.teleScripts.updateIdentification.jsonBlock');
    const updateContactInformationTelescript = getCMSObject('data.components.teleScripts.updateContactInformation.jsonBlock');
    const myProfileTelescript = getCMSObject('data.components.teleScripts.myProfile.jsonBlock');
    const updateEmailTelescript = getCMSObject('data.components.teleScripts.MyProfileUpdateEmail.jsonBlock');
    const updatePasswordTelescript = getCMSObject('data.components.teleScripts.MyProfileUpdatePassword.jsonBlock');

    const deleteAccount = getConfigForComponent('deleteAccount', config);

    let genders = getCMSObject('data.sitewideSettings.genders.jsonBlock');
    genders = Array.isArray(genders) ? genders : [];

    let states = getCMSObject('data.sitewideSettings.states.jsonBlock');
    states = Array.isArray(states) ? states : [];

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-10'>
                    <UpdateIdentification
                        user={user}
                        loading={loading}
                        actions={actions}
                        telescript={updateIdentificationTelescript}
                        editableFields={[]}
                        genders={genders}
                        options={{
                            variant: 'theme-primary',
                            hasCollapser: true,
                            showByDefault: true,
                            hash: 'update-identification',
                        }}
                    />
                    <UpdateEmail
                        user={user}
                        loading={loading}
                        actions={actions}
                        telescript={updateEmailTelescript}
                        options={
                            config?.site?.components?.updateEmail ?? {
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: true,
                                hash: 'update-email',
                            }
                        }
                    />

                    <UpdatePassword
                        user={user}
                        loading={loading}
                        actions={actions}
                        telescript={updatePasswordTelescript}
                        options={
                            config?.site?.components?.updatePassword ?? {
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: true,
                                hash: 'update-password',
                                minimumPasswordLength: 10,
                            }
                        }
                    />

                    <UpdateContactInformation
                        user={user}
                        loading={loading}
                        actions={actions}
                        telescript={updateContactInformationTelescript}
                        editableFields={['address1', 'address2', 'city', 'state', 'zip', 'phone']}
                        states={states}
                        options={{
                            variant: 'theme-primary',
                            hasCollapser: true,
                            showByDefault: false,
                            hash: 'update-contact-information',
                        }}
                    />

                    <OptinAccordian telescript={myProfileTelescript} />

                    <BoxedContent
                        note='Self Exclusion section'
                        hash='self-exclusion'
                        isActive={false}
                        isVisible={hasCMSContent('data.legalAndPolicyItems.selfExclusion.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.selfExclusion.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.selfExclusion.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </BoxedContent>

                    <DisplayContent note='Show Delete Account section' isVisible={deleteAccount?.enabled}>
                        <ErrorBoundary fallback={<TeleScript line={deleteAccount?.fallback} />} details={{ user, config }}>
                            <DeleteAccount
                                user={user}
                                loading={loading}
                                cmsSourceFirebase={cmsSourceFirebase}
                                actions={actions}
                                telescript={deleteAccount?.telescript}
                                options={
                                    deleteAccount?.config ?? {
                                        variant: 'theme-primary',
                                        hasCollapser: true,
                                        showByDefault: false,
                                        hash: 'delete-account',
                                    }
                                }
                            />
                        </ErrorBoundary>
                    </DisplayContent>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);
