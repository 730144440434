//***********************************************************************************
//  Inline validation for Password to make sure that it matches the password Schema
//
import { validatePasswordDetailed } from '../../validationRules';

export const CheckPassword = async (password) => {
    const passwordSchema = validatePasswordDetailed;
    const valid = await passwordSchema.isValid(password);
    let status = '';
    if (valid) {
        return 'valid';
    } else {
        status = await passwordSchema.validate(password, { abortEarly: true }).catch(function (err) {
            return err.message.toString();
        });
        return status;
    }
}; // end checkPassword
