var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { ContentBoxHead, ContentBox, ContentBoxBody, CMSContent, TeleScript } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
library.add(faCheck);
/**********************************************************************
 * Component: SurveyFormTemplate
 * Purpose:   Displays the survey questions for the survey name that
 *            is passed in via an URL Parameter.   Redirects to
 *            Post Claim upon completion of survey.
 *
 * Props: -   actions - actions table for api calls
 *            survey -  reference to the survey store
 *            loading - reference to the loading store
 *
 * APIs used:  /survey/status - get the status of the current survey
 *             /survey/questions -  get list of questions for this survey
 *             /survey/question/{question_id}/answer - submit answer to each question
 *             /survey/{survey_id}/complete -  complete the survey
 */
var SurveyItems = function (_a) {
    var actions = _a.actions, surveyQuestions = _a.surveyQuestions;
    //***********************************************************************************
    // Handle the various types of question based on the data-type.    Also checks
    // to see if the question has been answered prior and if it's a required question.
    //
    var RenderQuestionType = function (question) {
        var required = question.is_required ? true : false;
        var answer = question.answers[0] && question.answers[0].response ? question.answers[0].response : '';
        if (question.datatype === 'integer') {
            return (_jsx("input", { type: 'number', className: 'form-control', id: question.query, min: question.min === '0' ? Number.MIN_SAFE_INTEGER : question.min, max: question.max === '0' ? Number.MAX_SAFE_INTEGER : question.max, required: required, onChange: function (e) { return onChange(e, question); }, onBlur: function (e) { return onBlur(e, question); }, value: answer }, question.query));
        }
        else if (question.datatype === 'money') {
            return (_jsxs("div", { className: 'input-group', children: [_jsx("div", { className: 'input-group-prepend', children: _jsx("span", { className: 'input-group-text', id: 'basic-addon1', children: "$" }) }), _jsx("input", { type: 'number', className: 'form-control', id: question.query, min: question.min === '0' ? Number.MIN_SAFE_INTEGER : question.min, max: question.max === '0' ? Number.MAX_SAFE_INTEGER : question.max, required: required, onChange: function (e) { return onChange(e, question); }, onBlur: function (e) { return onBlur(e, question); }, value: answer }, question.query)] }));
        }
        else if (question.datatype === 'string' || question.datatype === 'text') {
            return (_jsx("input", { type: 'text', className: 'form-control', id: question.query, maxLength: 255, required: required, onChange: function (e) { return onChange(e, question); }, value: answer }, question.query));
        }
        else if (question.datatype === 'enum') {
            return question.enums.map(function (option, index) {
                return (_jsxs("div", { className: 'form-check', children: [_jsx("input", { className: 'form-check-input', type: 'radio', name: question.query, id: option, checked: answer === option, value: option, onChange: function (e) { return onChange(e, question); }, required: required }, "".concat(question.query, "=").concat(index, " input")), _jsx("label", { className: 'form-check-label', htmlFor: option, children: option })] }, "".concat(question.query, "=").concat(index)));
            });
        }
        else if (question.datatype === 'boolean') {
            return (_jsxs("div", { children: [_jsxs("div", { className: 'form-check', children: [_jsx("input", { type: 'radio', className: 'form-check-input', name: "".concat(question.query, " yes"), id: 'yes', value: 'true', checked: answer === 'true', onChange: function (e) { return onChange(e, question); } }, "".concat(question.query, " true")), _jsx("label", { htmlFor: 'yes', className: 'form-check-label', children: "Yes" })] }), _jsxs("div", { className: 'form-check', children: [_jsx("input", { type: 'radio', className: 'form-check-input', name: "".concat(question.query, " no"), id: 'no', value: 'false', checked: answer === 'false', onChange: function (e) { return onChange(e, question); } }, "".concat(question.query, " false")), _jsx("label", { htmlFor: 'no', className: 'form-check-label', children: "No" })] })] }));
        }
        else {
            return (_jsx("input", { type: 'text', className: 'form-control', id: question.query, maxLength: 255, onChange: function (e) { return onChange(e, question); }, value: answer, required: required, defaultValue: answer }, question.query));
        }
    }; // End RenderQuestionType
    //***********************************************************************************
    // On Blur for number answer fields, submit the answer to the backend.
    //
    var onBlur = function (event, question) { return __awaiter(void 0, void 0, void 0, function () {
        var target, value;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    target = event.target;
                    value = target.value;
                    if (!(target.type === 'number' || target.type === 'money')) return [3 /*break*/, 6];
                    if (!(question.max !== '0' && parseInt(value) > parseInt(question.max))) return [3 /*break*/, 2];
                    return [4 /*yield*/, actions.surveyActions.setSurveyAnswer({
                            question_id: question.id,
                            answer: question.max,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 2:
                    if (!(parseInt(value) < parseInt(question.min))) return [3 /*break*/, 4];
                    return [4 /*yield*/, actions.surveyActions.setSurveyAnswer({
                            question_id: question.id,
                            answer: question.min,
                        })];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, actions.surveyActions.setSurveyAnswer({
                        question_id: question.id,
                        answer: value,
                    })];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); }; // end onChange
    //***********************************************************************************
    // On Change for any answer field, submit the answer to the backend.
    //
    var onChange = function (event, question) { return __awaiter(void 0, void 0, void 0, function () {
        var target, value;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    target = event.target;
                    value = target.value;
                    if (!!value) return [3 /*break*/, 2];
                    return [4 /*yield*/, actions.surveyActions.setSurveyAnswer({
                            question_id: question.id,
                            answer: null,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, actions.surveyActions.setSurveyAnswer({
                        question_id: question.id,
                        answer: value,
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }; // end onChange
    //***********************************************************************************
    // Print out the question label and the question type
    //
    var EMPTY_RESPONSE_VALUES = ['', null];
    return (_jsx("ol", { children: surveyQuestions.map(function (question, index) {
            var _a, _b, _c;
            return (_jsx("li", { children: _jsxs("div", { className: 'form-group', children: [_jsxs("label", { className: 'd-block', children: [question.query, " ", question.is_required ? '*' : '', ((_a = question.answers) === null || _a === void 0 ? void 0 : _a.length) > 0 && !EMPTY_RESPONSE_VALUES.includes((_c = (_b = question.answers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.response) ? (_jsx(FontAwesomeIcon, { icon: faCheck, className: 'float-right' })) : null] }), RenderQuestionType(question)] }) }, index));
        }) }));
}; // end SurveyItems
var SurveyForm = function (_a) {
    var actions = _a.actions, survey = _a.survey, cmsSourceFirebase = _a.cmsSourceFirebase, telescript = _a.telescript, Redirect = _a.Redirect;
    var _b = useState(''), surveyErrors = _b[0], setSurveyErrors = _b[1];
    var _c = useState(false), surveySuccess = _c[0], setSurveySuccess = _c[1];
    var _d = useState(''), surveyName = _d[0], setSurveyName = _d[1];
    var _e = useState(false), loaded = _e[0], setLoaded = _e[1];
    var _f = useState(false), questionsFetched = _f[0], setQuestionsFetched = _f[1];
    var scrollRef = useRef(null);
    function getSurveyStatus() {
        return __awaiter(this, void 0, void 0, function () {
            var thisSurvey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        thisSurvey = new URLSearchParams(document.location.search).get('survey');
                        if (!thisSurvey) return [3 /*break*/, 2];
                        setSurveyName(thisSurvey);
                        return [4 /*yield*/, actions.surveyActions.getSurveyStatus({ name: thisSurvey })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        setLoaded(true);
                        return [2 /*return*/];
                }
            });
        });
    }
    function getSurveyQuestions() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, actions.surveyActions.getNamedSurveyQuestions(surveyName)];
                    case 1:
                        _a.sent();
                        setQuestionsFetched(true);
                        return [2 /*return*/];
                }
            });
        });
    }
    //***********************************************************************************
    // On load, see what the status of the survey is and determine if the player
    // has already taken it.
    //
    useEffect(function () {
        if (!loaded) {
            getSurveyStatus();
        }
    }, [loaded]); // end useEffect
    //***********************************************************************************
    // Check the survey store to see if there are any errors, or if the survey was
    // completed.    Otherwise, get the questions for rendering.
    //
    useEffect(function () {
        var _a, _b;
        if ((_a = survey === null || survey === void 0 ? void 0 : survey.errors) === null || _a === void 0 ? void 0 : _a.code) {
            setSurveyErrors((_b = survey === null || survey === void 0 ? void 0 : survey.errors) === null || _b === void 0 ? void 0 : _b.code);
        }
        else if (survey.status && survey.status === 'completed' && survey.multiple_responses === false) {
            setSurveySuccess(true);
        }
        else if (survey.multiple_responses === true ||
            (survey.status && survey.status === 'not_started') ||
            survey.status === 'in_process' ||
            survey.status === 'ready_to_complete') {
            // Limit the amount of times we call for the questions.
            if (!questionsFetched) {
                getSurveyQuestions();
            }
        }
        // Noop if Survey status is not set and no errors.
    }, [survey]);
    //***********************************************************************************
    // Call to complete survey, if no errors - go to post-claim and see if there is an award.
    //
    var completeSurvey = function () { return __awaiter(void 0, void 0, void 0, function () {
        var requiredQuestions, questionCheck, questionErrors;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    requiredQuestions = survey.surveyQuestions.filter(function (obj) { return obj.is_required === true; });
                    questionCheck = requiredQuestions.every(function (obj) { return !Object.values(obj.answers).every(function (x) { return x.response === null || x.response === ''; }); });
                    if (!questionCheck) return [3 /*break*/, 2];
                    setSurveyErrors('');
                    return [4 /*yield*/, actions.surveyActions.completeSurvey({ survey_id: (_a = survey.surveyQuestions[0].survey_id) !== null && _a !== void 0 ? _a : '' })];
                case 1:
                    _c.sent();
                    setSurveySuccess(true);
                    return [3 /*break*/, 3];
                case 2:
                    questionErrors = requiredQuestions.filter(function (obj) {
                        return Object.values(obj.answers).every(function (x) { return x.response === null || x.response === ''; });
                    });
                    setSurveyErrors("".concat(questionErrors
                        .flat()
                        .filter(function (res) { return res !== null; })
                        .map(function (error) { return "<br/> ".concat(error.query, "*"); })));
                    (_b = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }; // end completeSurvey
    if (!loaded) {
        return (
        // TODO: Extract separate component for loading state
        _jsx(React.Fragment, { children: _jsx("div", { className: 'loading-indicator', role: 'status', children: _jsx("div", { className: 'd-flex justify-content-center bg-white', children: _jsx("div", { className: 'loading-indicator-spinner', role: 'status', children: _jsx("span", { className: 'sr-only', children: "Loading..." }) }) }) }) }, 'loading-indicator-section'));
    }
    return (_jsxs(ContentBox, { variant: 'theme-primary', children: [_jsx(ContentBoxHead, { children: "Survey" }), _jsx(ContentBoxBody, { children: _jsxs("div", { className: 'raf-form container', ref: scrollRef, children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.surveyInstructions.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), surveyErrors ? (_jsx("div", { className: 'row m-1', children: _jsxs("div", { className: 'alert alert-danger col-12 d-flex flex-column', role: 'alert', children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.missingFieldsText, children: "Missing these required fields:" }), _jsx("div", { className: 'p-0 m-0', dangerouslySetInnerHTML: { __html: surveyErrors } })] }) })) : null, surveySuccess ? (_jsx(Redirect, { to: {
                                pathname: '/post-claim',
                                state: { motive: 'Survey' },
                                motive: 'Survey',
                            } })) : (_jsxs("div", { children: [_jsx("div", { className: 'row m-1', children: _jsxs("div", { className: 'col', children: [_jsx("small", { className: 'row align-items-center align-content-center', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.requiredQuestionsText, children: "Required Questions marked with a *" }) }), _jsxs("small", { className: 'row align-items-center align-content-center', children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.completedQuestionsText, children: "Completed Questions marked with a" }), _jsx(FontAwesomeIcon, { className: 'ml-2', icon: faCheck })] }), _jsx("br", {}), _jsx(SurveyItems, { surveyQuestions: survey.surveyQuestions, actions: actions })] }) }), _jsx("div", { className: 'row m-1', children: _jsx("div", { className: 'col text-center', children: _jsx("button", { type: 'button', className: 'survey-submit', onClick: completeSurvey, children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.submitText, children: "Complete Survey" }) }) }) })] }))] }) })] }));
};
export default SurveyForm;
