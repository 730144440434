var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import TeleScript from '../../Components/TeleScript/TeleScript';
import { logError } from '../../utils/logError';
export function withErrorBoundary(WrappedComponent, telescript) {
    return function ErrorBoundaryWrappedComponent(props) {
        return (_jsx(ErrorBoundary, { details: { page: WrappedComponent.displayName, url: document.location.href }, fallback: telescript && _jsx(TeleScript, { line: telescript, children: undefined }), children: _jsx(WrappedComponent, __assign({}, props)) }));
    };
}
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function () {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        console.log('🔥 Component caught an error:', error, errorInfo);
        logError({
            errorCode: 'JAVASCRIPT_ERROR',
            severity: 'critical',
            category: 'generic',
            user: this.props.details.user,
            details: __assign(__assign({}, this.props.details), { errorMessage: error === null || error === void 0 ? void 0 : error.message, errorInfo: errorInfo }),
        });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            // Render any custom fallback UI
            return this.props.fallback;
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
