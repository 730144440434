var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
export var defaultEntryState = {
    entries: [],
    drawings: [],
    points: 0,
    last_entry: null,
    game_over: false,
    error: '',
};
// REDUCERS
// basic actions that affect this object only
var getEntriesReducer = function (state, action, promotionConfig) {
    if (action.payload.entries != undefined) {
        var ents = action.payload.entries.filter(function (ent) {
            return ent.game_id === promotionConfig.promotion_id;
        });
        var points = ents.reduce(function (sum, val) {
            return sum + parseInt(val.points);
        }, 0);
        return __assign(__assign({}, state), { entries: ents, points: points, drawings: action.payload.drawings });
    }
    else {
        return state;
    }
};
var resetReducer = function (state, action) {
    return __assign({}, defaultEntryState);
};
var setLastEntryReducer = function (state, action) {
    return __assign(__assign({}, state), { last_entry: action.payload });
};
var clearErrorsReducer = function (state, action) {
    return __assign({}, state);
};
export var entrySlice = createSlice({
    name: 'entry',
    initialState: defaultEntryState,
    reducers: {
        getEntries: getEntriesReducer,
        reset: resetReducer,
        setLastEntry: setLastEntryReducer,
        clearErrors: clearErrorsReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getEntries = function (dispatch, payload, apiClient, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
                return [4 /*yield*/, apiClient().getEntries()];
            case 1:
                result = _a.sent();
                dispatch(entrySlice.actions.getEntries(result, promotionConfig));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var entryActions = function (dispatch) {
    return {
        getEntries: function (payload) { return getEntries(dispatch, payload, apiClient, promotionConfig); },
        reset: function (payload) { return dispatch(entrySlice.actions.reset(payload)); },
        setLastEntry: function (payload) { return dispatch(entrySlice.actions.setLastEntry(payload)); },
        clearErrors: function (payload) { return dispatch(entrySlice.actions.clearErrors()); },
    };
};
// TEST REDUX OBJECT FOR ENTRIES
// {
//     type: 'entry/getEntries',
//     payload: {
//         entries: [
//             {
//                 game_id: '7366',
//                 entry_id: '33',
//                 points: '5',
//                 multiplier: '1',
//                 entry_date: '2019-12-03 14:31:51',
//                 ticket_number: '7344-1254679-918 8698146695',
//                 entry_date_local_tz: '2019-12-03 13:31:51',
//                 script_id: '0',
//                 game_type: 'Instant',
//                 draw_id: '22',
//                 category: '1',
//                 draw_number: 1
//             },
//             {
//                 game_id: '7366',
//                 entry_id: '34',
//                 points: '5',
//                 multiplier: '1',
//                 entry_date: '2019-12-03 14:33:51',
//                 ticket_number: '7344-111111-918 8698146695',
//                 entry_date_local_tz: '2019-12-03 13:31:51',
//                 script_id: '0',
//                 game_type: 'Instant',
//                 draw_id: '21',
//                 category: '1',
//                 draw_number: 2
//             },
//         ],
//         drawings: [
//         {
//             id: '22',
//             game_id: '7366',
//             category: '1',
//             start_date: '2020-04-24 00:00:00',
//             end_date: '2020-05-04 12:59:59',
//             draw_date: '2020-12-14',
//             draw_status: 'current',
//             draw_number: 1
//         },
//         {
//             id: '21',
//             game_id: '7366',
//             category: '1',
//             start_date: '2020-05-05 13:00:00',
//             end_date: '2021-12-21 23:59:59',
//             draw_date: '2021-12-26',
//             draw_status: 'future',
//             draw_number: 2
//         }
//         ]
//     }
// }
