import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store.js';
import Layout from '../Components/Layout';
import VerificationError from '../Components/RegisterForm/VerificationError.js';

const RegisterVerificationErrorPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <VerificationError />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerificationErrorPage);
