var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
/**********************************************************************
 * Component:  RegistrationOptins
 * Purpose:   Used to create the notification selection chckboxes that
 *            are displayed on the Registration page 1.   This grab all
 *            optins available and filters out the ones in the
 *            "registration" category and displays that.
 *
 *            The parent (calling) class defines the callback to
 *            subscribe/unsubcribe as part of the registration
 *            payload.  So does NOT use the subscribe/unsubscribe APIs.
 *
 * Props: -   parentCallback -  callback returned to the parent (registration)
 *
 * APIs used: mrbEnsemble.getAllOptions
 */
var RegistrationOptins = function (_a) {
    var actions = _a.actions, optins = _a.optins, _b = _a.optinCategory, optinCategory = _b === void 0 ? 'registration' : _b, _c = _a.parentCallback, parentCallback = _c === void 0 ? '' : _c;
    var _d = useState(false), loaded = _d[0], setLoaded = _d[1];
    var _e = useState([]), registrationOptins = _e[0], setRegistrationOptins = _e[1];
    var _f = useState(new Map()), registrationCheckedState = _f[0], setRegistrationCheckedState = _f[1];
    //***********************************************************************************
    // Gets all the optins available (without any player data attached).
    // this could be a large list and will need to be filtered down by category
    var getAllOptins = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, actions.optinsActions.getAllOptins()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    //***********************************************************************************
    // Gets all optins when component loads.
    useEffect(function () {
        if (!loaded) {
            setLoaded(true);
            getAllOptins();
        }
    }, [loaded]); // end useEffect
    //***********************************************************************************
    // Iterates over the optins list and filters out only the ones in the registration
    // category, as this component should only be used on registration pages.
    //
    // Creates a MAP that keeps track of the checked status of the optin.
    useEffect(function () {
        var myOptins = optins.allOptins;
        if (myOptins) {
            // Filter the Optin List so it just contains the registration optin
            var regOptins = myOptins.filter(function (regOpton) { return regOpton.context === optinCategory; });
            setRegistrationOptins(regOptins);
            // Map checked values of by optinID.
            var checkedStateById_1 = new Map();
            // using a map of maps here to keep track of the clicked state of the option box.
            // Remapping to confirm with the optin array data requested by backend in /player/register
            // for the front-end we will always assume the subscription type is email.
            regOptins.forEach(function (optin) {
                checkedStateById_1.set(optin.id, {
                    checkedState: true,
                    optin: { type: optin.type, subscriptions: [{ type: 'email' }] },
                });
            });
            setRegistrationCheckedState(new Map(checkedStateById_1));
        }
    }, [optins]); // end useEffect
    //***********************************************************************************
    // Checked state for this widget managed internally since we won't change the
    // store state until post-registration.
    //
    var handleClick = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var newCheckedState, isChecked;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(registrationCheckedState.size > 0)) return [3 /*break*/, 2];
                    newCheckedState = new Map(registrationCheckedState);
                    isChecked = newCheckedState.get(id);
                    isChecked.checkedState = !isChecked.checkedState;
                    newCheckedState.set(id, isChecked);
                    // Maps are a special beast with set state
                    setRegistrationCheckedState(new Map(registrationCheckedState));
                    if (!(parentCallback === '')) return [3 /*break*/, 2];
                    if (!newCheckedState.get(id)) return [3 /*break*/, 2];
                    return [4 /*yield*/, actions.optinsActions.optinSubscribe(id)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }; // end handleChange
    return (_jsx("div", { className: 'form-check my-3', children: registrationOptins
            ? registrationOptins.map(function (optin) {
                return (_jsxs("div", { children: [_jsx("input", { type: 'checkbox', id: optin.id, name: optin.type, className: 'form-check-input', value: optin.id.toString(), checked: registrationCheckedState.get(optin.id) ? registrationCheckedState.get(optin.id).checkedState : 'true', onClick: function () { return handleClick(optin.id); }, onChange: parentCallback != '' ? parentCallback(registrationCheckedState) : '' }), _jsx("label", { htmlFor: 'marketing', className: 'form-check-label', children: optin.tag })] }, optin.id));
            })
            : '' }));
}; // end RegistrationOptins
export default RegistrationOptins;
