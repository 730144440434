import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CMSContent } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import Menu from '../../Menu.js';
import SocialMenu from '../../SocialMenu';

const Footer = ({ cmsSourceFirebase }) => {
    return (
        <div className='page-footer'>
            <footer>
                <div className='container'>
                    <div className='row justify-content-between align-items-center no-gutters text-center text-md-right'>
                        <div className='col-12 col-md-2'>
                            <FooterLotteryLogo />
                        </div>
                        <div className='col-12 col-md-10'>
                            <SocialNav />
                            <FooterNav />
                            <SecondaryNav />
                            <div className='disclaimer'>
                                <CMSContent
                                    localStorageObject='webContent'
                                    contentPath='data.sitewideSettings.footerDisclaimer.contentHTML'
                                    cmsSourceFirebase={cmsSourceFirebase}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

const FooterLotteryLogoTemplate = ({ cmsSourceFirebase }) => {
    return (
        <div className='lottery-logo-link-container'>
            <CMSContent
                localStorageObject='webContent'
                contentPath='data.sitewideSettings.footerLogo.image'
                className='img-fluid'
                cmsSourceFirebase={cmsSourceFirebase}
            />
        </div>
    );
};

const FooterLotteryLogo = connect(mapStateToProps, mapDispatchToProps)(FooterLotteryLogoTemplate);

const FooterNavTemplate = ({ cmsSourceFirebase }) => {
    const [localStorageFooterMenu, setLocalStorageFooterMenu] = useState([]);

    useEffect(() => {
        setLocalStorageFooterMenu(JSON.parse(window.localStorage.getItem('webMenu'))?.footerMenu);
    }, [cmsSourceFirebase.ready]);

    return (
        <div id='footer-main-nav'>
            <Menu menu_json={localStorageFooterMenu} />
        </div>
    );
};

const FooterNav = connect(mapStateToProps, mapDispatchToProps)(FooterNavTemplate);

const SecondaryNavTemplate = ({ cmsSourceFirebase }) => {
    const [localStorageFooterSecondaryMenu, setLocalStorageFooterSecondaryMenu] = useState([]);

    useEffect(() => {
        setLocalStorageFooterSecondaryMenu(JSON.parse(window.localStorage.getItem('webMenu'))?.footerSecondaryMenu);
    }, [cmsSourceFirebase.ready]);

    return (
        <div id='footer-secondary-nav'>
            <Menu menu_json={localStorageFooterSecondaryMenu} />
        </div>
    );
};

const SecondaryNav = connect(mapStateToProps, mapDispatchToProps)(SecondaryNavTemplate);

const SocialNavTemplate = ({ cmsSourceFirebase }) => {
    const [localStorageSocialMenu, setLocalStorageSocialMenu] = useState([]);

    useEffect(() => {
        setLocalStorageSocialMenu(JSON.parse(window.localStorage.getItem('webMenu'))?.socialMenu);
    }, [cmsSourceFirebase.ready]);

    return (
        <div id='footer-social-nav'>
            <SocialMenu menu_json={localStorageSocialMenu} />
        </div>
    );
};

const SocialNav = connect(mapStateToProps, mapDispatchToProps)(SocialNavTemplate);
