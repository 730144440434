import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isApp } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import promotion from '../promotion_config';

import { IdleTimerProvider } from 'react-idle-timer';

const IdleTimeout = ({ loading, user, config, actions }) => {
    const [auto_terminate, setAutoTerminate] = useState(config.config.auto_terminate_seconds);

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
        if (config.config) {
            setAutoTerminate(config.config.auto_terminate_seconds);
        }
    }, [config]);

    const timeout = auto_terminate !== '' ? parseInt(auto_terminate, 10) * 1000 : auto_terminate;

    let myLocation = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';

    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');

    if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig']) {
        return null;
    } else {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotion.getLogoutDestination(myHost, config.config.logout_host_uri);

        return user.loggedIn && timeout ? (
            <>
                <IdleTimerProvider
                    onIdle={async () => {
                        // user has been idle for auto_terminate_seconds
                        console.log('User timed out: ' + timeout);
                        window.location.search = '';
                        if (isApp()) {
                            console.log('Sending postMessage to refresh token');
                            window.parent.postMessage(
                                {
                                    message: 'openLogin',
                                    error: 'token_expired',
                                    redirect: myLocation,
                                },
                                '*'
                            );
                        } else {
                            await actions.userActions.logout();
                            window.location.href = logoutDestination;
                        }
                    }}
                    timeout={timeout}
                />
            </>
        ) : null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimeout);
