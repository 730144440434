var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { bonusingSlice } from './BonusingStore';
export var defaultScratchTicketState = {
    gameOver: false,
    success: false,
    message: '',
    entry: '',
};
// REDUCERS
// basic actions that affect this object only
var enterScratchTicketReducer = function (state, action) {
    return __assign(__assign({}, state), { success: action.payload.success, message: action.payload.message, entry: action.payload.entry });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultScratchTicketState);
};
var setGameOverReducer = function (state, action) {
    return __assign(__assign({}, state), { gameOver: action.payload.gameOver });
};
export var scratchTicketSlice = createSlice({
    name: 'scratchTicket',
    initialState: defaultScratchTicketState,
    reducers: {
        enterScratchTicket: enterScratchTicketReducer,
        reset: resetReducer,
        setGameOver: setGameOverReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var enterScratchTicket = function (dispatch, payload, apiClient, validateTicketResponse) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'enterScratchTicket' }));
                return [4 /*yield*/, apiClient().enterScratchTicket(payload)];
            case 1:
                result = _a.sent();
                result = validateTicketResponse(result);
                dispatch(bonusingSlice.actions.setBarObject(result));
                dispatch(scratchTicketSlice.actions.enterScratchTicket(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'enterScratchTicket' }));
                return [2 /*return*/];
        }
    });
}); };
var setGameOver = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'setGameOver' }));
                return [4 /*yield*/, apiClient().getGameOver(payload)];
            case 1:
                result = _a.sent();
                dispatch(scratchTicketSlice.actions.setGameOver(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'setGameOver' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var scratchTicketActions = function (dispatch, apiClient, validateTicketResponse) {
    return {
        enterScratchTicket: function (payload) { return enterScratchTicket(dispatch, payload, apiClient, validateTicketResponse); },
        reset: function (payload) { return dispatch(scratchTicketSlice.actions.reset()); },
        setGameOver: function (payload) { return setGameOver(dispatch, payload, apiClient); },
    };
};
