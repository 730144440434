var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';
import { drawTicketSlice } from './DrawTicketStore';
import { scratchTicketSlice } from './ScratchTicketStore';
import isJSON from '../utils/isJSON';
import getCookieByName from '../utils/getCookieByName';
export var defaultUserState = {
    player: {
        name: '',
        email: '',
        player_id: '',
        firstname: '',
        lastname: '',
        emailconfirm: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        area_phone1: '',
        prefix_phone1: '',
        suffix_phone1: '',
        phone2: '',
        area_phone2: '',
        prefix_phone2: '',
        suffix_phone2: '',
        birthdate: '',
        dob_year: '',
        dob_month: '',
        dob_day: '',
        password: '',
        verify_password: '',
        optins: [],
        registrar_id: '',
        account_type: '',
        actions: [],
    },
    loggedIn: false,
    errors: '',
    errorData: {},
    message: '',
    success: false,
    registerSuccess: false,
    updateSection: {
        section: null,
        status: null,
        code: null,
    },
    lastServerResponse: {
        type: null,
        message: null,
        code: null,
    },
    updateSuccess: false,
    verifySuccess: false,
    stepsLeft: [],
    expiration_days: localStorage.getItem('password_expiration_days') ? localStorage.getItem('password_expiration_days') : 365,
    prizePoints: 'N/A',
    drawPoints: 'N/A',
    pfpRemaining: 'N/A',
    pfpExpiring: 'N/A',
    pointsExpiringDate: 'N/A',
    rafCode: '',
    dashboardErrors: '',
    loaded: false,
};
// REDUCERS FOR THE USER
// basic actions that affect this object only
var loginReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { loggedIn: payload.loggedIn, player: __assign(__assign({}, state.player), payload.player), errors: payload.errors, errorData: payload.data, message: payload.message, expiration_days: payload.password_epiration_days, loaded: true });
};
var logoutReducer = function () {
    return defaultUserState;
};
var updateReducer = function (state, action) {
    var _a, _b;
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, updateSuccess: payload.success, updateSection: __assign(__assign({}, state.updateSection), { status: payload.success ? 'success' : 'error', code: (_a = payload.errors) !== null && _a !== void 0 ? _a : null }), player: __assign(__assign({}, state.player), (_b = payload.payload) === null || _b === void 0 ? void 0 : _b.player) });
};
var deleteReducer = function (state, action) {
    var _a, _b, _c;
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: (_a = payload.code) !== null && _a !== void 0 ? _a : null, updateSuccess: payload.state === 'active', updateSection: __assign(__assign({}, state.updateSection), { status: payload.state === 'active' ? 'success' : 'error', code: (_b = payload.code) !== null && _b !== void 0 ? _b : null }), player: __assign(__assign({}, state.player), (_c = payload.payload) === null || _c === void 0 ? void 0 : _c.player) });
};
var updateSectionReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { updateSection: payload });
};
var registerReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, registerSuccess: payload.success, player: __assign(__assign({}, state.player), payload.player) });
};
var verifyReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, verifySuccess: payload.success, player: __assign(__assign({}, state.player), payload.player) });
};
var confirmReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, verifySuccess: payload.success, player: __assign(__assign({}, state.player), payload.player), lastServerResponse: {
            type: 'confirm',
            message: payload,
        } });
};
var confirmResendReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { lastServerResponse: {
            type: 'confirmResend',
            message: payload,
        }, updateSection: __assign(__assign({}, state.updateSection), { status: payload.player ? 'success' : 'error' }) });
};
var forgotPasswordReducer = function (state, action) {
    var payload = action.payload;
    if (payload.code === '_NOT_FOUND_MODEL') {
        payload.success = false;
    }
    if (payload.status === 'ok') {
        payload.success = true;
    }
    return __assign(__assign({}, state), { updateSection: __assign(__assign({}, state.updateSection), { status: payload.success ? 'success' : 'error' }), errors: payload.errors, success: payload.success });
};
var recoverPasswordReducer = function (state, action) {
    var payload = action.payload;
    var errors = '';
    if (payload.code) {
        payload.success = false;
        errors = payload.code;
    }
    if (payload.status === 'ok') {
        payload.success = true;
        errors = '';
    }
    return __assign(__assign({}, state), { updateSection: __assign(__assign(__assign({}, payload), state.updateSection), { status: payload.success ? 'success' : 'error' }), errors: errors, success: payload.success });
};
//*****************************************************************************************************************************
// Fetches Player data from the PAM.  This should only be done in circumstances where the player data has been removed from
// the user store (such as a page refresh) or in situation where we need to fetch data on a timer (such as on Pending Account
// Activation).     Otherwise, the userStore should hold onto the data fetched at login and updated based on the AuthToken.
// See notes for getPlayerDataFromAuthToken for more information about the differences between this and getting data from
// the app.
//
var getPlayerDataReducer = function (state, action) {
    var payload = action.payload;
    var spat = isJSON(payload.spat) ? JSON.parse(payload.spat) : null;
    var actions = spat && spat.player && spat.player.actions && Array.isArray(spat.player.actions) ? spat.player.actions : [];
    return __assign(__assign({}, state), { loggedIn: payload.loggedIn, expires: payload === null || payload === void 0 ? void 0 : payload.expires, player: __assign(__assign({}, state.player), payload.player), spat: spat, actions: actions, loaded: true });
};
//*********************************************************************************************************************************
// Grabs the AuthToken cookie and updates the player based on the SPAT.     This is the best way to keep the actions table up to
// date as any API that requires authentication will pass the SPAT back with the most up-to-date actions table.
//
// Note that some player attributes such as gender are NOT part of the SPAT, so getPlayerData may still need to be called to update
// that data - but that should be done at a minimum, such as on a refresh that clears the userStore.
//
var getPlayerDataFromAuthTokenReducer = function (state) {
    var _a, _b;
    var authToken = getCookieByName('AuthToken');
    var token = (_a = (isJSON(authToken) && JSON.parse(authToken))) !== null && _a !== void 0 ? _a : null;
    if (token) {
        var spat = (_b = (isJSON(token.spat) && JSON.parse(token.spat))) !== null && _b !== void 0 ? _b : null;
        if (spat) {
            var actions = spat && spat.player && spat.player.actions && Array.isArray(spat.player.actions) ? spat.player.actions : [];
            return __assign(__assign({}, state), { player: __assign(__assign({}, state.player), spat.player), spat: spat, actions: actions, loggedIn: true, loaded: true });
        }
        else {
            return __assign({}, state);
        }
    }
    else {
        return __assign({}, state);
    }
};
var setErrorsReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, errorData: payload.data, message: payload.message });
};
var clearErrorsReducer = function (state) {
    return __assign(__assign({}, state), { errors: '', message: '', success: false, updateSuccess: false, registerSuccess: false, verifySuccess: false });
};
var getRafCodeReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { rafCode: payload.code });
};
var sendRafInviteReducer = function (state, action) {
    var payload = action.payload;
    if (payload.success) {
        return __assign(__assign({}, state), { errors: '' });
    }
    else {
        return __assign(__assign({}, state), { errors: payload.error });
    }
};
var getDashboardReducer = function (state, action) {
    var _a;
    var payload = action.payload;
    var pfpPoints = 'N/A';
    var pfdPoints = 'N/A';
    var pointsRemaining = 'N/A';
    var pointsExpiring = 'N/A';
    var pointsExpiringDate = 'N/A';
    if (payload && payload.length > 0) {
        payload.forEach(function (type) {
            switch (type.shortname) {
                case 'pfp':
                    pfpPoints = type.value ? type.value : '0';
                    break;
                case 'pfd':
                    pfdPoints = type.value ? type.value : '0';
                    break;
                case 'pfpremaining':
                    pointsRemaining = type.value ? type.value : '0';
                    break;
                case 'pfpexpiring':
                    pointsExpiring = type.value ? type.value : '0';
                    break;
                case 'expiringdatetime':
                    pointsExpiringDate = type.value ? type.value : '0';
                    break;
            } // end switch
        }); // end forEach pointsDash
    }
    return __assign(__assign({}, state), { dashboardErrors: (_a = payload.message) !== null && _a !== void 0 ? _a : '', prizePoints: pfpPoints !== null && pfpPoints !== void 0 ? pfpPoints : 'N/A', drawPoints: pfdPoints !== null && pfdPoints !== void 0 ? pfdPoints : 'N/A', pfpRemaining: pointsRemaining !== null && pointsRemaining !== void 0 ? pointsRemaining : 'N/A', pfpExpiring: pointsExpiring !== null && pointsExpiring !== void 0 ? pointsExpiring : 'N/A', pointsExpiringDate: pointsExpiringDate !== null && pointsExpiringDate !== void 0 ? pointsExpiringDate : 'N/A' });
};
export var userSlice = createSlice({
    name: 'user',
    initialState: defaultUserState,
    reducers: {
        login: loginReducer,
        logout: logoutReducer,
        update: updateReducer,
        updateSection: updateSectionReducer,
        register: registerReducer,
        verify: verifyReducer,
        confirm: confirmReducer,
        confirmResend: confirmResendReducer,
        getPlayerData: getPlayerDataReducer,
        getPlayerDataFromAuthToken: getPlayerDataFromAuthTokenReducer,
        clearErrors: clearErrorsReducer,
        forgotPassword: forgotPasswordReducer,
        recoverPassword: recoverPasswordReducer,
        setErrors: setErrorsReducer,
        getDashboard: getDashboardReducer,
        getRafCode: getRafCodeReducer,
        sendRafInvite: sendRafInviteReducer,
        updateEmail: updateReducer,
        updatePassword: updateReducer,
        deletePlayerAccount: deleteReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var login = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!userSlice.loggedIn) return [3 /*break*/, 6];
                dispatch(loadingSlice.actions.setLoading({ action: 'login' }));
                return [4 /*yield*/, apiClient().login(payload)];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, dispatch(userSlice.actions.login(result))];
            case 2:
                _a.sent();
                return [4 /*yield*/, dispatch(entrySlice.actions.reset())];
            case 3:
                _a.sent();
                return [4 /*yield*/, dispatch(drawTicketSlice.actions.reset())];
            case 4:
                _a.sent();
                return [4 /*yield*/, dispatch(scratchTicketSlice.actions.reset())];
            case 5:
                _a.sent();
                dispatch(loadingSlice.actions.unsetLoading({ action: 'login' }));
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); };
var logout = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'logout' }));
                return [4 /*yield*/, apiClient().logout(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.logout(result));
                dispatch(entrySlice.actions.reset());
                dispatch(drawTicketSlice.actions.reset());
                dispatch(scratchTicketSlice.actions.reset());
                dispatch(loadingSlice.actions.unsetLoading({ action: 'logout' }));
                return [2 /*return*/];
        }
    });
}); };
var update = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'update' }));
                return [4 /*yield*/, apiClient().update(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.update(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'update' }));
                return [2 /*return*/];
        }
    });
}); };
var updateSection = function (dispatch, payload) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(userSlice.actions.updateSection(payload));
        return [2 /*return*/];
    });
}); };
var register = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'register' }));
                return [4 /*yield*/, apiClient().register(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.register(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'register' }));
                return [2 /*return*/];
        }
    });
}); };
var verify = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'verify' }));
                return [4 /*yield*/, apiClient().verify(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.verify(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'verify' }));
                return [2 /*return*/];
        }
    });
}); };
var confirm = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'confirm' }));
                return [4 /*yield*/, apiClient().confirm(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.confirm(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'confirm' }));
                return [2 /*return*/];
        }
    });
}); };
var confirmResend = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'confirmResend' }));
                return [4 /*yield*/, apiClient().confirmResend(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.confirmResend(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'confirmResend' }));
                return [2 /*return*/];
        }
    });
}); };
var forgotPassword = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'forgotPassword' }));
                return [4 /*yield*/, apiClient().forgotPassword(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.forgotPassword(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'forgotPassword' }));
                return [2 /*return*/];
        }
    });
}); };
var recoverPassword = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'recoverPassword' }));
                return [4 /*yield*/, apiClient().recoverPassword(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.recoverPassword(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'recoverPassword' }));
                return [2 /*return*/];
        }
    });
}); };
var getPlayerData = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerData' }));
                return [4 /*yield*/, apiClient().getPlayerData()];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.getPlayerData(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerData' }));
                return [2 /*return*/];
        }
    });
}); };
var getDashboard = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDashboard' }));
                return [4 /*yield*/, apiClient().getDashboard()];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.getDashboard(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDashboard' }));
                return [2 /*return*/];
        }
    });
}); };
var getRafCode = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getRafCode' }));
                return [4 /*yield*/, apiClient().getRafCode()];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.getRafCode(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getRafCode' }));
                return [2 /*return*/];
        }
    });
}); };
var sendRafInvite = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'sendRafInvite' }));
                return [4 /*yield*/, apiClient().sendRafInvite(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.sendRafInvite(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'sendRafInvite' }));
                return [2 /*return*/];
        }
    });
}); };
var actionComplete = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'actionComplete' }));
                return [4 /*yield*/, apiClient().actionComplete(payload)];
            case 1:
                _a.sent();
                return [4 /*yield*/, apiClient().getPlayerData()];
            case 2:
                result = _a.sent();
                dispatch(userSlice.actions.getPlayerData(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'actionComplete' }));
                return [2 /*return*/];
        }
    });
}); };
var updateEmail = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'updateEmail' }));
                return [4 /*yield*/, apiClient().updateEmail(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.updateEmail(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'updateEmail' }));
                return [2 /*return*/];
        }
    });
}); };
var updatePassword = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'updatePassword' }));
                return [4 /*yield*/, apiClient().updatePassword(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.updatePassword(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'updatePassword' }));
                return [2 /*return*/];
        }
    });
}); };
var deletePlayerAccount = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'deletePlayerAccount' }));
                return [4 /*yield*/, apiClient().deletePlayerAccount(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.deletePlayerAccount(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'deletePlayerAccount' }));
                return [2 /*return*/];
        }
    });
}); };
// actions that get exported to the views
export var userActions = function (dispatch, apiClient) {
    return {
        login: function (payload) { return login(dispatch, payload, apiClient); },
        logout: function (payload) { return logout(dispatch, payload, apiClient); },
        update: function (payload) { return update(dispatch, payload, apiClient); },
        updateSection: function (payload) { return updateSection(dispatch, payload, apiClient); },
        register: function (payload) { return register(dispatch, payload, apiClient); },
        verify: function (payload) { return verify(dispatch, payload, apiClient); },
        confirm: function (payload) { return confirm(dispatch, payload, apiClient); },
        confirmResend: function (payload) { return confirmResend(dispatch, payload, apiClient); },
        forgotPassword: function (payload) { return forgotPassword(dispatch, payload, apiClient); },
        recoverPassword: function (payload) { return recoverPassword(dispatch, payload, apiClient); },
        getPlayerData: function (payload) { return getPlayerData(dispatch, payload, apiClient); },
        getPlayerDataFromAuthToken: function () { return dispatch(userSlice.actions.getPlayerDataFromAuthToken()); },
        clearErrors: function () { return dispatch(userSlice.actions.clearErrors()); },
        setErrors: function (payload) { return dispatch(userSlice.actions.setErrors(payload)); },
        getDashboard: function (payload) { return getDashboard(dispatch, payload, apiClient); },
        getRafCode: function (payload) { return getRafCode(dispatch, payload, apiClient); },
        sendRafInvite: function (payload) { return sendRafInvite(dispatch, payload, apiClient); },
        actionComplete: function (payload) { return actionComplete(dispatch, payload, apiClient); },
        updateEmail: function (payload) { return updateEmail(dispatch, payload, apiClient); },
        updatePassword: function (payload) { return updatePassword(dispatch, payload, apiClient); },
        deletePlayerAccount: function (payload) { return deletePlayerAccount(dispatch, payload, apiClient); },
    };
};
