import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BoxedContent from '../BoxedContent/BoxedContent';
import DisplayContent from '../DisplayContent/DisplayContent';
import replaceTemplateStrings from '../../utils/replaceTemplateStrings';
var ActionNotification = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var action = _a.action, _k = _a.attributes, attributes = _k === void 0 ? {} : _k, _l = _a.userActions, userActions = _l === void 0 ? [] : _l, _m = _a.options, options = _m === void 0 ? [] : _m;
    var playerActions = (_b = options === null || options === void 0 ? void 0 : options.playerActions) !== null && _b !== void 0 ? _b : [];
    var playerActionLogoutMessage = (_c = options === null || options === void 0 ? void 0 : options.playerActionLogoutMessage) !== null && _c !== void 0 ? _c : '';
    var playerActionsLeftMessage = (_d = options === null || options === void 0 ? void 0 : options.playerActionsLeftMessage) !== null && _d !== void 0 ? _d : '';
    // Find the content based on action type
    var content = playerActions.find(function (item) { return item.actionType === action; });
    return (_jsx(Modal, { show: content ? true : false, size: 'lg', backdrop: 'static', dialogClassName: 'action-notification', autoFocus: true, keyboard: false, centered: true, children: _jsxs(BoxedContent, { note: 'Action notification box', isActive: true, hasCollapser: false, title: (_e = content === null || content === void 0 ? void 0 : content.actionTitle) !== null && _e !== void 0 ? _e : '', children: [_jsx(DisplayContent, { isVisible: content === null || content === void 0 ? void 0 : content.actionImageUrl, children: _jsx("div", { className: 'action-notification-image', children: _jsx("img", { src: content === null || content === void 0 ? void 0 : content.actionImageUrl, alt: '', "aria-hidden": 'true' }) }) }), _jsx("div", { className: 'action-notification-description', children: _jsx("div", { dangerouslySetInnerHTML: { __html: replaceTemplateStrings((_f = content === null || content === void 0 ? void 0 : content.actionDescription) !== null && _f !== void 0 ? _f : '', attributes) } }) }), _jsxs("div", { className: 'action-notification-buttons', children: [_jsx("a", { className: 'btn btn-primary mx-2', href: replaceTemplateStrings((_g = content === null || content === void 0 ? void 0 : content.actionCtaUrl) !== null && _g !== void 0 ? _g : '', attributes), children: (_h = content === null || content === void 0 ? void 0 : content.actionCtaText) !== null && _h !== void 0 ? _h : '' }), _jsxs(DisplayContent, { isVisible: userActions.length >= 2, children: [_jsx("hr", {}), _jsx("div", { className: 'action-notification-alert', children: _jsxs("small", { children: [_jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: replaceTemplateStrings(playerActionsLeftMessage !== null && playerActionsLeftMessage !== void 0 ? playerActionsLeftMessage : '', { '%NUMBER_OF_ACTIONS%': (_j = userActions.length) !== null && _j !== void 0 ? _j : 1 }),
                                                } }), _jsx("ul", { className: 'action-notification-alert-list', children: userActions.map(function (actionItem, index) {
                                                    var _a, _b;
                                                    var actionType = typeof actionItem === 'string' ? actionItem : Object.keys(actionItem)[0];
                                                    var actionTitle = (_b = (_a = playerActions.find(function (item) { return item.actionType === actionType; })) === null || _a === void 0 ? void 0 : _a.actionTitle) !== null && _b !== void 0 ? _b : actionType;
                                                    return _jsx("li", { children: actionTitle }, index);
                                                }) })] }) })] }), _jsx(DisplayContent, { isVisible: content === null || content === void 0 ? void 0 : content.actionHasLogout, children: _jsx("div", { dangerouslySetInnerHTML: { __html: playerActionLogoutMessage } }) })] })] }) }));
};
export default ActionNotification;
