var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import CMSContent from '../CMSContent/CMSContent';
var StyleOverrides = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    var _b = useState({}), styleProps = _b[0], setStyleProps = _b[1];
    useEffect(function () {
        setStyleProps({
            localStorageObject: 'webContent',
            contentPath: 'data.styleOverrides.cssBlock',
        });
    }, [cmsSourceFirebase.ready]);
    return _jsx(CMSContent, __assign({}, styleProps, { cmsSourceFirebase: cmsSourceFirebase }));
};
export default StyleOverrides;
