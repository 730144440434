import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, PasswordExpiring } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const telescriptPasswordExpiring = getCMSObject('data.components.teleScripts.PasswordExpiring.jsonBlock');

const PasswordExpiringPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <PasswordExpiring
                telescript={telescriptPasswordExpiring}
                expirationDays={user.expiration_days ?? 0}
                updateNowLink={'/my-profile'}
                remindMeLaterLink={'/home'}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiringPage);
