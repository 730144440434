import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
var FeedbackError = function (_a) {
    var isError = _a.isError, error = _a.error;
    if (isError) {
        var errors = Object.keys(error).map(function (er) {
            return (_jsxs(_Fragment, { children: [er, ": ", error[er], _jsx("br", {})] }));
        });
        return (_jsx("div", { className: 'alert alert-danger text-center', role: 'alert', children: errors.map(function (err, index) {
                return _jsx("div", { children: err }, index);
            }) }));
    }
    return null;
};
export default FeedbackError;
