import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import isBrowser from '../../utils/isBrowser';
import getCookieByName from '../../utils/getCookieByName';
import isApp from '../../utils/isApp';
var LoggedInOnlyPages = function (_a) {
    var _b, _c, _d;
    var children = _a.children, loading = _a.loading, user = _a.user, config = _a.config;
    var _e = useState(false), hasMounted = _e[0], setHasMounted = _e[1];
    useEffect(function () {
        setHasMounted(true);
    }, [loading, hasMounted, user]);
    var myLocation = isBrowser() ? window.location.protocol + '//' + window.location.host + '/login-pta' : '';
    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');
    var defaultLoginUrl = (_c = (_b = config === null || config === void 0 ? void 0 : config.site) === null || _b === void 0 ? void 0 : _b.defaultLoginLink) !== null && _c !== void 0 ? _c : '/login';
    if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig'] || loading.actions['login']) {
        return _jsx(LoadingIndicator, { priority: 'normal' });
    }
    else {
        if (user.loggedIn) {
            return _jsx(_Fragment, { children: children });
        }
        else {
            if (!getCookieByName('AuthToken')) {
                if (isApp()) {
                    var myLocation_1 = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';
                    // remove any trailing slashes
                    myLocation_1 = myLocation_1.replace(/\/$/, '');
                    // For debug: console.log('🚀 triggerAppLogin from: ' + myLocation);
                    window.parent.postMessage({
                        message: 'openLogin',
                        redirect: myLocation_1,
                    }, '*');
                }
                else {
                    var loginUrl = ((_d = config.config) === null || _d === void 0 ? void 0 : _d.login_host_uri) ? "".concat(config.config.login_host_uri, "?destURL=").concat(myLocation) : defaultLoginUrl;
                    window.location.href = loginUrl;
                }
            }
            return _jsx(LoadingIndicator, { priority: 'normal' });
        }
    }
};
export default LoggedInOnlyPages;
