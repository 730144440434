import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import isApp from '../../utils/isApp';
export var IfNotMobile = function (_a) {
    var children = _a.children;
    if (!isApp()) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return null;
    }
};
export default IfNotMobile;
