import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
var TableDesktop = function (_a) {
    var _b, _c;
    var headers = _a.headers, rows = _a.rows, _d = _a.options, options = _d === void 0 ? {
        tableId: '',
        tableClass: '',
        isLoaded: true,
        messageNoRows: '',
        messageLoading: '',
    } : _d;
    return (_jsx(_Fragment, { children: !rows || rows.length === 0 ? (_jsx("div", { className: 'd-none d-lg-block text-center', children: _jsx("em", { children: options.isLoaded ? options.messageNoRows : (_b = options.messageLoading) !== null && _b !== void 0 ? _b : '. . .' }) })) : (_jsxs("table", { id: options.tableId, className: (_c = options.tableClass) !== null && _c !== void 0 ? _c : 'table theme-table entry-table d-none d-lg-table text-center', children: [_jsx("thead", { children: _jsx("tr", { children: Object.keys(headers).map(function (header, id) {
                            var _a;
                            return _jsx("th", { children: (_a = get(headers, header)) !== null && _a !== void 0 ? _a : '' }, id);
                        }) }) }), _jsx("tbody", { children: rows.map(function (row, rowIndex) {
                        return (_jsx("tr", { children: Object.keys(headers).map(function (header, id) {
                                var _a;
                                return _jsx("td", { children: (_a = get(row.fields, header)) !== null && _a !== void 0 ? _a : '' }, id);
                            }) }, rowIndex));
                    }) })] })) }));
};
export default TableDesktop;
