import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import stripsTagsAndSlashes from '../../utils/stripsTagsAndSlashes';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import IfNotMobile from '../IfNotMobile/IfNotMobile';
// TODO: Remove `path` and `routes` props once fully transitioned over to using `pageData`
var TitleBreadcrumb = function (_a) {
    var path = _a.path, routes = _a.routes, pageData = _a.pageData;
    var data = routes && routes[stripsTagsAndSlashes(path !== null && path !== void 0 ? path : '')] ? routes[stripsTagsAndSlashes(path !== null && path !== void 0 ? path : '')] : null;
    var breadcrumb = (pageData === null || pageData === void 0 ? void 0 : pageData.breadcrumb) || (data === null || data === void 0 ? void 0 : data.breadcrumb);
    var pageHeader = (pageData === null || pageData === void 0 ? void 0 : pageData.pageHeader) || (data === null || data === void 0 ? void 0 : data.pageHeader);
    if (!breadcrumb) {
        return null;
    }
    return (_jsx("div", { className: 'row justify-content-center', children: _jsxs("div", { className: 'col', children: [_jsx(IfNotMobile, { children: _jsx(Breadcrumbs, { data: breadcrumb !== null && breadcrumb !== void 0 ? breadcrumb : [] }) }), pageHeader && _jsx("h1", { className: 'section-title', children: pageHeader })] }) }));
};
export default TitleBreadcrumb;
