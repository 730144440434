import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { getCMSObject, SurveyForm } from 'sg-ui-components';
import { Redirect } from 'react-router-dom';

const SurveyPage = ({ user, config, cmsSourceFirebase, actions, match, survey }) => {
    const surveyFormTelescript = getCMSObject('data.components.teleScripts.survey.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <SurveyForm Redirect={Redirect} telescript={surveyFormTelescript} actions={actions} survey={survey} cmsSourceFirebase={cmsSourceFirebase} />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
