/**********************************************************************
 * Component:  replaceUrlSigils
 * Purpose:    Replaces sigils/variables from the CMS and replaces them
 *             with proper URL values.
 *
 * Props:       config - config data store
 *              urlString - url String containing the Sigils.
 *
 *  Notes:      todo -  move to sg-ui-components
 */
export const replaceUrlSigils = (config, urlString) => {
    const sigilDictionary = [
        {
            find: '{{MO_LOTTERY_URI}}',
            replace: config?.config?.lottery_host ?? '',
        },
        {
            find: '{{MO_STORE_URI}}',
            replace: config?.config?.store_uri ?? '',
        },
    ];

    sigilDictionary.forEach((dictEntry) => {
        if (urlString) {
            urlString = urlString.replaceAll(dictEntry.find, dictEntry.replace);
        }
    });

    return urlString;
};
