import noHash from './noHash';
import isHash from './isHash';
var calculateContentBoxShow = function (options) {
    var _a, _b, _c, _d;
    var hash = (_a = options === null || options === void 0 ? void 0 : options.hash) !== null && _a !== void 0 ? _a : null;
    var hasCollapser = (_b = options === null || options === void 0 ? void 0 : options.hasCollapser) !== null && _b !== void 0 ? _b : false;
    var showByDefault = (_c = options === null || options === void 0 ? void 0 : options.showByDefault) !== null && _c !== void 0 ? _c : false;
    var hasEntries = (_d = options === null || options === void 0 ? void 0 : options.hasEntries) !== null && _d !== void 0 ? _d : false;
    //? if entries present need to show
    if (hasEntries)
        return true;
    //? when there is no collapser, we need to show the content
    if (!hasCollapser)
        return true;
    //? when there is no hash, we use the default value
    if (noHash()) {
        return showByDefault === true;
    }
    //? only show when the hash is matching
    return isHash(hash);
};
export default calculateContentBoxShow;
