import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import * as yup from 'yup';
import moment from 'moment';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';

const StepTwoTemplate = ({ user, step, setStep, verifyFields, setVerifyFields, cmsSourceFirebase }) => {
    let formattedBirthday = user?.player?.birthdate ? user?.player?.birthdate.toString().split('-') : '';
    formattedBirthday = formattedBirthday[1] + '-' + formattedBirthday[2] + '-' + formattedBirthday[0];

    const initialStepFields = {
        firstname: user.player.firstname,
        lastname: user.player.lastname,
        birthdate: formattedBirthday,
        gender: user.player.extra ? user.player.extra.gender : '',
    };

    const schema = yup.object().shape({
        firstname: yup.string().required('First Name is required'),
        lastname: yup.string().required('Last Name required'),
        birthdate: yup
            .string()
            .test('birthdate', 'Must be 18 or older to register', (value) => {
                //? we need transform the input format (MM-DD-YYYY) to a moment().diff() supported format (YYYY-MM-DD)
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') >= 18;
            })
            .test('birthdate', 'Invalid Date of Birth', (value) => {
                //? we need transform the input format (MM-DD-YYYY) to a moment().diff() supported format (YYYY-MM-DD)
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') <= 150;
            })
            .test('birthdate', 'Invalid Date of Birth', (value) => {
                //? we need to do a strict date validation, otherwise moment accept invalid dates like (12-31-1)
                return moment(value, 'MM-DD-YYYY', true).isValid();
            })
            .required('Date of Birth is required'),
        gender: yup.string().required('Gender is required'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const secondStepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            let birthday = stepFields.birthdate.split('-');
            setError('');
            setLocalErrors({});
            setVerifyFields({
                ...verifyFields,
                firstname: stepFields.firstname,
                lastname: stepFields.lastname,
                birthdate: `${birthday[2]}-${birthday[0]}-${birthday[1]}`,
                gender: stepFields.gender,
            });
            setStep({ step: 3 });
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};
        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);
        return validationErrors;
    };

    useEffect(() => {
        setStepFields(initialStepFields);
    }, [user]);

    if (step.step == 2) {
        return (
            <div className='row justify-content-center'>
                <div className='col-12 col-md-10 col-lg-8'>
                    <ContentBox variant='theme-blue mx-2'>
                        <ContentBoxHead>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.registrationItems.registrationStepTwo.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div className='form-step'>
                                <div className='inner-step'>
                                    <div className='col'>
                                        <CMSContent
                                            localStorageObject='webContent'
                                            contentPath='data.registrationItems.registrationStepTwo.contentHTML'
                                            cmsSourceFirebase={cmsSourceFirebase}
                                        />
                                    </div>
                                    {error ? (
                                        <Alert variant='danger'>
                                            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                            <ul className='alert-text'>
                                                {Object.values(localErrors).map((err, index) => {
                                                    return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                                })}
                                            </ul>
                                        </Alert>
                                    ) : null}
                                    <div className='form-group'>
                                        <label htmlFor='firstname'>First Name</label>
                                        <input
                                            type='email'
                                            className='form-control theme-input'
                                            id='firstname'
                                            value={stepFields.firstname}
                                            name='firstname'
                                            onChange={handleChange}
                                            aria-describedby='emailHelp'
                                            placeholder=''
                                        />
                                        <small id='emailHelp' className='form-text text-muted'>
                                            Please use your legal name.
                                        </small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='lastname'>Last Name</label>
                                        <input
                                            type='text'
                                            className='form-control theme-input'
                                            id='lastname'
                                            value={stepFields.lastname}
                                            name='lastname'
                                            onChange={handleChange}
                                            placeholder=''
                                        />
                                        <small id='emailHelp' className='form-text text-muted'>
                                            Please use your legal name.
                                        </small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='birthdate'>DOB</label>
                                        <Cleave
                                            className='form-control theme-input col-6 col-sm-5'
                                            placeholder='MM-DD-YYYY'
                                            id='birthdate'
                                            value={stepFields.birthdate}
                                            name='birthdate'
                                            options={{
                                                date: true,
                                                delimiter: '-',
                                                datePattern: ['m', 'd', 'Y'],
                                            }}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='gender'>Gender</label>
                                        <select
                                            name='gender'
                                            value={stepFields.gender}
                                            onChange={handleChange}
                                            className='required form-control theme-select col-md-4 gender'>
                                            <option value=''>Select One</option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                            <option value='O'>Other</option>
                                            <option value='H'>Prefer Not to Say</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-section text-center mb-3'>
                                <button type='button' className='btn theme-btn theme-secondary' onClick={secondStepSubmit}>
                                    Continue
                                </button>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepTwo = connect(mapStateToProps, mapDispatchToProps)(StepTwoTemplate);

export { StepTwo };