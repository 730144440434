var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { cmsContent } from 'sg-cms';
export var defaultStorageState = {
    ready: true,
    loaded: false,
};
// REDUCERS
// basic actions that affect this object only
var setReadyReducer = function (state, action) {
    return __assign(__assign({}, state), { ready: action.payload });
};
var setLoadedReducer = function (state, action) {
    return __assign(__assign({}, state), { loaded: action.payload });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultStorageState);
};
export var cmsSourceFirebaseSlice = createSlice({
    name: 'cms_source_firebase',
    initialState: defaultStorageState,
    reducers: {
        setReady: setReadyReducer,
        reset: resetReducer,
        setLoaded: setLoadedReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var storeAllData = function (dispatch, payload) { return __awaiter(void 0, void 0, void 0, function () {
    var collectionName, firebaseConfig, searchParams, previewMode;
    var _a, _b, _c, _d, _e, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                dispatch(cmsSourceFirebaseSlice.actions.setReady(false));
                collectionName = 'configManager';
                firebaseConfig = {
                    apiKey: (_a = payload.config) === null || _a === void 0 ? void 0 : _a.api_key,
                    authDomain: (_b = payload.config) === null || _b === void 0 ? void 0 : _b.auth_domain,
                    projectId: (_c = payload.config) === null || _c === void 0 ? void 0 : _c.project_id,
                    storageBucket: (_d = payload.config) === null || _d === void 0 ? void 0 : _d.storage_bucket,
                    messagingSenderId: (_e = payload.config) === null || _e === void 0 ? void 0 : _e.messaging_sender_id,
                    appId: (_f = payload.config) === null || _f === void 0 ? void 0 : _f.app_id,
                    measurementId: (_g = payload.config) === null || _g === void 0 ? void 0 : _g.measurement_id,
                };
                searchParams = new URLSearchParams(window.location.search);
                previewMode = searchParams.has('preview_mode') ? 'true' : 'false';
                return [4 /*yield*/, cmsContent({
                        config: firebaseConfig,
                        collection: collectionName,
                        docName: payload.docName,
                        previewMode: previewMode,
                    })];
            case 1:
                _h.sent();
                dispatch(cmsSourceFirebaseSlice.actions.setReady(true));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var cmsSourceFirebaseActions = function (dispatch) {
    return {
        storeAllData: function (payload) { return storeAllData(dispatch, payload); },
        setLoaded: function (payload) { return dispatch(cmsSourceFirebaseSlice.actions.setLoaded(payload)); },
        reset: function (payload) { return dispatch(cmsSourceFirebaseSlice.actions.reset(payload)); },
    };
};
