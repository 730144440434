var stringToRegex = function (pattern) {
    return new RegExp(pattern, 'g');
};
var replaceBadTicketInput = function (inputField, badTicketInputReplacement) {
    badTicketInputReplacement.forEach(function (_a) {
        var mapping = _a.mapping, replace = _a.replace;
        if (inputField && mapping && replace) {
            var regex = stringToRegex(mapping);
            inputField = inputField.replace(regex, replace);
        }
    });
    return inputField;
};
export default replaceBadTicketInput;
