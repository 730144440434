var getCookieByName = function (cookieName) {
    if (typeof document === 'undefined') {
        return;
    }
    var cookie = document.cookie
        .split('; ')
        .filter(function (row) { return row.startsWith(cookieName); })
        .map(function (c) { return c.split('=')[1]; })[0];
    return cookie;
};
export default getCookieByName;
