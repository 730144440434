import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, TableMobile, formatDate, TableDesktop, CMSContent, isHash } from 'sg-ui-components';
import AllocatableEntriesForm from '../AllocatableEntries/AllocatableEntriesForm';
import Modal from 'react-bootstrap/Modal';
import './my-allocatable-entries.scss';

/**********************************************************************
 * Component: MyAllocatableEntries
 * Purpose:   Shows all the player's outstanding entries they can allocate
 *            for all sweepstakes.    Part of the My Activity page
 *
 * Props: - actions       -  actions table for api calls
 *          sweep         -  reference to the parent Sweepstake
 *          loading       -  reference to the loading store
 *
 *
 * APIs used:  /sweepstake/entry/allocatable - get players allocatable entries
 *             /sweepstake/current -  gets list of current sweepstakes
 */
const MyAllocatableEntries = ({ cmsSourceFirebase, actions, sweep, loading }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const hash = 'allocatable-entries';

    //***********************************************************************************
    // On load get the both the list of current sweeps and all player's allocatable
    // entries.
    //
    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                await actions.sweepActions.getPlayerAllocatableEntries();
                await actions.sweepActions.getSweeps();
                setPageLoaded(true);
            }
        }

        fetchData();
        return () => {
            setPageLoaded(false);
        };
    }, []);

    const DetailsMobile = ({ entry }) => {
        const [show, setShow] = useState(false);

        const showModal = () => {
            setShow(true);
        };

        return (
            <div key={entry.id}>
                <Button variant='link' className='theme-btn-link p-0' onClick={() => showModal((entry = { entry }))}>
                    Details
                </Button>
                <AllocatableModal show={show} setShow={setShow} entry={entry} />
            </div>
        );
    };

    const AllocatableModal = ({ show, setShow, entry }) => {
        const handleModalClose = () => {
            setShow(false);
        };

        return (
            <Modal
                key={entry.id}
                show={show}
                onHide={handleModalClose}
                backdrop='static'
                keyboard={false}
                size='lg'
                dialogClassName='allocatable-form-modal'
                contentClassName='allocatable-form-modal'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.allocatableEntriesPopup.allocatableEntriesTitle.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AllocatableEntriesForm
                        sweepId={entry.sweeps_id ?? ''}
                        promotionName={entry.sweepstake_name ?? ''}
                        numEntries={entry.quantity ?? ''}
                        entryId={entry.id ?? ''}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const displayHeaders = {
        drawing: 'SECOND-CHANCE PROMOTION',
        allocateBy: 'ASSIGN BY*',
        entriesToAllocate: 'ENTRIES TO ALLOCATE',
        details: 'DETAILS',
    };
    const displayRows = sweep.playerAllocatableEntries.map((entry) => ({
        ...entry,
        fields: {
            drawing: entry.sweepstake_name ?? 'N/A',
            allocateBy: formatDate?.dateTimeWithMeridiem(entry?.allocate_by),
            entriesToAllocate: entry.quantity ?? 'N/A',
            details: <DetailsMobile key={entry.id} entry={entry} />,
        },
    }));

    const displayOptions = {
        tableId: 'allocatable-entries-table',
        isLoaded: pageLoaded,
        messageNoRows: 'You currently have no second-chances entries to assign',
    };

    if (loading.actions['allocateEntries']) {
        return null;
    } else {
        return (
            <ContentBox variant='theme-blue' id={hash} show={isHash(hash) ? 'true' : 'false'}>
                <ContentBoxHead>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.allocatableEntriesPopup.allocatableEntriesTitle.contentHTML'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                    <ContentCollapser />
                </ContentBoxHead>
                <ContentBoxBody>
                    <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                    <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
                    <div className='text-center'>
                        <small>* All unassigned entries are entered into the default prize for that drawing.</small>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAllocatableEntries);
