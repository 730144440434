import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
var HideFromView = function (_a) {
    var paths = _a.paths, children = _a.children;
    var location = useLocation();
    // // Check if paths is not an array and validate it as one
    if (!(paths instanceof Array)) {
        paths = [paths];
    }
    var hide = paths.some(function (path) {
        return location.pathname === path;
    });
    if (hide) {
        return null;
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
export default HideFromView;
