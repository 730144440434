import React from 'react';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { ContentBox, ContentBoxBody, ContentBoxHead } from 'sg-ui-components';

// Set up the RAF Page, which is only accessible to logged in users.
const RafPageTemplate = ({ children }) => {
    return (
        <div className='raf-container theme-form'>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <h1>REFER A FRIEND</h1>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-10'>
                    <ContentBox variant='theme-blue'>
                        <ContentBoxHead>
                            Share <em>My Lottery</em>
                        </ContentBoxHead>
                        <ContentBoxBody>{children}</ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        </div>
    );
};

const RafPage = connect(mapStateToProps, mapDispatchToProps)(RafPageTemplate);

export { RafPage };
