import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import PropTypes from 'prop-types';
import { Nav, NavLink, Dropdown } from 'react-bootstrap';
import { replaceUrlSigils } from '../utils/replaceUrlSigils';

const Menu = (props) => {
    const { pathname } = useLocation();

    const setLinkProps = (menuItemNode) => {
        let propsObj = {};
        if (menuItemNode.route) {
            propsObj = { ...propsObj, as: Link, to: menuItemNode.route };
        } else if (menuItemNode.webLinkUrl) {
            propsObj = {
                ...propsObj,
                as: 'a',
                href: replaceUrlSigils(props.config, menuItemNode.webLinkUrl),
                target: menuItemNode.webLinkTarget,
            };
        }

        return propsObj;
    };

    if (props.menu_json && props.menu_json.length) {
        return (
            <Nav as='ul' className={props.className}>
                {props.menu_json.map((node, key) => {
                    if (!node.subMenuList) {
                        return (
                            <Nav.Item as='li' key={`menu-${key}`}>
                                {node.webLinkUrl || node.route ? (
                                    <Nav.Link {...setLinkProps(node)} role='button' className={pathname === node.route ? 'active' : ''}>
                                        <span dangerouslySetInnerHTML={{ __html: node.label }} />
                                    </Nav.Link>
                                ) : (
                                    <span className='nav-link' dangerouslySetInnerHTML={{ __html: node.label }} />
                                )}
                            </Nav.Item>
                        );
                    } else if (node.subMenuList.length) {
                        const routes = node.subMenuList.map((subNode) => subNode.route);
                        const dropdownTitle = <span dangerouslySetInnerHTML={{ __html: node.label }} />;

                        return (
                            <Dropdown as='li' className={[routes.includes(pathname) ? 'active' : '', 'has-dropdown nav-item']} key={`menu-${key}`}>
                                <Nav.Link className='d-none d-xl-block dropdown-toggle nav-link' {...setLinkProps(node)}>
                                    {dropdownTitle}
                                </Nav.Link>
                                <Dropdown.Toggle as={NavLink} className='d-xl-none'>
                                    {dropdownTitle}
                                </Dropdown.Toggle>

                                <Dropdown.Menu renderOnMount={true}>
                                    {node.webLinkUrl || node.route ? (
                                        <Dropdown.Item
                                            {...setLinkProps(node)}
                                            className={`d-xl-none ${pathname === node.route ? 'active' : ''}`}
                                            role='button'
                                            key={`sub-menu-${key}`}>
                                            <span dangerouslySetInnerHTML={{ __html: node.label }} />
                                        </Dropdown.Item>
                                    ) : (
                                        <span className='nav-link' dangerouslySetInnerHTML={{ __html: node.label }} />
                                    )}

                                    {node.subMenuList.map((subNode, key) => {
                                        return (
                                            <Dropdown.Item
                                                {...setLinkProps(subNode)}
                                                className={pathname === subNode.route ? 'active' : ''}
                                                role='button'
                                                key={`sub-menu-${key}`}>
                                                <span dangerouslySetInnerHTML={{ __html: subNode.label }} />
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else {
                        return (
                            <li className='nav-item' key={`menu-${key}`}>
                                <span dangerouslySetInnerHTML={{ __html: node.label }} />
                            </li>
                        );
                    }
                })}
            </Nav>
        );
    } else {
        return null;
    }
};

Menu.propTypes = {
    menu_json: PropTypes.array,
    className: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
