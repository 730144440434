var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
export var defaultWinnerState = {
    drawings: [],
    expiredDrawings: [],
    expiredDrawingsByEntryType: {
        promotion: [], // Second-Chance Bonus Zone drawings
        purchase: [], // PFD drawings
    },
    currentDrawings: [],
    nextDrawings: [],
};
// REDUCERS
// basic actions that affect this object only
var getDrawingsReducer = function (state, action) {
    return __assign(__assign({}, state), { drawings: action.payload });
};
var getExpiredDrawingsReducer = function (state, action) {
    var _a;
    var result = action.payload;
    var newDrawings = result.drawings;
    if (newDrawings) {
        var current = (_a = state.expiredDrawings.drawings) !== null && _a !== void 0 ? _a : [];
        current = __spreadArray(__spreadArray([], current, true), newDrawings, true);
        current = current.filter(function (item) { return item.id; });
        result.drawings = current;
        return __assign(__assign({}, state), { expiredDrawings: result });
    }
    else {
        return state;
    }
};
var getExpiredDrawingsByEntryTypeReducer = function (state, action) {
    var _a;
    var _b, _c, _d;
    var result = action.payload;
    var newDrawings = result.drawings, entryType = result.entryType;
    if (newDrawings) {
        var current = (_d = (_c = (_b = state.expiredDrawingsByEntryType) === null || _b === void 0 ? void 0 : _b[entryType]) === null || _c === void 0 ? void 0 : _c.drawings) !== null && _d !== void 0 ? _d : [];
        current = __spreadArray(__spreadArray([], current, true), newDrawings, true);
        current = current.filter(function (item) { return item.id; });
        result.drawings = current;
        return __assign(__assign({}, state), { expiredDrawingsByEntryType: __assign(__assign({}, state.expiredDrawingsByEntryType), (_a = {}, _a[entryType] = result, _a)) });
    }
    else {
        return state;
    }
};
var getCurrentDrawingsReducer = function (state, action) {
    var currentDrawings = action.payload;
    return __assign(__assign({}, state), { currentDrawings: currentDrawings });
};
var getNextDrawingsReducer = function (state, action) {
    return __assign(__assign({}, state), { nextDrawings: action.payload });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultWinnerState);
};
export var winnerSlice = createSlice({
    name: 'winner',
    initialState: defaultWinnerState,
    reducers: {
        getDrawings: getDrawingsReducer,
        getExpiredDrawings: getExpiredDrawingsReducer,
        getExpiredDrawingsByEntryType: getExpiredDrawingsByEntryTypeReducer,
        getCurrentDrawings: getCurrentDrawingsReducer,
        getNextDrawings: getNextDrawingsReducer,
        reset: resetReducer,
    },
});
//ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getDrawings = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDrawings' }));
                return [4 /*yield*/, apiClient().winners(payload)];
            case 1:
                result = _a.sent();
                dispatch(winnerSlice.actions.getDrawings(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawings' }));
                return [2 /*return*/];
        }
    });
}); };
var getExpiredDrawings = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDrawings' }));
                return [4 /*yield*/, apiClient().expiredDrawings(payload)];
            case 1:
                result = _a.sent();
                dispatch(winnerSlice.actions.getExpiredDrawings(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawings' }));
                return [2 /*return*/];
        }
    });
}); };
var getExpiredDrawingsByEntryType = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result, entryType;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDrawingsByEntryType' }));
                return [4 /*yield*/, apiClient().expiredDrawings(payload)];
            case 1:
                result = _a.sent();
                entryType = payload === null || payload === void 0 ? void 0 : payload.entry_type;
                dispatch(winnerSlice.actions.getExpiredDrawingsByEntryType(__assign(__assign({}, result), { entryType: entryType })));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawingsByEntryType' }));
                return [2 /*return*/];
        }
    });
}); };
var getCurrentDrawings = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDrawings' }));
                return [4 /*yield*/, apiClient().currentDrawings(payload)];
            case 1:
                result = _a.sent();
                dispatch(winnerSlice.actions.getCurrentDrawings(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawings' }));
                return [2 /*return*/];
        }
    });
}); };
var getNextDrawings = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDrawings' }));
                return [4 /*yield*/, apiClient().nextDrawings(payload)];
            case 1:
                result = _a.sent();
                dispatch(winnerSlice.actions.getNextDrawings(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawings' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var winnerActions = function (dispatch, apiClient) {
    return {
        getDrawings: function (payload) { return getDrawings(dispatch, payload, apiClient); },
        getExpiredDrawings: function (payload) { return getExpiredDrawings(dispatch, payload, apiClient); },
        getExpiredDrawingsByEntryType: function (payload) { return getExpiredDrawingsByEntryType(dispatch, payload, apiClient); },
        getCurrentDrawings: function (payload) { return getCurrentDrawings(dispatch, payload, apiClient); },
        getNextDrawings: function (payload) { return getNextDrawings(dispatch, payload, apiClient); },
        reset: function (payload) { return dispatch(winnerSlice.actions.reset(payload)); },
    };
};
