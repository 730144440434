import React from 'react';

const LoadingIndicator = () => {
    return (
        <React.Fragment key='loading-indicator-section'>
            <div className='loading-indicator'>
                <div className='d-flex justify-content-center bg-white'>
                    <div className='loading-indicator-spinner' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoadingIndicator;
