var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice, current } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
export var defaultState = {
    lastRequested: null, // possibly use this to trigger reloading?
    playerOptins: [],
    allOptins: [],
    optinsByCategory: [],
    error: null,
};
// REDUCERS
var getOptinsReducer = function (state, action) {
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    return __assign(__assign({}, state), { lastRequested: Date.now(), playerOptins: action.payload, error: null });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultState);
};
var getAllOptinsReducer = function (state, action) {
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    return __assign(__assign({}, state), { lastRequested: Date.now(), allOptins: action.payload, error: null });
};
var getOptinsByCategoryReducer = function (state, action) {
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    return __assign(__assign({}, state), { lastRequested: Date.now(), optinsByCategory: action.payload, error: null });
};
var optinSubscribeReducer = function (state, action) {
    // The payload will send the specific updated subscription information for that specific
    // optin.   To update it, we first need to find it in the larger list of all playerOptins and
    // update that specific one in the list.
    //
    var payload = action.payload;
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    // state.playerOptins is a array of big objects, so a deep copy is needed here.
    var currentOptins = JSON.parse(JSON.stringify(state.playerOptins));
    var changedSubscriptionIndex = currentOptins.findIndex(function (optin) { return optin.id == payload.notification_type_id; });
    if (changedSubscriptionIndex != -1) {
        var changedSubscription = currentOptins[changedSubscriptionIndex];
        if (changedSubscription && changedSubscription.subscription && Array.isArray(changedSubscription.subscription) && changedSubscription.subscription[0]) {
            changedSubscription.subscription[0] = payload;
            currentOptins[changedSubscriptionIndex] = changedSubscription;
        }
    }
    return __assign(__assign({}, state), { playerOptins: currentOptins, lastRequested: Date.now(), error: null });
};
var optinUnsubscribeReducer = function (state, action) {
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    return __assign(__assign({}, state), { lastRequested: Date.now(), error: null });
};
var updateOptinsReducer = function (state, action) {
    if (action.payload.code) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), error: action.payload.code });
    }
    return __assign(__assign({}, state), { lastRequested: Date.now(), error: null });
};
export var optinsSlice = createSlice({
    name: 'optins',
    initialState: defaultState,
    reducers: {
        getOptins: getOptinsReducer,
        getAllOptins: getAllOptinsReducer,
        getOptinsByCategory: getOptinsByCategoryReducer,
        optinSubscribe: optinSubscribeReducer,
        optinUnsubscribe: optinUnsubscribeReducer,
        updateOptins: updateOptinsReducer,
        reset: resetReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
// Gets the optins with the Player data attached.   Player must be logged in.
var getOptins = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getOptins' }));
                return [4 /*yield*/, apiClient().getOptins()];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.getOptins(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getOptins' }));
                return [2 /*return*/];
        }
    });
}); };
// Gets all Optins set up, no subsctiption info passed, user does not need to be
// logged in.
var getAllOptins = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getAllOptins' }));
                return [4 /*yield*/, apiClient().getAllOptins()];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.getAllOptins(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getAllOptins' }));
                return [2 /*return*/];
        }
    });
}); };
// Gets all Optins set up, no subsctiption info passed, user does not need to be
// logged in.
var getOptinsByCategory = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getOptinsByCategory' }));
                return [4 /*yield*/, apiClient().getOptinsByCategory()];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.getOptinsByCategory(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getOptinsByCategory' }));
                return [2 /*return*/];
        }
    });
}); };
var optinSubscribe = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'optinSubscribe' }));
                return [4 /*yield*/, apiClient().optinSubscribe(payload)];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.optinSubscribe(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'optinSubscribe' }));
                return [2 /*return*/];
        }
    });
}); };
var optinUnsubscribe = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'optinUnsubscribe' }));
                return [4 /*yield*/, apiClient().optinUnsubscribe(payload)];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.optinUnsubscribe(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'optinUnsubscribe' }));
                return [2 /*return*/];
        }
    });
}); };
var updateOptins = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'updateOptins' }));
                return [4 /*yield*/, apiClient().updateOptins(payload)];
            case 1:
                result = _a.sent();
                dispatch(optinsSlice.actions.updateOptins(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'updateOptins' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var optinActions = function (dispatch, apiClient) {
    return {
        getOptins: function (payload) { return getOptins(dispatch, payload, apiClient); },
        getAllOptins: function (payload) { return getAllOptins(dispatch, payload, apiClient); },
        getOptinsByCategory: function (payload) { return getOptinsByCategory(dispatch, payload, apiClient); },
        optinSubscribe: function (payload) { return optinSubscribe(dispatch, payload, apiClient); },
        optinUnsubscribe: function (payload) { return optinUnsubscribe(dispatch, payload, apiClient); },
        updateOptins: function (payload) { return updateOptins(dispatch, payload, apiClient); },
        reset: function (payload) { return dispatch(optinsSlice.actions.reset(payload)); },
    };
};
