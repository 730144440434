import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { errorMessage } from '../errorMessages';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent, getCMSObject, TeleScript } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StateSelectionList from '../ui-components/StateDropdown';
import promotion_config from '../../promotion_config';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

/**********************************************************************
 * Component:  StepThreeTemplate
 * Purpose:    Allows users to enter in Address and phone informstion
 *             Combines information in step2Template here to update the
 *             player with an updateRegister call
 *
 * Props:       step -  counter of what step we are in the registration process
 *              setStep -  a method to change steps in the process
 *              verifyFields -  fields that we will verify upon completion of form
 *              setVerifyFiels - method to set the verification fields
 *              user -  user data store
 *              actions - store actions (apis
 *
 * APIs used:   userActions.update - updates player information
 *
 * Notes:
 */
const StepThreeTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, cmsSourceFirebase, actions }) => {
    const initialStepFields = {
        state: user?.player?.address?.state ?? promotion_config?.jurisdiction?.toUpperCase() ?? '',
        address1: user?.player?.address?.address1 ?? '',
        address2: user?.player?.address?.address2 ?? '',
        city: user?.player?.address?.city ?? '',
        zip: user?.player?.address?.zip ?? '',
        phone: user?.player?.phone ?? '',
    };

    const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    let states = getCMSObject('data.sitewideSettings.states.jsonBlock');
    states = Array.isArray(states) ? states : [];
    const telescript = getCMSObject('data.components.teleScripts.registerForm.jsonBlock');

    const isVerifyRequired = user?.player?.actions?.includes('verify-account');

    //***********************************************************************************
    // Schemas for field verification
    const schema = yup.object().shape({
        address1: yup.string().required('Address Line 1 is required').max(200, 'Address Line 1 cannot exceed 200 characters'),

        address2: yup.string().max(200, 'Address Line 2 cannot exceed 200 characters').nullable(),

        city: yup.string().required('City is required').max(30, 'City cannot exceed 30 characters'),

        state: yup
            .string()
            .required('State is required')
            .min(2, 'State must be 2 characters or longer')
            .max(20, 'State must be less than 20 characters')
            .test('is-state', 'State is not valid.', (value) => {
                if (value.length == 2) {
                    return states.find((st) => st.abbr === value);
                } else {
                    return states.find((st) => st.name === value);
                }
            }),

        zip: yup.string().min(5).max(5).required('Zip is required'),

        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required').nullable(),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState(null);
    const [localErrors, setLocalErrors] = useState({});
    const [loaded, setLoaded] = useState(false);

    /* Since the user can jump into registration at different points in the process (if they were kicked out previously)
       so we need to make sure that email address is in place/valid before we submit - if validateFields doesn't have it
       grab it from the user store.
     */
    useEffect(() => {
        if (verifyFields?.email?.length <= 0 && user.player?.email?.length > 0) {
            setVerifyFields({
                ...verifyFields,
                email: user.player.email,
            });
        } else {
            setLoaded(true);
        }
    }, [verifyFields, user]);

    //***********************************************************************************
    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    //***********************************************************************************
    //  Update the store/API with the curerent fields from registration step 2 and 3
    const updateRegister = async (fieldsToPush) => {
        await actions.userActions.update(fieldsToPush);
    };

    //***********************************************************************************
    // Validate all fields and report errors if they fail schemas.
    const stepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);
        if (valid) {
            setError(null);
            let fieldsToPush = {
                ...verifyFields,
                address: {
                    state: stepFields.state ?? '',
                    address1: stepFields.address1 ?? '',
                    address2: stepFields.address2 ?? '',
                    city: stepFields.city ?? '',
                    zip: stepFields.zip ?? '',
                },
                phone: stepFields.phone ?? '',
            };

            await setVerifyFields(fieldsToPush);
            await updateRegister(fieldsToPush);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
            });
        }
    };

    //***********************************************************************************
    // Helper method to format and pack validation errors
    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    //***********************************************************************************
    // Callback for a updateRegister call -  reports errors or if successful, moves
    // user to next steps in registration process.
    const success = async () => {
        if (step.step == 3 && !user.updateSuccess) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.updateSuccess) {
                setError(null);
                setLocalErrors({});
                actions.userActions.clearErrors();
                if (isVerifyRequired) {
                    setStep({ step: 4 });
                }
            }
        }
    };

    useEffect(success, [user]);

    if (user.updateSuccess && !isVerifyRequired) {
        return <Redirect to='/' />;
    }

    if (step.step == 3 && loaded) {
        return (
            <div className='row justify-content-center'>
                <div className='col-12 col-md-10 col-lg-8'>
                    <ContentBox variant='theme-blue mx-2'>
                        <ContentBoxHead>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.registrationItems.registrationStepThree.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div className='form-step'>
                                <div className='inner-step'>
                                    <div className='col'>
                                        <CMSContent
                                            localStorageObject='webContent'
                                            contentPath='data.registrationItems.registrationStepThree.contentHTML'
                                            cmsSourceFirebase={cmsSourceFirebase}
                                        />
                                    </div>
                                    {error || Object.keys(localErrors).length !== 0 ? (
                                        <Alert variant='danger'>
                                            <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                                            <ul className='alert-text'>
                                                {Object.keys(localErrors).length !== 0
                                                    ? Object.values(localErrors).map((err, index) => {
                                                          return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                                      })
                                                    : null}
                                                {error ? <li dangerouslySetInnerHTML={{ __html: errorMessage(error) }} /> : null}
                                            </ul>
                                        </Alert>
                                    ) : null}
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <label htmlFor='address1'>Address</label>
                                            <input
                                                type='email'
                                                className='form-control theme-input'
                                                id='address1'
                                                placeholder='Address'
                                                value={stepFields.address1}
                                                name='address1'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <label htmlFor='address2'>Address Line 2 (optional)</label>
                                            <input
                                                type='text'
                                                className='form-control theme-input'
                                                id='address2'
                                                placeholder='Address Line 2'
                                                value={stepFields.address2}
                                                name='address2'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <label htmlFor='city'>City</label>
                                            <input
                                                type='text'
                                                className='form-control theme-input'
                                                id='city'
                                                placeholder='City'
                                                value={stepFields.city}
                                                name='city'
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='form-group col'>
                                            <label htmlFor='state'>State</label>
                                            <StateSelectionList currentVal={stepFields.state} changeCB={handleChange} />
                                        </div>
                                        <div className='form-group col'>
                                            <label htmlFor='zip'>Zip</label>
                                            <Cleave
                                                type='text'
                                                className='form-control theme-input'
                                                id='zip'
                                                placeholder='Zip Code'
                                                value={stepFields.zip}
                                                name='zip'
                                                options={{
                                                    blocks: [5],
                                                    numericOnly: true,
                                                }}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <label htmlFor='phone'>Primary Phone Number</label>
                                            <Cleave
                                                className='form-control theme-input col-6'
                                                id='phone'
                                                placeholder='###-###-####'
                                                value={stepFields.phone}
                                                name='phone'
                                                options={{
                                                    blocks: [3, 3, 4],
                                                    delimiter: '-',
                                                    numericOnly: true,
                                                }}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-section text-center mb-3'>
                                <button type='button' className='btn theme-btn theme-secondary' onClick={stepSubmit}>
                                    {isVerifyRequired ? (
                                        <TeleScript line={telescript?.continueStep3Text}>Continue</TeleScript>
                                    ) : (
                                        <TeleScript line={telescript?.submitStep3Text}>Submit</TeleScript>
                                    )}
                                </button>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepThree = connect(mapStateToProps, mapDispatchToProps)(StepThreeTemplate);

export { StepThree };
