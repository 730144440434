var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
export var defaultLoadingState = {
    loading: false,
    actions: {
        login: false,
        logout: false,
        update: false,
        register: false,
        verify: false,
        confirmResend: false,
        forgotPassword: false,
        recoverPassword: false,
        getPlayerData: false,
        clearErrors: false,
        getGame: false,
        getDrawings: false,
        getDrawingsByEntryType: false,
        postFeedback: false,
        getEntries: false,
        getDashboard: false,
        enterIGT24Ticket: false,
        enterDrawTicket: false,
        enterScratchTicket: false,
        enterFastPlayTicket: false,
        setGameOver: false,
        getSweeps: false,
        getSweepDetailsByName: false,
        getActiveSweeps: false,
        getCurrentSweeps: false,
        getPastSweeps: false,
        getSweepDetails: false,
        getSweepDetailsLoggedIn: false,
        getSweepDetailsLoggedInByName: false,
        allocateEntries: false,
        getPoints: false,
        getReward: false,
        getCoupons: false,
        getConfig: false,
        getRevealToken: false,
        getSurveyStatus: false,
        completeSurvey: false,
        getNamedSurveyQuestions: false,
        setSurveyAnswer: false,
        submitSocialMediaClick: false,
        getPromotions: false,
        getPointsForDrawings: false,
        getPlayerPFDTransactions: false,
        redeemPFDPoints: false,
        getPlayerPromotions: false,
        getExternalPromotions: false,
        getPlayerAllocatableEntries: false,
        getPointsHistory: false,
        actionComplete: false,
        getThirdPartyPrizes: false,
        getThirdPartyPrizeBarcode: false,
        getBanners: false,
        updateEmail: false,
        updatePassword: false,
        deletePlayerAccount: false,
        getOptins: false,
        getAllOptins: false,
        getOptinsByCategory: false,
        optinSubscribe: false,
        optinUnsubscribe: false,
        updateOptins: false,
    },
};
// REDUCERS
var setLoadingReducer = function (state, action) {
    var payload = action.payload;
    if (payload && payload['action']) {
        var loadingActions_1 = {};
        loadingActions_1[payload.action] = true;
        return __assign(__assign({}, state), { actions: __assign(__assign({}, state.actions), loadingActions_1) });
    }
    return __assign({}, state);
};
var unsetLoadingReducer = function (state, action) {
    var payload = action.payload;
    if (payload && payload['action']) {
        var loadingActions_2 = {};
        loadingActions_2[payload.action] = false;
        return __assign(__assign({}, state), { actions: __assign(__assign({}, state.actions), loadingActions_2) });
    }
    return __assign({}, state);
};
export var loadingSlice = createSlice({
    name: 'loading',
    initialState: defaultLoadingState,
    reducers: {
        // add an action to chceck localstorage
        // for existing session
        setLoading: setLoadingReducer,
        unsetLoading: unsetLoadingReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var setLoading = function (dispatch, payload) {
    var result = dispatch(loadingSlice.actions.setLoading(payload));
    return result;
};
var unsetLoading = function (dispatch, payload) {
    var result = dispatch(loadingSlice.actions.unsetLoading(payload));
    return result;
};
export var loadingActions = function (dispatch) {
    return {
        // dispatching plain actions
        setLoading: function (payload) { return setLoading(dispatch, payload); },
        unsetLoading: function (payload) { return unsetLoading(dispatch, payload); },
    };
};
