import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, getCMSObject, DisplayContent, FeedbackForm } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import Layout from '../Components/Layout';

const FeedbackPage = ({ user, config, cmsSourceFirebase, actions, feedbackCategories, promotion, loading, feedback, match }) => {
    const feedbackFormTelescript = getCMSObject('data.components.teleScripts.feedback.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <DisplayContent note='Help Intro section' isVisible={hasCMSContent('data.helpAndFaqs.helpIntro.contentHTML')}>
                <div className='row'>
                    <div id='about' className='my-3 col-md-4'>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.helpAndFaqs.helpIntro.image'
                            className='w-100 rounded-image'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </div>
                    <div className='my-3 col d-flex align-items-center playersClubBlurb'>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.helpAndFaqs.helpIntro.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </div>
                </div>
            </DisplayContent>

            <BoxedContent
                note='How It Works section'
                hash='how-it-works'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.howItWorks.contentHeaderText')}
                title={getCMSObject('data.helpAndFaqs.howItWorks.contentHeaderText')}>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.howItWorks.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <BoxedContent
                note='FAQ section'
                hash='faq'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.faqs.contentHeaderText')}
                title={getCMSObject('data.helpAndFaqs.faqs.contentHeaderText')}>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <FeedbackForm
                user={user}
                actions={actions}
                feedbackCategories={feedbackCategories}
                promotion={promotion}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                feedback={feedback}
                telescript={feedbackFormTelescript}
            />

            <BoxedContent
                note='Rules section'
                hash='rules'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHeaderText')}
                title={getCMSObject('data.helpAndFaqs.rules.contentHeaderText')}>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPage);
