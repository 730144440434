import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './link-card-style.scss';
var LinkCard = function (_a) {
    var title = _a.title, onClick = _a.onClick, icon = _a.icon;
    var handleKeyDown = function (event) {
        // Check if the Enter key is pressed (key code 13)
        if (event.key === 'Enter') {
            onClick();
        }
    };
    return (_jsxs("div", { className: 'link-card-container link-typography', onClick: onClick, tabIndex: 0, onKeyDown: handleKeyDown, children: [_jsx(FontAwesomeIcon, { className: 'link-icon mt-1', icon: icon, size: '3x' }), title] }));
};
export default LinkCard;
