import React from 'react';
import { getCMSObject } from 'sg-ui-components';

const StateSelectionList = ({ currentVal, changeCB }) => {

    let states = getCMSObject('data.sitewideSettings.states.jsonBlock');
    states = Array.isArray(states) ? states : [];

    let defaultState = states.find((state) => currentVal === state.name || currentVal === state.abbr);
    if (!defaultState) {
        defaultState = {
            name: 'Missouri',
            abbr: 'MO',
        };
    }
    return (
        <select value={defaultState.abbr} className='required form-control theme-select' id='state' name='state' onChange={changeCB}>
            {states.map((state) => {
                return (
                    <option value={state.abbr} key={state.abbr}>
                        {state.name} - {state.abbr}
                    </option>
                );
            })}
        </select>
    );
};
export default StateSelectionList;
