import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Carousel, Col } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import SiteConfig from '../../promotion_config';

const ExternalPromotions = ({ promotion, config, actions }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                if (promotion.externalPromotions.length === 0) {
                    let baseUrl = config?.config?.lottery_host;
                    const endpointUrl = 'api/promotions';

                    if (baseUrl) {
                        await actions.promotionActions.getExternalPromotions({
                            base_url: baseUrl,
                            endpoint_url: endpointUrl,
                            auth: false,
                        });

                        setPageLoaded(true);
                    }
                }
            }
        }

        fetchData();
    }, [config]);

    useEffect(() => {
        return () => {
            setShowAll(false);
            setPageLoaded(false);
        };
    }, []);

    //* Validation check
    if (!promotion?.externalPromotions?.length) return null;

    const externalPromotions = promotion.externalPromotions ?? [];

    const PromotionCard = ({ id, title, desc, image }) => {
        return (
            <Col lg={4} key={id}>
                <Card className='promotion-card'>
                    <Card.Img variant='top' src={image} className='img-fluid' />
                    <Card.Body className='promotion-body'>
                        <Card.Title className='promotion-title'>{title}</Card.Title>
                        <Card.Text className='promotion-description'>
                            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                        </Card.Text>
                    </Card.Body>
                    <div className='more-info justify-content-end'>
                        <div className='col-6 more-link'>
                            <Link to={`lottery-promotion?id=${id}`} className='more-info-link'>
                                More Info <FaAngleRight className='more-link-icon' />
                            </Link>
                        </div>
                    </div>
                </Card>
            </Col>
        );
    };

    const DesktopList = () => {
        let promotionsToDisplay = externalPromotions;
        if (!showAll && externalPromotions.length > SiteConfig.numPromosToDisplayByDefault) {
            promotionsToDisplay = externalPromotions.slice(0, SiteConfig.numPromosToDisplayByDefault);
        }
        return (
            <>
                <div className='promotion-list d-none d-lg-flex'>
                    {promotionsToDisplay.map(({ id, title, ShortDescription, PromotionImage }) => {
                        return id && title && ShortDescription && PromotionImage ? (
                            <PromotionCard key={id} id={id} title={title} desc={ShortDescription} image={PromotionImage} />
                        ) : null;
                    })}
                </div>
                {showAll === false && externalPromotions.length > SiteConfig.numPromosToDisplayByDefault ? (
                    <div className='text-center'>
                        <button type='button' className='btn theme-btn theme-outline' onClick={() => handleShowAll()}>
                            See All
                        </button>
                    </div>
                ) : null}
            </>
        );
    };

    const handleShowAll = async () => {
        setShowAll(true);
    };

    const MobileCarousel = () => {
        return (
            <Carousel className='promotion-list overflow-hidden d-lg-none'>
                {externalPromotions.map(({ id, title, ShortDescription, PromotionImage }) => {
                    return id && title && ShortDescription && PromotionImage ? (
                        <Carousel.Item key={id} className='text-center' interval={5000}>
                            <PromotionCard id={id} title={title} desc={ShortDescription} image={PromotionImage} />
                        </Carousel.Item>
                    ) : null;
                })}
            </Carousel>
        );
    };

    return (
        <>
            <DesktopList />
            <MobileCarousel />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalPromotions);
