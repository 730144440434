import { jsx as _jsx } from "react/jsx-runtime";
import TeleScript from '../TeleScript/TeleScript';
var FeedbackSuccess = function (_a) {
    var success = _a.success, telescript = _a.telescript;
    if (success) {
        return (_jsx("div", { className: 'alert alert-success text-center', role: 'alert', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.successMessage, children: "Thanks! We've got the message." }) }));
    }
    return null;
};
export default FeedbackSuccess;
