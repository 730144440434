import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { Carousel } from 'react-bootstrap';

import './banner_carousel_syles.scss';

const BannerCarousel = ({ banners, bannerName }) => {
    const carousel = banners[bannerName] ?? [];

    return (
        <Carousel className='overflow-hidden'>
            {carousel
                ? carousel.map((car, index) => {
                      return (
                          <Carousel.Item key={index} className='text-center'>
                              <a href={car.onclick} target={car.new_window === '1' ? '_blank' : '_self'} rel='noreferrer'>
                                  <img className='img-fluid' src={car.url} alt={car.alt_text ?? car.name ?? 'carousel item'} />
                              </a>
                          </Carousel.Item>
                      );
                  })
                : ''}
        </Carousel>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerCarousel);
