var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
export var defaultEntryState = {
    barObject: [],
    revealEntries: [],
    awardedPromotions: [],
    promosInProgress: [],
    ineligiblePromos: [],
    allocatableRewards: [],
    couponRewards: [],
    promoCodeRewards: [],
    experientialRewards: [],
    sweepRewards: [],
    pointRewards: [],
    promosWithReveals: [],
    ticketDetails: {},
    error: '',
};
// REDUCERS
// basic actions that affect this object only
/*******************************************************************************************
 * setBarObjectReducer
 *
 * This parses the BAR object, breaking it up first into:
 *      * promotions that have been triggered and have rewarded the player something,
 *      * promotions that have not been triggered or failed due to limits
 *      * promotions that are still in progress (and have gauges).
 *
 * The awarded promotions are then broken down further into specific arrays based upon
 * their reward type (Coupons, Points, Sweeps, etc).   Awarded promotions that also contain
 * a reveal game will also be broken down so that each "reward" will trigger its own reveal
 * game (since one promotion can potentially reward many different rewards at once).
 *
 * */
var setBarObjectReducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var bar = [];
    // Filter the Bar Object into various usable pieces.
    var ineligible = [];
    var awardedPromos = [];
    var inProgressPromos = [];
    var reveals = [];
    var coupons = [];
    var promoCodes = [];
    var experientials = [];
    var allocatables = [];
    var sweeps = [];
    var points = [];
    var ticketDetails = {};
    var error = '';
    // The BAR can come from multiple places -  when it comes from a Ticket Entry, we can get error codes that we should
    // check and display to the user.     However, for events like Survey or Social Media Promotions (Probably for Optimove too)
    // the payload coming will just contain the BAR object.
    if (!((_a = action.payload) === null || _a === void 0 ? void 0 : _a.success) && ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.message)) {
        // Don't do any processing - just return the error message.
        error = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.message;
        return __assign(__assign({}, state), { barObject: bar, awardedPromotions: awardedPromos, ineligiblePromos: ineligible, promosInProgress: inProgressPromos, couponRewards: coupons, promoCodeRewards: promoCodes, experientialRewards: experientials, allocatableRewards: allocatables, sweepRewards: sweeps, pointRewards: points, promosWithReveals: reveals, ticketDetails: ticketDetails, error: error });
        // Successful Ticket Entry
    }
    else if (((_d = action.payload) === null || _d === void 0 ? void 0 : _d.success) && ((_e = action.payload) === null || _e === void 0 ? void 0 : _e.entry)) {
        bar = (_g = (_f = action.payload) === null || _f === void 0 ? void 0 : _f.entry) === null || _g === void 0 ? void 0 : _g.Bonus;
        ticketDetails = (_j = (_h = action.payload) === null || _h === void 0 ? void 0 : _h.entry) === null || _j === void 0 ? void 0 : _j.TicketDetails;
        // With Social Media and Surveys the payload is the BAR.
    }
    else if (Array.isArray(action.payload)) {
        bar = action.payload;
    }
    // for testing:
    // bar = sampleQueryBar;
    if (bar === null || bar === void 0 ? void 0 : bar.length) {
        ineligible = bar.filter(function (promo) {
            if (promo.result === 'playerlimited' || promo.result === 'globallimited' || promo.result === 'nomatch') {
                return promo;
            }
        });
        awardedPromos = bar.filter(function (promo) {
            if (promo.result === 'awarded') {
                return promo;
            }
        });
        inProgressPromos = bar.filter(function (promo) {
            if (promo.result === 'notry') {
                return promo;
            }
        });
        awardedPromos.forEach(function (promotion) {
            // A promotion can have one or many rewards.  If a promotion has multiple rewards, we
            // break that up here so that each reward is handled independently with its own
            // promotion card and reveal game.
            if (promotion.rewards && promotion.rewards.length > 0) {
                promotion.rewards.forEach(function (reward) {
                    var _a, _b, _c, _d;
                    // do not show a reveal if reward is not 100% complete
                    if (reward.reward.state != 'complete' && reward.reward.state != 'issued') {
                        return;
                    }
                    // Create an object of the data be need to display the reward with all that it needs.
                    var rewardDisplayInfo = {
                        promotionName: (_a = promotion === null || promotion === void 0 ? void 0 : promotion.promotionname) !== null && _a !== void 0 ? _a : '',
                        promotionId: (_b = promotion === null || promotion === void 0 ? void 0 : promotion.promotion_id) !== null && _b !== void 0 ? _b : '',
                        promoReward: reward !== null && reward !== void 0 ? reward : [],
                        content: (_c = promotion === null || promotion === void 0 ? void 0 : promotion.content) !== null && _c !== void 0 ? _c : '',
                        reveal: (_d = promotion === null || promotion === void 0 ? void 0 : promotion.reveal) !== null && _d !== void 0 ? _d : null,
                    };
                    // Games can only be played if they have more than one award in the awards array.
                    if (promotion.reveal && promotion.reveal.length > 0 && reward.awards.length > 1) {
                        reveals.push(rewardDisplayInfo);
                    }
                    // Put each reward into its own bucket based on currency
                    if (reward.reward.currency === 'lotteryproducts') {
                        coupons.push(rewardDisplayInfo);
                    }
                    else if (reward.reward.currency === 'storepromo') {
                        promoCodes.push(rewardDisplayInfo);
                    }
                    else if (reward.reward.currency === 'experiential') {
                        experientials.push(rewardDisplayInfo);
                    }
                    else if (reward.reward.currency === 'sweepstake') {
                        if (reward.reward.allocatable) {
                            allocatables.push(rewardDisplayInfo);
                        }
                        else {
                            sweeps.push(rewardDisplayInfo);
                        }
                    }
                    else if (reward.reward.currency === 'points') {
                        points.push(rewardDisplayInfo);
                    }
                }); // end for eachReward
            } // end if promotion.rewards
        }); // end forEach awardedPromos
    } // end else !bar.success
    else {
        error = 'No Rewards Found';
    }
    return __assign(__assign({}, state), { barObject: bar, awardedPromotions: awardedPromos, ineligiblePromos: ineligible, promosInProgress: inProgressPromos, couponRewards: coupons, promoCodeRewards: promoCodes, experientialRewards: experientials, allocatableRewards: allocatables, sweepRewards: sweeps, pointRewards: points, promosWithReveals: reveals, ticketDetails: ticketDetails, error: error });
};
var clearErrorsReducer = function (state) {
    return __assign({}, state);
};
var clearPendingGamesReducer = function (state) {
    return __assign(__assign({}, state), { promosWithReveals: [] });
};
var resetReducer = function () {
    return __assign({}, defaultEntryState);
};
var overrideReducer = function (state, action) {
    return __assign(__assign({}, state), action.payload);
};
export var bonusingSlice = createSlice({
    name: 'bonusing',
    initialState: defaultEntryState,
    reducers: {
        setBarObject: setBarObjectReducer,
        clearErrors: clearErrorsReducer,
        clearPendingGames: clearPendingGamesReducer,
        reset: resetReducer,
        override: overrideReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
//actions that get exported to the views
export var bonusingActions = function (dispatch) {
    return {
        getBarObject: function (payload) { return dispatch(bonusingSlice.actions.setBarObject(payload)); },
        clearErrors: function () { return dispatch(bonusingSlice.actions.clearErrors()); },
        clearPendingGames: function () { return dispatch(bonusingSlice.actions.clearPendingGames()); },
        reset: function (payload) { return dispatch(bonusingSlice.actions.reset(payload)); },
        override: function (payload) { return dispatch(bonusingSlice.actions.override(payload)); },
    };
};
