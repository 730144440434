import isJSON from './isJSON';
var getCMSObject = function (contentPath, module) {
    var _a;
    if (contentPath === void 0) { contentPath = ''; }
    if (module === void 0) { module = 'webContent'; }
    var resolveDataNode = function (dataToRetrieve, dataObject) {
        return dataToRetrieve.split('.').reduce(function (obj, objKey) {
            return obj && obj[objKey];
        }, dataObject);
    };
    var db = window.localStorage;
    var resultObj = db.getItem(module);
    if (resultObj) {
        var contentObject = isJSON(resultObj) ? JSON.parse(resultObj) : {};
        var contentBody = resolveDataNode(contentPath, contentObject);
        if (contentPath.endsWith('jsonBlock')) {
            var contentBodyJSONstring = (_a = contentBody) !== null && _a !== void 0 ? _a : '{}';
            return isJSON(contentBodyJSONstring) ? JSON.parse(contentBodyJSONstring) : {};
        }
        return contentBody;
    }
    return undefined;
};
export default getCMSObject;
