var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from '../../utils/formatDates';
import TableDesktop from '../TableDesktop/TableDesktop';
import TableMobile from '../TableMobile/TableMobile';
import './pfd-style.scss';
var ClosedDrawings = function (_a) {
    var _b, _c, _d, _e, _f;
    var drawings = _a.drawings, telescript = _a.telescript;
    var displayHeaders = {
        startDate: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableStartDate) !== null && _b !== void 0 ? _b : 'Start Date',
        endDate: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableEndDate) !== null && _c !== void 0 ? _c : 'End Date',
        yourEntries: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableYourEntries) !== null && _d !== void 0 ? _d : 'Your Entries',
        drawDate: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawDate) !== null && _e !== void 0 ? _e : 'Draw Date',
    };
    var displayOptions = {
        tableId: 'myDrawingEntriesAndWinnersTable',
        isLoaded: true,
        messageNoRows: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _f !== void 0 ? _f : 'No drawings',
    };
    var displayRows = drawings.map(function (drawing) {
        var _a, _b, _c;
        return (__assign(__assign({}, drawing), { fields: {
                startDate: formatDate.monthDayYearTimeWithSlashes(drawing.quantum_start),
                endDate: formatDate.monthDayYearTimeWithSlashes(drawing.quantum_end),
                yourEntries: (_c = (_b = (_a = drawing.drawing_summaries) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.entry_count) !== null && _c !== void 0 ? _c : 0,
                drawDate: formatDate.monthDayYearWithSlashes(drawing.draw_date),
            } }));
    });
    return (_jsxs("div", { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
};
export default ClosedDrawings;
