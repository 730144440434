import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Logout from '../Components/Logout/Logout';
import Layout from '../Components/Layout';

const LogoutPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <Logout />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
