import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
/**
 * A simple wrapper component what could help us to render based on conditions.
 */
var DisplayContent = function (_a) {
    var isVisible = _a.isVisible, children = _a.children;
    return isVisible ? _jsx(_Fragment, { children: children }) : null;
};
DisplayContent.defaultProps = {
    children: null,
};
export default DisplayContent;
