import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Gauges } from '../../../index';
var InProgressPromotionsDisplay = function (_a) {
    var promosInProgress = _a.promosInProgress, siteConfig = _a.siteConfig, legacyImageDomain = _a.legacyImageDomain;
    return (_jsx("div", { className: 'container-fluid', children: promosInProgress.map(function (promotion, index) {
            var _a, _b, _c;
            var promoImage = (_b = (_a = promotion.content) === null || _a === void 0 ? void 0 : _a.thumb) !== null && _b !== void 0 ? _b : legacyImageDomain + '/' + ((_c = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.jurisdiction) !== null && _c !== void 0 ? _c : '') + '/promotion_' + promotion.promotion_id + '_thumb.png';
            return (_jsx("div", { className: 'media my-2 text-center claim-box', children: _jsx("div", { className: 'container-fluid', children: _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-md-6 px-0', children: promoImage.length > 0 && _jsx("img", { className: 'promo-image', src: "".concat(promoImage), alt: "".concat(promotion.promotionname, " logo") }) }), _jsx("div", { className: 'col-md-6', children: _jsxs("div", { className: 'media-body promotion-info-container', children: [_jsx("h2", { className: 'promotion-name', children: promotion.promotionname }), _jsx(Gauges, { gauges: promotion.gauges })] }) })] }) }) }, index));
        }) }));
}; // end InProgressPromotionsDisplay
InProgressPromotionsDisplay.defaultProps = {
    promosInProgress: [],
    siteConfig: {},
    legacyImageDomain: '',
};
export default InProgressPromotionsDisplay;
