var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';
import { drawTicketSlice } from './DrawTicketStore';
import isJSON from '../utils/isJSON';
import getCookieByName from '../utils/getCookieByName';
export var defaultUserState = {
    player: {
        name: '',
        email: '',
        player_id: '',
        first_name: '',
        last_name: '',
        email_confirm: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        area_phone1: '',
        prefix_phone1: '',
        suffix_phone1: '',
        phone2: '',
        area_phone2: '',
        prefix_phone2: '',
        suffix_phone2: '',
        dob_year: '',
        dob_month: '',
        dob_day: '',
        password: '',
        verify_password: '',
        optins: '',
        optin1: '',
        optin2: '',
        registrar_id: '',
    },
    loggedIn: false,
    errors: '',
    success: false,
    prizePoints: 'N/A',
    drawPoints: 'N/A',
    pfpRemaining: 'N/A',
    pfpExpiring: 'N/A',
    pointsExpiringDate: 'N/A',
    rafCode: '',
    dashboardErrors: '',
    loaded: false,
};
// REDUCERS FOR THE USER
// basic actions that affect this object only
var loginReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { loggedIn: payload.loggedIn, player: __assign(__assign({}, state.player), payload.player), errors: payload.errors });
};
var resetReducer = function (state, action) {
    return __assign({}, defaultUserState);
};
var logoutReducer = function (state, action) {
    return defaultUserState;
};
var updateReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, success: payload.success, player: __assign(__assign({}, state.player), payload.player) });
};
var registerReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, success: payload.success, player: __assign(__assign({}, state.player), payload.player) });
};
var forgotPasswordReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { errors: payload.errors, success: payload.success });
};
var getDashboardReducer = function (state, action) {
    var _a;
    var payload = action.payload;
    var pfpPoints = 'N/A';
    var pfdPoints = 'N/A';
    var pointsRemaining = 'N/A';
    var pointsExpiring = 'N/A';
    var pointsExpiringDate = 'N/A';
    if (payload && payload.length > 0) {
        payload.forEach(function (type) {
            switch (type.shortname) {
                case 'pfp':
                    pfpPoints = type.value ? type.value : '0';
                    break;
                case 'pfd':
                    pfdPoints = type.value ? type.value : '0';
                    break;
                case 'pfpremaining':
                    pointsRemaining = type.value ? type.value : '0';
                    break;
                case 'pfpexpiring':
                    pointsExpiring = type.value ? type.value : '0';
                    break;
                case 'expiringdatetime':
                    pointsExpiringDate = type.value ? type.value : '0';
                    break;
            } // end switch
        }); // end forEach pointsDash
    }
    return __assign(__assign({}, state), { dashboardErrors: (_a = payload.message) !== null && _a !== void 0 ? _a : '', prizePoints: pfpPoints !== null && pfpPoints !== void 0 ? pfpPoints : 'N/A', drawPoints: pfdPoints !== null && pfdPoints !== void 0 ? pfdPoints : 'N/A', pfpRemaining: pointsRemaining !== null && pointsRemaining !== void 0 ? pointsRemaining : 'N/A', pfpExpiring: pointsExpiring !== null && pointsExpiring !== void 0 ? pointsExpiring : 'N/A', pointsExpiringDate: pointsExpiringDate !== null && pointsExpiringDate !== void 0 ? pointsExpiringDate : 'N/A' });
};
var getPlayerDataReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { loggedIn: payload.loggedIn, player: __assign(__assign({}, state.player), payload.player) });
};
//*********************************************************************************************************************************
// Grabs the AuthToken cookie and updates the player based on the SPAT.     This is the best way to keep the actions table up to
// date as any API that requires authentication will pass the SPAT back with the most up-to-date actions table.
//
// Note that some player attributes such as gender are NOT part of the SPAT, so getPlayerData may still need to be called to update
// that data - but that should be done at a minimum, such as on a refresh that clears the userStore.
//
var getPlayerDataFromAuthTokenReducer = function (state) {
    var _a, _b;
    var authToken = getCookieByName('AuthToken');
    var token = (_a = (isJSON(authToken) && JSON.parse(authToken))) !== null && _a !== void 0 ? _a : null;
    if (token) {
        var spat = (_b = (isJSON(token.spat) && JSON.parse(token.spat))) !== null && _b !== void 0 ? _b : null;
        if (spat) {
            var actions = spat && spat.player && spat.player.actions && Array.isArray(spat.player.actions) ? spat.player.actions : [];
            return __assign(__assign({}, state), { player: __assign(__assign({}, state.player), spat.player), spat: spat, actions: actions, loggedIn: true, loaded: true });
        }
        else {
            return __assign({}, state);
        }
    }
    else {
        return __assign({}, state);
    }
};
var clearErrorsReducer = function (state, action) {
    return __assign(__assign({}, state), { errors: '', success: false });
};
export var userSlice = createSlice({
    name: 'user',
    initialState: defaultUserState,
    reducers: {
        login: loginReducer,
        logout: logoutReducer,
        update: updateReducer,
        register: registerReducer,
        getPlayerData: getPlayerDataReducer,
        getPlayerDataFromAuthToken: getPlayerDataFromAuthTokenReducer,
        getDashboard: getDashboardReducer,
        clearErrors: clearErrorsReducer,
        forgotPassword: forgotPasswordReducer,
        reset: resetReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var login = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!userSlice.loggedIn) return [3 /*break*/, 2];
                dispatch(loadingSlice.actions.setLoading({ action: 'login' }));
                return [4 /*yield*/, apiClient().login(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.login(result));
                dispatch(entrySlice.actions.reset());
                dispatch(drawTicketSlice.actions.reset());
                dispatch(loadingSlice.actions.unsetLoading({ action: 'login' }));
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
var logout = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'logout' }));
                return [4 /*yield*/, apiClient().logout(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.logout(result));
                dispatch(entrySlice.actions.reset());
                dispatch(drawTicketSlice.actions.reset());
                dispatch(loadingSlice.actions.unsetLoading({ action: 'logout' }));
                return [2 /*return*/];
        }
    });
}); };
var update = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'update' }));
                return [4 /*yield*/, apiClient().update(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.update(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'update' }));
                return [2 /*return*/];
        }
    });
}); };
var register = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'register' }));
                return [4 /*yield*/, apiClient().register(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.register(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'register' }));
                return [2 /*return*/];
        }
    });
}); };
var forgotPassword = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'forgotPasword' }));
                return [4 /*yield*/, apiClient().forgotPassword(payload)];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.forgotPassword(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'forgotPasword' }));
                return [2 /*return*/];
        }
    });
}); };
var getPlayerData = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var queryString, urlParams, loginObj, result, result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerData' }));
                queryString = window.location.search;
                urlParams = new URLSearchParams(queryString);
                if (!(urlParams.has('Hash') && urlParams.has('Iv') && urlParams.has('Data'))) return [3 /*break*/, 2];
                loginObj = {
                    hash: urlParams.get('Hash'),
                    iv: urlParams.get('Iv'),
                    data: urlParams.get('Data'),
                };
                return [4 /*yield*/, apiClient().login(loginObj)];
            case 1:
                result = _a.sent();
                if (result && result.loggedIn) {
                    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
                }
                dispatch(userSlice.actions.getPlayerData(result));
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, apiClient().getPlayerData()];
            case 3:
                result = _a.sent();
                dispatch(userSlice.actions.getPlayerData(result));
                _a.label = 4;
            case 4:
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerData' }));
                return [2 /*return*/];
        }
    });
}); };
var getDashboard = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getDashboard' }));
                return [4 /*yield*/, apiClient().getDashboard()];
            case 1:
                result = _a.sent();
                dispatch(userSlice.actions.getDashboard(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getDashboard' }));
                return [2 /*return*/];
        }
    });
}); };
// actions that get exported to the views
export var userActions = function (dispatch, apiClient) {
    return {
        login: function (payload) { return login(dispatch, payload, apiClient); },
        logout: function (payload) { return logout(dispatch, payload, apiClient); },
        update: function (payload) { return update(dispatch, payload, apiClient); },
        register: function (payload) { return register(dispatch, payload, apiClient); },
        forgotPassword: function (payload) { return forgotPassword(dispatch, payload, apiClient); },
        getPlayerData: function (payload) { return getPlayerData(dispatch, payload, apiClient); },
        getPlayerDataFromAuthToken: function () { return dispatch(userSlice.actions.getPlayerDataFromAuthToken()); },
        getDashboard: function (payload) { return getDashboard(dispatch, payload, apiClient); },
        clearErrors: function (payload) { return dispatch(userSlice.actions.clearErrors()); },
        reset: function (payload) { return dispatch(userSlice.actions.reset(payload)); },
    };
};
