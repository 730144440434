//TODO: move to sg-ui-components

/**
 * Retrieves all CMS and config based properties of a component.
 * To avoid typos and mixed naming conventions in features, config, telescript CMS nodes.
 *
 * @param {string} componentName - The unique id of the component instance.
 * @param {object} config - Config store object
 * @returns {object} - The component props.
 *
 * @example
 * hash: can be used for section id's
 * enabled: can be used to determine if the component should be rendered with DisplayContent
 * config: can be used to pass in the component's config object
 * telescript: can be used to pass in the component's telescript object
 * errorBoundaryFallback: can be used to pass in the component's error boundary fallback
 * cmsTelescriptNode: can be used to pass in the component's CMS telescript node
 * cmsConfigNode: can be used to pass in the component's CMS config node
 *
 * Feature node should be here: WebConfig: config.web.features.${componentName}
 * Config node should be here: WebConfig: config.site.components.${componentName}.jsonBlock
 * Telescript node should be here: WebContent: data.components.teleScripts.${componentName}.jsonBlock
 *
 */

import React from 'react';
import { kebabCase } from 'lodash';
import { TeleScript, getTelescriptItem } from 'sg-ui-components';

const getConfigForComponent = (componentName, config) => {
    return {
        id: componentName,
        hash: kebabCase(componentName, '-'),
        enabled: config?.web?.features?.[componentName] ?? false,
        config: config?.site?.components?.[componentName] ?? null,
        telescript: getTelescriptItem(componentName),
        errorBoundaryFallback: <TeleScript line={getTelescriptItem(componentName)?.fallback} />,
    };
};

export default getConfigForComponent;
