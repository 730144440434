import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
/**
 * A trail links representing the heirarchichal structure of the users current page.
 *
 *
 *  For a promotion landing page, the following `breadcrumbData` is fine.
 * ```
 *  const breadcrumbData = [
 *      { name: 'Home', path: '/' },
 *      { name: 'Promotions', path: '/promotions' },
 *  ];
 * ```
 *
 *  For a promotion / sweeps detail page, however, we would push another object onto
 *  the array that represents the current page.
 *  `breadcrumbData.push({ name: sweepstake.name, path: '#' });`
 */
var Breadcrumbs = function (_a) {
    var data = _a.data, className = _a.className;
    if (data.length <= 1) {
        return null;
    }
    return (_jsx("nav", { "aria-label": 'breadcrumb', children: _jsx("ol", { id: 'breadcrumb', className: "breadcrumb ".concat(className !== null && className !== void 0 ? className : ''), children: data.map(function (_a, key) {
                var name = _a.name, path = _a.path;
                return key + 1 === data.length ? (_jsx("li", { "aria-current": 'page', className: 'breadcrumb-item active', children: name }, key)) : (_jsx("li", { className: 'breadcrumb-item', children: _jsx(Link, { to: path, children: name }) }, key));
            }) }) }));
};
Breadcrumbs.defaultProps = {
    className: '',
};
export default Breadcrumbs;
