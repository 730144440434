var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, TableDesktop, TableMobile, TeleScript, formatDate } from '../../../index';
/**********************************************************************************************
 * Component: MyPoints
 * Purpose:   Display's the player's points history.
 */
var MyPoints = function (_a) {
    var _b, _c, _d, _e;
    var points = _a.points, actions = _a.actions, telescript = _a.telescript, _f = _a.options, options = _f === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        hash: 'points-history',
        pageSize: 5,
    } : _f;
    var _g = useState(false), pageLoaded = _g[0], setPageLoaded = _g[1];
    var _h = useState(1), page = _h[0], setPage = _h[1];
    var _j = useState((_b = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _b !== void 0 ? _b : 5), pageSize = _j[0], setPageSize = _j[1];
    var hash = (_c = options === null || options === void 0 ? void 0 : options.hash) !== null && _c !== void 0 ? _c : 'points-history';
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.pointActions.getPointsHistory()];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        return function () {
            var _a;
            setPage(1);
            setPageSize((_a = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _a !== void 0 ? _a : 5);
            setPageLoaded(false);
            actions.pointActions.reset();
        };
    }, []);
    var loadMoreData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, actions.pointActions.getPointsHistory({ page: page + 1, page_size: pageSize })];
                case 1:
                    _a.sent();
                    setPage(page + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    // if there are more items, show the link for more
    var loadMoreLink = function () {
        var _a, _b;
        return ((_a = points === null || points === void 0 ? void 0 : points.history) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = points === null || points === void 0 ? void 0 : points.history) === null || _b === void 0 ? void 0 : _b.length) < (points === null || points === void 0 ? void 0 : points.historyTotalCount) ? (_jsx("div", { className: 'text-center', children: _jsx(Button, { variant: 'outline-secondary', className: 'btn theme-btn theme-outline', onClick: function () { return loadMoreData(); }, children: _jsx(TeleScript, { line: telescript.seeMoreLinkText, children: "See More" }) }) })) : null;
    };
    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    var entriesTable = function (data) {
        var _a, _b, _c, _d, _e;
        var entries = data !== null && data !== void 0 ? data : [];
        var displayHeaders = {
            activity: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.tableActivity) !== null && _a !== void 0 ? _a : 'Activity',
            date: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableDate) !== null && _b !== void 0 ? _b : 'Date',
            type: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableType) !== null && _c !== void 0 ? _c : 'Type',
            points: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tablePoints) !== null && _d !== void 0 ? _d : 'Points',
        };
        var displayRows = __spreadArray([], entries, true).map(function (transaction) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            var transactionComment = (transaction === null || transaction === void 0 ? void 0 : transaction.comments) && ((_a = transaction === null || transaction === void 0 ? void 0 : transaction.comments) === null || _a === void 0 ? void 0 : _a.length) ? transaction === null || transaction === void 0 ? void 0 : transaction.comments : null;
            var activity = '';
            switch (transaction.event_type) {
                case 'administrative_deposit':
                    activity = (_b = transactionComment !== null && transactionComment !== void 0 ? transactionComment : telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeAdministrativeDeposit) !== null && _b !== void 0 ? _b : 'Administrative Deposit';
                    break;
                case 'awardpoints':
                    activity = (_c = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeAwardPoints) !== null && _c !== void 0 ? _c : 'Rewarded Points';
                    break;
                case 'refundpoints':
                    activity = (_d = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeRefundPoints) !== null && _d !== void 0 ? _d : 'Refund';
                    break;
                case 'holdpoints':
                    activity = (_e = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeHoldPoints) !== null && _e !== void 0 ? _e : 'Held Points';
                    break;
                case 'redeempoints':
                    activity = (_f = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeRedeemPoints) !== null && _f !== void 0 ? _f : 'Redeemed';
                    break;
                case 'expirepoints':
                    activity = (_g = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeExpirePoints) !== null && _g !== void 0 ? _g : 'Expired';
                    break;
                case 'cancelpoints':
                    activity = (_h = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeCancelPoints) !== null && _h !== void 0 ? _h : 'Canceled Points';
                    break;
                default:
                    activity = (_j = telescript === null || telescript === void 0 ? void 0 : telescript.eventTypeUnknown) !== null && _j !== void 0 ? _j : 'Unknown Transaction';
                    break;
            }
            var pointType = '';
            switch (transaction.account) {
                case 'pfp':
                    pointType = (_k = telescript === null || telescript === void 0 ? void 0 : telescript.pointTypePFP) !== null && _k !== void 0 ? _k : 'Prize';
                    break;
                case 'pfd':
                    pointType = (_l = telescript === null || telescript === void 0 ? void 0 : telescript.pointTypePFD) !== null && _l !== void 0 ? _l : 'Draw';
                    break;
                default:
                    pointType = (_m = telescript === null || telescript === void 0 ? void 0 : telescript.pointTypeDefault) !== null && _m !== void 0 ? _m : 'Points';
                    break;
            }
            return __assign(__assign({}, transaction), { fields: {
                    activity: activity !== null && activity !== void 0 ? activity : '',
                    date: (_o = formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithMeridiem(transaction.datetime)) !== null && _o !== void 0 ? _o : '',
                    type: pointType !== null && pointType !== void 0 ? pointType : '',
                    points: (_p = transaction.amount) !== null && _p !== void 0 ? _p : '',
                } });
        });
        var displayOptions = {
            tableId: 'my-points-table',
            isLoaded: pageLoaded,
            messageNoRows: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.messageNoRows) !== null && _e !== void 0 ? _e : 'You currently have no new Draw Points.',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsxs(ContentBox, { variant: (_d = options === null || options === void 0 ? void 0 : options.variant) !== null && _d !== void 0 ? _d : 'theme-primary', id: hash, show: isHash(hash) ? 'true' : 'false', children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "My Points" }), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true ? _jsx(ContentCollapser, {}) : ''] }), _jsx(ContentBoxBody, { children: _jsx("div", { className: 'row', children: _jsxs("div", { className: 'col', children: [entriesTable((_e = points === null || points === void 0 ? void 0 : points.history) !== null && _e !== void 0 ? _e : []), loadMoreLink()] }) }) })] }));
};
export default MyPoints;
