import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Field } from 'formik';
var PromotionDropdown = function (_a) {
    var promotions = _a.promotions, values = _a.values, isSubmitting = _a.isSubmitting, telescript = _a.telescript;
    if (promotions.length) {
        return (_jsxs(Field, { disabled: isSubmitting, as: 'select', className: 'form-control theme-select text-and-select', name: 'promotion', id: 'promotion', value: values.promotion, children: [_jsx("option", { value: (telescript === null || telescript === void 0 ? void 0 : telescript.noPromotionText) || 'No Promotion', children: (telescript === null || telescript === void 0 ? void 0 : telescript.noPromotionText) || 'No Promotion' }, 'none'), promotions.map(function (_a) {
                    var id = _a.id, name = _a.name;
                    return (_jsx("option", { value: name, children: name }, id));
                })] }));
    }
    else {
        return (_jsx(Field, { disabled: true, name: 'promotion', type: 'text', id: 'promotion', className: 'form-control theme-input', size: '60', maxLength: '60', value: values.promotion }));
    }
};
export default PromotionDropdown;
