import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';
import { ContentBox, ContentBoxBody, ContentBoxHead, CMSContent, TeleScript, getCMSObject } from 'sg-ui-components';
import { Accordion, Card, Alert } from 'react-bootstrap';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import NumberSelector from '../ui-components/NumberSelector';
import { errorMessage } from '../errorMessages';

import './allocatable-entries.scss';
import { FaRegTimesCircle } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

/**********************************************************************
 * Component: AllocatableEntriesForm
 * Purpose:   Allows the user to choose their entries for an
 *            allocatable sweep.
 *
 * Props: - sweepId       -  Parent sweepstake that we have
 *                           allocatable entries for
 *          promotionName -  name of the promotion that awarded the entries
 *          numEntries    -  Number of entries that were awarded
 *          entryId       -  Sweeps Entry ID from promotion reward
 *          sweep         -  reference to the parent Sweepstake
 *          actions       -  actions table for api calls
 *
 * APIs used:  getSweepDetails -  gets the details/drawing groups of the
 *                                 current sweep
 *             allocateEntries - Allocates the users entries once selected.
 */
const AllocatableEntriesForm = ({ sweepId, promotionName, numEntries, entryId, cmsSourceFirebase, actions, sweep }) => {
    const [defaultPrizeName, setDefaultPrizeName] = useState('tbd');
    const [drawingGroups, setDrawingGroups] = useState([]);
    const [currentAllocatedEntries, setCurrentAllocatedEntries] = useState(new Map());
    const [unallocatedEntries, setUnallocatedEntries] = useState(numEntries);
    const [allowSubmission, setAllowSubmission] = useState(false);
    const [error, setError] = useState('');
    const [sweepDetails, setSweepDetails] = useState([]);
    const [allocationResults, setAllocationResults] = useState([]);
    const telescript = getCMSObject('data.components.teleScripts.allocatableEntries.jsonBlock');
    //***********************************************************************************
    // On load get the sweeps details.
    //
    useEffect(() => {
        setUnallocatedEntries(numEntries);
        // NOTE:   To prevent too many API calls, the parent object should call getSweeps first to
        // populate the store.    We include this here just in case the sweeps array is empty and we don't
        // have the needed data to populate the form.
        async function fetchSweeps() {
            await actions.sweepActions.getSweeps();
        }
        if (!sweep.sweeps.length) {
            fetchSweeps();
        }

        return () => {
            setSweepDetails([]);
            setError('');
            setCurrentAllocatedEntries([]);
            setDefaultPrizeName([]);
            setDrawingGroups([]);
        };
    }, []);

    useEffect(() => {
        // filter list of sweeps down. . .
        let thisSweep = sweep.sweeps.filter((sweepstake) => {
            if (sweepstake.id == sweepId) {
                return sweepstake;
            }
        });
        setSweepDetails(thisSweep[0]);

        // See if this sweep was allocated and what the result was
        if (sweep.allocatedDetails.length) {
            let sweepAllocatedResults = sweep.allocatedDetails.filter((allocatedResult) => {
                if (allocatedResult.sweepId === sweepId && allocatedResult.entryId === entryId) {
                    return allocatedResult;
                }
            });
            if (sweepAllocatedResults && sweepAllocatedResults.error && sweepAllocatedResults.data && sweepAllocatedResults.data.code) {
                setError(errorMessage(sweepAllocatedResults.data.code));
            }
            setAllocationResults(sweepAllocatedResults);
        }
    }, [sweep]);

    useEffect(() => {
        if (sweepDetails && sweepDetails.drawing_groups) {
            let entries = new Map();
            const defaultPrize = sweepDetails.drawing_groups.filter((drawGroup) => {
                entries.set(drawGroup.id, 0);
                if (drawGroup.default_allocation) return drawGroup;
            });
            setCurrentAllocatedEntries(new Map(entries));
            setDefaultPrizeName(defaultPrize[0]?.name ?? '');
            setDrawingGroups(sweepDetails.drawing_groups ?? '');
        }
    }, [sweepDetails]);

    //***********************************************************************************
    // Gather up all the entries and pack them into the payload to allocate them
    //
    //  api:  /api/sweepstakes/$sweepstake_id/entries/allocate
    //  @param - $sweepstake_id - the allocatable sweeps
    //  * @param $entry_id      - the player's allocatable entry
    //  * @param array $allocations - array of tuples (drawing_group_id, entry_count)
    //
    const submitEntries = async () => {
        await actions.sweepActions.clearAllocatableDetails(sweepId);
        let allocationsArray = [];
        currentAllocatedEntries.forEach(function (entries, id) {
            allocationsArray.push({ drawing_group_id: id, entry_count: entries });
        });
        await actions.sweepActions.allocateEntries({
            sweepstake_id: sweepId,
            entry_id: entryId,
            allocation: allocationsArray,
        });
        await actions.sweepActions.getSweeps();
        await actions.winnerActions.getCurrentDrawings({ entry_type: 'promotion' });
        await actions.sweepActions.getPlayerAllocatableEntries();
    }; // end submitEntries

    //***********************************************************************************
    // Render of each Drawing group and its pickers for allocatable entries.
    //
    //
    const DrawingGroupDisplay = ({ drawing }) => {
        const [detailsOpen, setDetailsOpen] = useState(false);
        let numWinners = 0;
        let currentValue = currentAllocatedEntries.get(drawing.id);
        drawing.prizes.forEach((prize) => {
            numWinners += prize.quantity;
        });

        const prizeDetails = drawing.extra && drawing.extra.prize_details ? drawing.extra.prize_details : '';

        // Allows toggling of the Prize Details accordion
        const togglePrizeDetails = () => {
            setDetailsOpen(!detailsOpen);
        }; // end togglePrizeDetails

        // On a change of the number selectors,  update the allocated entries map
        const manageEntries = (value) => {
            if (value != 'undefined') {
                let tempEntries = currentAllocatedEntries;
                tempEntries.set(drawing.id, value);
                setCurrentAllocatedEntries((currentAllocatedEntries) => new Map([...currentAllocatedEntries, [drawing.id, value]]));
            }
        }; // end manageEntries

        // calculate the unallocated entries left check to see if we can submit the form
        useEffect(() => {
            let totalCurrentlyAllocated = 0;
            currentAllocatedEntries.forEach((value) => {
                totalCurrentlyAllocated += value;
            });
            let unallocated = numEntries - totalCurrentlyAllocated;
            unallocated === 0 ? setAllowSubmission(false) : setAllowSubmission(true);
            setUnallocatedEntries(unallocated);
        }, [currentAllocatedEntries]);

        return (
            <div className='container drawing-group'>
                <div className='row'>
                    <div className='col'>
                        <img src={drawing.extra?.image ?? ''} alt={drawing.name ?? ''} className='img-fluid' />
                    </div>
                    <div className='col'>
                        <h4 className='text-uppercase'>{drawing.name ?? ''}</h4>
                        <p dangerouslySetInnerHTML={{ __html: drawing.extra?.description ?? '' }} />
                        <p>
                            {numWinners} {numWinners > 1 ? 'Winners' : 'Winner'} / drawing.
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-8 col-xl-9'>
                        <Accordion className='prize-details'>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey='0' onClick={() => togglePrizeDetails()}>
                                    Prize Details {detailsOpen ? <BiChevronDown /> : <BiChevronUp />}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey='0'>
                                    <Card.Body>
                                        <p dangerouslySetInnerHTML={{ __html: prizeDetails }} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    <div className='col-sm-4 col-xl-3'>
                        <NumberSelector value={currentValue} max={unallocatedEntries + currentValue} label='Number of entries' parentCallback={manageEntries} />
                    </div>
                </div>
            </div>
        );
    }; // end DrawingGroupDisplay

    return (
        <>
            {error ? (
                <div className='allocatable-entries-form'>
                    <Alert variant='danger' className='d-flex align-items-center rounded-pill'>
                        <FaRegTimesCircle />
                        <div className='col list-unstyled m-0' dangerouslySetInnerHTML={{ __html: error }} />
                    </Alert>
                </div>
            ) : (
                <div className='allocatable-entries-form'>
                    {allocationResults.length && allocationResults[0].submitted ? (
                        <div className='entries-submitted-success'>Success: Your Entries have been submitted!</div>
                    ) : (
                        <ContentBox>
                            <ContentBoxHead>
                                <CMSContent
                                    localStorageObject='webContent'
                                    contentPath='data.allocatableEntriesPopup.allocatableEntriesTitle.contentHTML'
                                    cmsSourceFirebase={cmsSourceFirebase}
                                />
                            </ContentBoxHead>
                            <ContentBoxBody>
                                <p>
                                    <TeleScript
                                        line={telescript?.allocatedEarningMessage}
                                        replace={{
                                            '%ENTRY_WORD%': numEntries === 1 ? 'entry' : 'entries',
                                            '%NUM_ENTRIES%': numEntries?.toString() ?? '',
                                            '%PROMOTION_NAME%': promotionName,
                                        }}>
                                        You have earned <strong>{numEntries}</strong> {numEntries > 1 ? 'entries' : 'entry'} into the{' '}
                                        <strong>{promotionName}</strong> drawing of your choice. Use the &quot;-&quot; and &quot;+&quot; buttons below to choose
                                        your drawing.
                                    </TeleScript>
                                </p>
                                {window.location.pathname !== '/my-activity' ? (
                                    <p className='later-time-message'>
                                        You may also assign your entries at a later time, prior to the draw date by viewing your{' '}
                                        <HashLink to='/my-activity#allocatable-entries'>unassigned entries table in My Activity</HashLink>.
                                    </p>
                                ) : null}
                                <p className='text-center'>
                                    <TeleScript
                                        line={telescript?.allocatedPrizeNameMessage}
                                        replace={{
                                            '%PRIZE_NAME%': defaultPrizeName,
                                        }}>
                                        Any entries left unassigned will be entered into <strong>{defaultPrizeName}</strong> by default.
                                    </TeleScript>{' '}
                                </p>
                                <h3 className='entries-to-allocate'>
                                    {' '}
                                    {unallocatedEntries} {unallocatedEntries > 1 ? 'entries' : 'entry'} to assign
                                </h3>
                                {drawingGroups.map((drawingGroup, index) => {
                                    return <DrawingGroupDisplay key={index} drawing={drawingGroup} />;
                                })}
                                <button className='submit-allocatable' disabled={allowSubmission} onClick={submitEntries}>
                                    Submit Entries
                                </button>
                            </ContentBoxBody>
                        </ContentBox>
                    )}
                </div>
            )}
        </>
    );
}; // ene AllocatableEntriesForm
export default connect(mapStateToProps, mapDispatchToProps)(AllocatableEntriesForm);
