import { Link } from 'react-router-dom';
import { ContentBoxHead, ContentBoxBody, ContentBox } from 'sg-ui-components';
import React from 'react';

/**********************************************************************
 * Component:  ReadyToGo
 * Purpose:    Simple Component that tells user they are verified
 *             and Ready To Go
 *
 * Props:      none
 *
 * APIs used:   None
 */
export const ReadyToGo = () => {
    return (
        <ContentBox variant='theme-blue mx-2 registration-step readytogo'>
            <ContentBoxHead>You&apos;re Ready To Go</ContentBoxHead>
            <ContentBoxBody>
                <img src='jurisdiction_logo.png' className='img-fluid mx-auto my-3' />
                <p className='text-center'>
                    Your email has been confirmed! Just a few more questions before you have access to all My Lottery Players Club features.
                </p>
                <p className='text-center'>
                    <Link to='/enter'>
                        <button type='button' className='btn theme-btn theme-primary btn-lg'>
                            Continue To Home
                        </button>
                    </Link>
                </p>
            </ContentBoxBody>
        </ContentBox>
    );
};
