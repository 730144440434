import React from 'react';
import { connect } from 'react-redux';
import {
    getCMSObject,
    MyPoints,
    MyDrawingEntries,
    MyTicketHistory,
    MyRewardsHistory,
    MyCouponsHistory,
    MyPointsForDrawing,
    ErrorBoundary,
    TeleScript,
} from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

import MyAllocatableEntries from '../Components/MyActivity/MyAllocatableEntries';
import MyPrizes from '../Components/MyActivity/MyPrizes';

import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import siteConfig from '../promotion_config';

const MyActivityPage = ({ reward, coupon, user, config, winner, points, cmsSourceFirebase, actions, activity, page, match, pointsForDrawings }) => {
    const lotteryLogo = getCMSObject('data.sitewideSettings.alternateLogo.image.imageSrc');
    const myPointsTelescript = getCMSObject('data.components.teleScripts.myPointsHistory.jsonBlock');
    const myPointsForDrawingTelescript = getCMSObject('data.components.teleScripts.myPointsForDrawing.jsonBlock');
    const myTicketHistoryTelescript = getCMSObject('data.components.teleScripts.myTicketHistory.jsonBlock');
    const myDrawingEntriesTelescript = getCMSObject('data.components.teleScripts.myDrawingEntries.jsonBlock');
    const myCouponsTelescript = getCMSObject('data.components.teleScripts.couponsHistory.jsonBlock');
    const myRewardsHistoryTelescript = getCMSObject('data.components.teleScripts.myRewardsHistory.jsonBlock');
    const myAllocatableTelescript = getCMSObject('data.components.teleScripts.myAllocatableEntries.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <div className='row'>
                <div className='col'>
                    <h1>MY ACTIVITY</h1>
                </div>
            </div>
            <MyPrizes />
            <ErrorBoundary fallback={<TeleScript line={myAllocatableTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyAllocatableEntries />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={myDrawingEntriesTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyDrawingEntries
                    winner={winner}
                    actions={actions}
                    telescript={myDrawingEntriesTelescript}
                    WinnerLink={WinnerLinkWrapper}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        showByDefault: false,
                        hash: 'drawing-entries',
                        pageSize: siteConfig?.components?.drawingEntries?.defaultPageSize ?? 5,
                        promotionDetailsLink: '/second-chance-promotion',
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={myPointsForDrawingTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyPointsForDrawing
                    pointsHistory={pointsForDrawings?.playerPFDTransactions}
                    actions={actions}
                    telescript={myPointsForDrawingTelescript}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'points-for-drawing',
                        pageSize: siteConfig?.components?.pointsHistory?.defaultPageSize ?? 5,
                        linkOlderDrawingsTarget: myPointsForDrawingTelescript?.linkOlderDrawingsTarget ?? `_self`,
                        linkOlderDrawings: myPointsForDrawingTelescript?.linkOlderDrawingsUrl ?? `${config.config.store_uri}/users/drawing_orders.php`,
                    }}
                />
            </ErrorBoundary>

            <ErrorBoundary fallback={<TeleScript line={myCouponsTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyCouponsHistory
                    coupon={coupon}
                    actions={actions}
                    telescript={myCouponsTelescript}
                    lotteryLogo={lotteryLogo}
                    barcodeHost={config.config.barcode_image_host}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'coupons-history',
                        pageSize: siteConfig?.components?.couponHistory?.defaultPageSize ?? 5,
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={myPointsTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyPoints
                    points={points}
                    actions={actions}
                    telescript={myPointsTelescript}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'points-history',
                        pageSize: siteConfig?.components?.pointHistory?.defaultPageSize ?? 5,
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={myTicketHistoryTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyTicketHistory
                    activity={activity}
                    actions={actions}
                    telescript={myTicketHistoryTelescript}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'ticket-history',
                        pageSize: siteConfig?.components?.ticketHistory?.defaultPageSize ?? 5,
                        promotionsLink: '/promotions',
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={myRewardsHistoryTelescript?.fallback} />} details={{ page, user, config, reward }}>
                <MyRewardsHistory
                    reward={reward}
                    actions={actions}
                    telescript={myRewardsHistoryTelescript}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'rewards-history',
                        pageSize: siteConfig?.components?.rewardsHistory?.defaultPageSize ?? 5,
                        promotionsLink: '/promotions',
                    }}
                    resolveCurrency={siteConfig.resolveCurrency}
                />
            </ErrorBoundary>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivityPage);
