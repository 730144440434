var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, TeleScript, getCMSObject, ErrorMessage, SelectionWidget } from '../../../index';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*******************************************************************************************
 * Component: OptinForm
 * Purpose:   Used to create the notification selection widget/card that
 *            lists all available optins for the user that they can select
 *            categorized by context.  This was update for DE and uses a bulk
 *            update of optins rather than updating them one by one, see Missouri code
 *            for an example of the "old" way of doing these.
 *
 * Props: - actions - store actions (apis)
 *          user - reference to the user store
 *          optins - reference to the optins/notifiction store
 *          heading - optional heading for the content box.
 *
 * APIs used:   mrbEnsemble.getOptinsByCategory - all optins with player data sorted
 *                                                 in a key/value pair grouped by context.
 *              mrbEnsemble.updateOptins -  updates the user's selections and subscribes /
 *                                          unsubscribes the notifications in bulk.
 *
 */
var OptinAccordian = function (_a) {
    var _b;
    var actions = _a.actions, user = _a.user, optins = _a.optins, _c = _a.heading, heading = _c === void 0 ? '' : _c;
    var _d = useState(false), loaded = _d[0], setLoaded = _d[1];
    var _e = useState(false), successfulUpdate = _e[0], setSuccessfulUpdate = _e[1];
    var _f = useState([]), subscribedOptins = _f[0], setSubscribedOptins = _f[1];
    var _g = useState(false), updateInProgress = _g[0], setUpdateInProgress = _g[1];
    var myNotificationsTelescript = getCMSObject('data.components.teleScripts.myNotifications.jsonBlock');
    var hash = 'my-notifications';
    //***********************************************************************************
    // Get all the Optins from the API (with player data)
    var getPlayerOptins = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // Get the Optins from the Optin Store.
                return [4 /*yield*/, actions.optinsActions.getOptinsByCategory()];
                case 1:
                    // Get the Optins from the Optin Store.
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; // end getAllOptins
    //***********************************************************************************
    // Get all the Optins data on load or when the checkedState has changed (and thus when
    // a player has subscribed/unsubscribed from that optin.
    //
    var clearAction = function (actionName) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(((_b = (_a = user.player) === null || _a === void 0 ? void 0 : _a.actions) === null || _b === void 0 ? void 0 : _b.length) > 0 && user.player.actions.includes(actionName))) return [3 /*break*/, 2];
                    return [4 /*yield*/, actions.userActions.actionComplete({ action_name: actionName })];
                case 1:
                    _c.sent();
                    _c.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var currentlySubscribedOptins = [];
        if (!loaded) {
            setLoaded(true);
            clearAction('review-optins'); //? we need to clear this action on pageload
            getPlayerOptins();
        }
        if (updateInProgress && !optins.errors) {
            setUpdateInProgress(false);
            setSuccessfulUpdate(true);
        }
        optins.optinsByCategory &&
            Object.keys(optins.optinsByCategory).forEach(function (key) {
                optins === null || optins === void 0 ? void 0 : optins.optinsByCategory[key].forEach(function (optin) {
                    var _a, _b, _c, _d;
                    var subscribed = optin.subscription.find(function (_a) {
                        var channel = _a.channel;
                        return channel === 'email';
                    });
                    if (subscribed) {
                        if ((_b = (_a = optin.subscription) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.jackpot) {
                            var subscribedOptin = {
                                type: optin.type,
                                channel: { type: 'email' },
                                filter: { jackpot: (_d = (_c = optin.subscription) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.jackpot },
                            };
                            currentlySubscribedOptins.push(subscribedOptin);
                        }
                        else {
                            var subscribedOptin = {
                                type: optin.type,
                                channel: { type: 'email' },
                                filter: {},
                            };
                            currentlySubscribedOptins.push(subscribedOptin);
                        }
                    }
                });
            });
        setSubscribedOptins(currentlySubscribedOptins);
    }, [loaded, optins]); // end useEffect
    var unsubscribe = function (optinToUnsubscribe) { return __awaiter(void 0, void 0, void 0, function () {
        var currentSubscriptions, index;
        return __generator(this, function (_a) {
            currentSubscriptions = __spreadArray([], subscribedOptins, true);
            index = currentSubscriptions.findIndex(function (optin) { return optin.type === optinToUnsubscribe.type; });
            if (index != -1) {
                currentSubscriptions.splice(index, 1);
                setSubscribedOptins(currentSubscriptions);
            }
            return [2 /*return*/];
        });
    }); };
    //***********************************************************************************
    // Handler for when a subscription is clicked -  will add or remove the optin from
    // the local array of subscribedOptins
    var handleClick = function (affectedOptin, event) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedValue, isChecked, currentlySubscribed, foundIndex;
        var _a;
        return __generator(this, function (_b) {
            setSuccessfulUpdate(false);
            selectedValue = event.currentTarget.value;
            isChecked = event.currentTarget.checked;
            currentlySubscribed = __spreadArray([], subscribedOptins, true);
            if (isChecked) {
                if (Object.keys((_a = affectedOptin.filters) !== null && _a !== void 0 ? _a : []).length) {
                    if (currentlySubscribed.find(function (obj) { return obj.type === affectedOptin.type; })) {
                        foundIndex = currentlySubscribed.findIndex(function (x) { return x.type === affectedOptin.type; });
                        currentlySubscribed[foundIndex] = {
                            type: affectedOptin.type,
                            channel: { type: 'email' },
                            filter: { jackpot: selectedValue },
                        };
                    }
                    else {
                        currentlySubscribed.push({
                            type: affectedOptin.type,
                            channel: { type: 'email' },
                            filter: { jackpot: selectedValue },
                        });
                    }
                }
                else {
                    currentlySubscribed.push({ type: affectedOptin.type, channel: { type: 'email' }, filter: {} });
                }
                setSubscribedOptins(currentlySubscribed);
            }
            else {
                unsubscribe(affectedOptin);
            }
            return [2 /*return*/];
        });
    }); }; // end handleClick
    var saveOptins = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payloadData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payloadData = {
                        optins: subscribedOptins !== null && subscribedOptins !== void 0 ? subscribedOptins : {},
                    };
                    setUpdateInProgress(true);
                    return [4 /*yield*/, actions.optinsActions.updateOptins(payloadData)];
                case 1:
                    _a.sent();
                    setLoaded(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var OptinWithFilters = function (_a) {
        var _b, _c, _d, _e, _f;
        var optin = _a.optin;
        var filterOptions = [];
        var _g = useState(false), showFilters = _g[0], setShowFilters = _g[1];
        var checked = '';
        var filter = '';
        if (subscribedOptins.length) {
            var subscription = subscribedOptins.find(function (_a) {
                var type = _a.type;
                return type === optin.type;
            });
            checked = (_b = subscription === null || subscription === void 0 ? void 0 : subscription.type) !== null && _b !== void 0 ? _b : '';
            filter = (_d = (_c = subscription === null || subscription === void 0 ? void 0 : subscription.filter) === null || _c === void 0 ? void 0 : _c.jackpot) !== null && _d !== void 0 ? _d : '';
        }
        (optin === null || optin === void 0 ? void 0 : optin.filters) &&
            Object.entries(optin.filters).forEach(function (filter) {
                filterOptions.push({
                    label: filter[1],
                    value: filter[0],
                    name: optin.tag,
                });
            });
        // Toggle the child filters.
        var handleFilterControlClick = function (event, optinAffected) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setShowFilters(!showFilters);
                if (!event.currentTarget.checked) {
                    unsubscribe(optinAffected);
                }
                return [2 /*return*/];
            });
        }); };
        return (
        // Look for the control
        _jsxs("div", { className: 'form-group notification-control', children: [_jsxs("div", { className: 'form-check', children: [_jsx("input", { type: 'checkbox', id: optin.id, name: optin.type, className: 'form-check-input', value: optin.id.toString(), defaultChecked: checked, onClick: function (event) { return handleFilterControlClick(event, optin); } }), _jsx("label", { className: 'form-check-label', htmlFor: optin.id, children: (_f = (_e = optin.extra) === null || _e === void 0 ? void 0 : _e.control_text) !== null && _f !== void 0 ? _f : optin.tag })] }), showFilters || checked ? (_jsx("div", { id: "SelectionWidget-".concat(optin.id), className: 'controlled-filters', children: _jsx(SelectionWidget, { options: filterOptions, changeCallback: function (event) { return handleClick(optin, event); }, className: 'nested-notification-filters', defaultValue: filter.toString() }, optin.id) })) : null] }, optin.id));
    };
    var OptinWithoutFilters = function (_a) {
        var _b;
        var optin = _a.optin;
        var selectionWidgetOptions = [
            {
                label: optin.tag,
                value: optin.id.toString(),
            },
        ];
        var checked = '';
        if (subscribedOptins.length) {
            var subscription = subscribedOptins.find(function (_a) {
                var type = _a.type;
                return type === optin.type;
            });
            if (subscription) {
                checked = (_b = subscription.type) !== null && _b !== void 0 ? _b : '';
            }
        }
        return (_jsx(SelectionWidget, { options: selectionWidgetOptions, changeCallback: function (event) { return handleClick(optin, event); }, className: 'form-check', defaultValue: checked.toString() }, optin.id));
    };
    return (_jsxs(ContentBox, { variant: 'theme-primary', id: hash, show: isHash(hash) ? 'true' : 'false', children: [_jsxs(ContentBoxHead, { children: [heading, _jsx(ContentCollapser, {})] }), _jsx(ContentBoxBody, { children: updateInProgress ? (_jsx(React.Fragment, { children: _jsx("div", { className: 'd-flex justify-content-center bg-white', children: _jsx("div", { className: 'loading-indicator', role: 'status', children: _jsx("span", { className: 'sr-only', children: "Loading..." }) }) }) }, 'loading-indicator-spection')) : (_jsxs(_Fragment, { children: [_jsx(ErrorMessage, { code: (_b = optins === null || optins === void 0 ? void 0 : optins.error) !== null && _b !== void 0 ? _b : '', collection: 'data.messages.errorMessages.jsonBlock' }), successfulUpdate ? (_jsxs(Alert, { variant: 'success', children: [_jsx(FontAwesomeIcon, { icon: 'fa-solid fa-circle-check' }), _jsx("span", { className: 'alert-text', children: _jsx(TeleScript, { line: myNotificationsTelescript === null || myNotificationsTelescript === void 0 ? void 0 : myNotificationsTelescript.successfulSaveMessage, children: "Notifications have been updated." }) })] })) : null, _jsx("div", { className: 'form-step', children: _jsxs("div", { className: 'inner-step', children: [_jsx(TeleScript, { line: myNotificationsTelescript === null || myNotificationsTelescript === void 0 ? void 0 : myNotificationsTelescript.heading, children: _jsx("h2", { children: "My Notification Options" }) }), _jsx(TeleScript, { line: myNotificationsTelescript === null || myNotificationsTelescript === void 0 ? void 0 : myNotificationsTelescript.introDirections, children: _jsx("p", { children: "Select notification options below to stay up to date." }) }), optins.optinsByCategory &&
                                        Object.keys(optins.optinsByCategory).map(function (key, i) {
                                            var _a, _b, _c;
                                            return (_jsxs("div", { className: 'notification-type', children: [_jsx("h3", { children: (_c = (_b = (_a = optins === null || optins === void 0 ? void 0 : optins.optinsByCategory[key][0]) === null || _a === void 0 ? void 0 : _a.extra) === null || _b === void 0 ? void 0 : _b.context_heading) !== null && _c !== void 0 ? _c : optins === null || optins === void 0 ? void 0 : optins.optinsByCategory[key].context }, i), optins === null || optins === void 0 ? void 0 : optins.optinsByCategory[key].map(function (optin) {
                                                        if (optin.filters && Object.keys(optin.filters).length) {
                                                            return _jsx(OptinWithFilters, { optin: optin }, optin.id);
                                                        }
                                                        else {
                                                            return _jsx(OptinWithoutFilters, { optin: optin }, optin.id);
                                                        } // end else if (option.filters.length > 0)
                                                    })] }, i));
                                        }), _jsx(TeleScript, { line: myNotificationsTelescript === null || myNotificationsTelescript === void 0 ? void 0 : myNotificationsTelescript.saveInstructions, children: _jsx("p", { className: 'save-instructions', children: "Click \u201CUpdate Preferences\u201D button when you have made your changes." }) }), _jsx("div", { className: 'text-center', children: _jsx("button", { type: 'button', className: 'save-optins-btn', onClick: saveOptins, children: _jsx(TeleScript, { line: myNotificationsTelescript === null || myNotificationsTelescript === void 0 ? void 0 : myNotificationsTelescript.saveButtonText, children: "Update Preferences" }) }) })] }) })] })) })] }));
}; // end OptinAccordion
export default OptinAccordian;
