import React from 'react';
import { ContentBox, ContentBoxHead, ContentBoxBody, IfLoggedIn, IfLoggedOut } from 'sg-ui-components';
import { Link } from 'react-router-dom';

import EnterTicketTabs from './EnterTicketTabs';
import UserLockout from '../../utils/user_lockout';

const HomeEnterTickets = ({ user }) => {
    return (
        <div>
            <IfLoggedIn user={user}>
                <UserLockout>
                    <EnterTicketTabs />
                </UserLockout>
            </IfLoggedIn>
            <IfLoggedOut user={user}>
                <ContentBox variant='theme-blue'>
                    <ContentBoxHead>Enter Tickets</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='enter-logged-out text-center'>
                            <h3>Login or Sign-up to Enter Tickets</h3>
                            <div className='line-under'>
                                Enter any eligible Scratchers and Draw Games tickets to earn Rewards and promotional drawing entries.
                            </div>
                            <div className='learn-more'>
                                <p className='mb-1'>
                                    What is <em>My Lottery</em> Players Club?
                                </p>
                                <Link to='/feedback' className='btn theme-btn theme-secondary'>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </IfLoggedOut>
        </div>
    );
};

export { HomeEnterTickets };
