import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export var IfAndroid = function (_a) {
    var children = _a.children;
    var userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return null;
    }
};
export var IfIos = function (_a) {
    var children = _a.children;
    var userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return null;
    }
};
