import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import AlertBox from '../../Components/AlertBox/AlertBox';
import getCMSObject from '../../utils/getCMSObject';
var isObject = function (value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
};
var IndexedData = function (data, val) {
    if ((data === null || data === void 0 ? void 0 : data[val]) != null) {
        return data[val];
    }
    else {
        return null;
    }
};
var interpolateDataIntoTemplate = function (template, values) {
    return template.replace(/<(\w+)>/g, function (_, key) { return (values === null || values === void 0 ? void 0 : values[key]) || "<".concat(key, ">"); });
};
/**
 * A horizontal colored box used to indicate an error occured.
 */
var ErrorMessage = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.code, code = _f === void 0 ? '' : _f, _g = _a.collection, collection = _g === void 0 ? '' : _g, _h = _a.replace, replace = _h === void 0 ? {} : _h, _j = _a.mode, mode = _j === void 0 ? 'render' : _j;
    if (code === '')
        return null;
    var dictionary = isObject(collection) ? collection : (_b = getCMSObject(String(collection))) !== null && _b !== void 0 ? _b : {};
    var translatedMessage = (_c = IndexedData(dictionary, code)) !== null && _c !== void 0 ? _c : null;
    // ? We need to allow one more level error code check to resolve error code refernces
    var isErrorCodeAgain = /^([A-Z_]+)$/;
    if (isErrorCodeAgain.test(String(translatedMessage))) {
        translatedMessage = (_d = IndexedData(dictionary, String(translatedMessage))) !== null && _d !== void 0 ? _d : null;
    }
    var message = translatedMessage !== null && translatedMessage !== void 0 ? translatedMessage : code;
    if (replace && ((_e = Object.keys(replace)) === null || _e === void 0 ? void 0 : _e.length)) {
        message = interpolateDataIntoTemplate(String(message), replace);
    }
    if (mode === 'return')
        return _jsx(_Fragment, { children: message });
    return _jsx(AlertBox, { message: String(message) });
};
export default ErrorMessage;
