import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { PointsForDrawingsList, getCMSObject } from 'sg-ui-components';
import { FaTicketAlt, FaWallet, FaRegClock, FaExclamationTriangle } from 'react-icons/fa';

const Drawings = ({ user, config, cmsSourceFirebase, actions, pointsForDrawings, match }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    useEffect(() => {
        async function fetchData() {
            await actions?.pointsForDrawingsActions?.getPointsForDrawings();
            setPageLoaded(true);
        }

        fetchData();
    }, [user.loggedIn, setPageLoaded]);
    const filterDrawingsByCategory = (drawings, category) => drawings?.filter((item) => item.category === category);
    const getTelescriptItem = (key) => getCMSObject(`data.components.teleScripts.${key}.jsonBlock`);

    const commonOptions = {
        pointIcon: <FaWallet className='pfd-point-icon' size={16} />,
        entryIcon: <FaTicketAlt className='pfd-entry-icon' size={18} />,
        hasCollapser: false,
    };

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <PointsForDrawingsList
                config={config}
                action={actions}
                user={user}
                mode={'show-limited'}
                data={filterDrawingsByCategory(pointsForDrawings.pointsForDrawings, 'current')}
                pageLoaded={pageLoaded}
                telescript={getTelescriptItem('currentPointsForDrawings')}
                drawings={pointsForDrawings}
                options={{
                    ...commonOptions,
                    variant: 'theme-primary current-pfd',
                    dateIcon: <FaRegClock className='pfd-date-icon' size={18} />,
                }}
            />
            <PointsForDrawingsList
                config={config}
                action={actions}
                user={user}
                mode={'show-limited'}
                data={filterDrawingsByCategory(pointsForDrawings.pointsForDrawings, 'expired')}
                pageLoaded={pageLoaded}
                telescript={getTelescriptItem('pastPointsForDrawings')}
                drawings={pointsForDrawings}
                hideLoadingAndEmptyStates={true}
                options={{
                    ...commonOptions,
                    variant: 'theme-primary',
                    exclamationDateIcon: <FaExclamationTriangle className='pfd-date-icon' size={18} />,
                }}
            />
            <PointsForDrawingsList
                config={config}
                action={actions}
                user={user}
                mode={'show-limited'}
                data={filterDrawingsByCategory(pointsForDrawings.pointsForDrawings, 'future')}
                pageLoaded={pageLoaded}
                telescript={getTelescriptItem('futurePointsForDrawings')}
                drawings={pointsForDrawings}
                hideLoadingAndEmptyStates={true}
                options={{
                    ...commonOptions,
                    variant: 'theme-primary',
                    dateIcon: <FaRegClock className='pfd-date-icon' size={18} />,
                }}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawings);
