import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import ExternalPromotions from '../Promotions/ExternalPromotions';

import { ContentBox, ContentBoxHead, ContentBoxBody } from 'sg-ui-components';

const LotteryPromotions = () => {
    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>Promotions</ContentBoxHead>
            <ContentBoxBody>
                <ExternalPromotions />
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryPromotions);
