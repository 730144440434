import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { TeleScript, Gauges } from '../../../index';
var PromotionCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var config = _a.config, promotion = _a.promotion, _l = _a.jurisdiction, jurisdiction = _l === void 0 ? '' : _l, _m = _a.showNoLimit, showNoLimit = _m === void 0 ? false : _m, telescript = _a.telescript;
    // construct Expiration date from EPOCH timestamp.
    var promotionExpirationDate = new Date(((_b = promotion.expiration) !== null && _b !== void 0 ? _b : 0) * 1000);
    var promotionExpirationDateString = promotionExpirationDate.toLocaleString('en-US', { dateStyle: 'medium' });
    var imgUrl = (config.config.image_host && (config.config.image_host.startsWith('https://') ? config.config.image_host : "https://".concat(config.config.image_host))) ||
        '';
    var juristiction = typeof jurisdiction === 'string' ? jurisdiction.toLowerCase() : '';
    // In new versions of bonusing, images are stored in the promotion "extra" content, in older versions
    // the front-end needed to construct the image via the promotion ID and config variables for the image server
    //
    var promotionImg = (_d = (_c = promotion === null || promotion === void 0 ? void 0 : promotion.extra) === null || _c === void 0 ? void 0 : _c.thumb) !== null && _d !== void 0 ? _d : imgUrl + '/' + juristiction + '/promotion_' + ((_e = promotion.promotion_id) !== null && _e !== void 0 ? _e : '') + '_thumb.png';
    return (_jsx(Col, { lg: 4, className: 'reward-card', children: _jsxs(Card, { className: 'promotion-card', children: [_jsx(Card.Img, { variant: 'top', src: promotionImg, alt: promotion.name, className: 'img-fluid' }), _jsxs(Card.Body, { className: 'promotion-body', children: [_jsx(Gauges, { gauges: promotion.gauges }), _jsx(Card.Title, { className: 'promotion-title', children: promotion.name }), _jsx(Card.Text, { className: 'promotion-description', children: _jsx("span", { dangerouslySetInnerHTML: { __html: (_g = (_f = promotion.extra) === null || _f === void 0 ? void 0 : _f.short_description) !== null && _g !== void 0 ? _g : '' } }) })] }), _jsxs("div", { className: 'more-info', children: [_jsxs("div", { className: 'col-6', children: [promotion.limit_player > 0 ? (_jsxs("p", { children: [(_h = telescript === null || telescript === void 0 ? void 0 : telescript.limit) !== null && _h !== void 0 ? _h : 'Limit:', " ", promotion.limit_player] })) : showNoLimit ? (_jsxs("p", { children: [(_j = telescript === null || telescript === void 0 ? void 0 : telescript.limit) !== null && _j !== void 0 ? _j : 'Limit:', " ", (_k = telescript === null || telescript === void 0 ? void 0 : telescript.noLimit) !== null && _k !== void 0 ? _k : 'No Limit'] })) : (''), _jsxs("p", { children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.expires, children: "Expires:" }), _jsx("br", {}), " ", promotionExpirationDateString] })] }), _jsx("div", { className: 'more-link col-6', children: _jsxs(Link, { to: "/promotion?promotion=".concat(promotion.promotion_id), children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.more_info, children: "More Info" }), " ", _jsx(FontAwesomeIcon, { icon: faAngleRight, className: 'more-link-icon' })] }) })] })] }) }));
};
export default PromotionCard;
