import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { TeleScript, BoxedContent } from '../../../index';
var HomeEnterTickets = function (_a) {
    var _b;
    var user = _a.user, _c = _a.telescript, telescript = _c === void 0 ? {} : _c, children = _a.children;
    if ((user === null || user === void 0 ? void 0 : user.loggedIn) === true) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return (_jsx("div", { id: 'enter-enter-ticket-container', children: _jsx(BoxedContent, { hash: 'enter-tickets', title: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.title) !== null && _b !== void 0 ? _b : 'Enter Tickets', hasCollapser: false, children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.message, children: _jsxs("div", { className: 'enter-logged-out text-center', children: [_jsx("h3", { children: "Login or Sign-up to Enter Tickets" }), _jsx("div", { className: 'line-under', children: "Enter any eligible tickets to earn entries." }), _jsxs("div", { className: 'learn-more', children: [_jsx("p", { className: 'mb-1', children: "Need more info?" }), _jsx(Link, { to: '/feedback', className: 'btn theme-btn theme-secondary', children: "Learn More" })] })] }) }) }) }));
    }
};
export default HomeEnterTickets;
