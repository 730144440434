import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';

const NumberSelector = ({ value, max, label, parentCallback, min = 0 }) => {
    const [num, setNum] = useState(value);
    const inc = () => {
        if (num < max) {
            setNum(Number(num) + 1);
            parentCallback(num + 1);
        }
    };
    const dec = () => {
        if (num > min) {
            setNum(num - 1);
            parentCallback(num - 1);
        }
    };
    const handleChange = (e) => {
        setNum(e.target.value);
    };

    return (
        <div className='number-entries-field'>
            <InputGroup>
                <InputGroup.Prepend>
                    <button className='btn btn-primary decrement' type='button' onClick={dec}>
                        -
                    </button>
                </InputGroup.Prepend>
                <FormControl value={num} onChange={() => handleChange} min={min} max={max} disabled={true} />
                <InputGroup.Prepend>
                    <button className='btn btn-primary increment' type='button' onClick={inc}>
                        +
                    </button>
                </InputGroup.Prepend>
            </InputGroup>
            <p className='numbers-entry-label'>{label}</p>
        </div>
    );
};
export default NumberSelector;
