import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
var NumberSelector = function (_a) {
    var _b = _a.value, value = _b === void 0 ? 0 : _b, _c = _a.min, min = _c === void 0 ? 0 : _c, _d = _a.max, max = _d === void 0 ? 0 : _d, _e = _a.label, label = _e === void 0 ? '' : _e, parentCallback = _a.parentCallback;
    var _f = useState(value), num = _f[0], setNum = _f[1];
    var inc = function () {
        if (num < max) {
            setNum(Number(num) + 1);
            parentCallback(num + 1);
        }
    };
    var dec = function () {
        if (num > min) {
            setNum(num - 1);
            parentCallback(num - 1);
        }
    };
    var handleChange = function (e) {
        setNum(e.target.value); // TODO: Convert the value to a number
    };
    return (_jsxs("div", { className: 'number-entries-field', children: [_jsxs(InputGroup, { children: [_jsx(InputGroup.Prepend, { children: _jsx("button", { className: 'btn btn-primary decrement', type: 'button', onClick: dec, children: "-" }) }), _jsx(FormControl, { value: num, onChange: function () { return handleChange; }, min: min, max: max, disabled: true }), _jsx(InputGroup.Prepend, { children: _jsx("button", { className: 'btn btn-primary increment', type: 'button', onClick: inc, children: "+" }) })] }), _jsx("p", { className: 'numbers-entry-label', children: label })] }));
};
export default NumberSelector;
