import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentBox, ContentBoxHead, ContentCollapser, ContentBoxBody } from '../ContentBox/ContentBox';
import { CMSContent } from '../CMSContent/CMSContent';
import isHash from '../../utils/isHash';
var DrawNumbersExample = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    return (_jsxs(_Fragment, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findDrawNumbers.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findDrawNumbers.image', className: 'img-fluid mx-auto d-block', cmsSourceFirebase: cmsSourceFirebase })] }));
};
var FindDrawNumbers = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    var hash = 'draw_ticket_sample';
    return (_jsxs(ContentBox, { variant: 'theme-primary', id: hash, show: isHash(hash), children: [_jsxs(ContentBoxHead, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findDrawNumbers.contentHeaderText', cmsSourceFirebase: cmsSourceFirebase }), _jsx(ContentCollapser, { children: undefined })] }), _jsx(ContentBoxBody, { children: _jsx(DrawNumbersExample, { cmsSourceFirebase: cmsSourceFirebase }) })] }));
};
/**
 * Displays a ContentBox of a header, content, and image. Typically used for instructions on how to enter a draw number. Can also be used in isolation without the ContentBox by using `DrawNumbersExample`.
 */
export default {
    DrawNumbersExample: DrawNumbersExample,
    FindDrawNumbers: FindDrawNumbers,
};
