import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { RafForm, RafLink, ShareRafCode } from '../Components/ReferAFriend/RafForm';
import { RafPage } from '../Components/ReferAFriend';
import Layout from '../Components/Layout';

const ReferAFriendPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <RafPage>
                <RafForm>
                    {/*
                    <RafErrorCard/>
                    <RafCode />
                    */}
                    <RafLink />
                    <ShareRafCode />
                </RafForm>
            </RafPage>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriendPage);
