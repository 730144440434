import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentBox, ContentBoxHead, ContentCollapser, ContentBoxBody } from '../ContentBox/ContentBox';
import isHash from '../../utils/isHash';
/**
 * A simple component what could help us to create content box based sections.
 */
var BoxedContent = function (_a) {
    var hash = _a.hash, _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.variant, variant = _c === void 0 ? 'theme-primary' : _c, _d = _a.hasCollapser, hasCollapser = _d === void 0 ? true : _d, _e = _a.isActive, isActive = _e === void 0 ? true : _e, _f = _a.isVisible, isVisible = _f === void 0 ? true : _f, children = _a.children;
    if (!isVisible)
        return null;
    return (_jsxs(ContentBox, { id: hash, show: isHash(hash) || !hasCollapser || isActive ? 'true' : 'false', variant: variant, children: [_jsxs(ContentBoxHead, { children: [typeof title === 'string' ? _jsx("div", { dangerouslySetInnerHTML: { __html: String(title) } }) : title, hasCollapser === true ? _jsx(ContentCollapser, {}) : ''] }), _jsx(ContentBoxBody, { children: children })] }));
};
export default BoxedContent;
