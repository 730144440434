import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ProgressBar } from 'react-bootstrap';
/**
 * An array of progress displays.
 */
var Gauges = function (_a) {
    var gauges = _a.gauges;
    return (_jsx(_Fragment, { children: gauges.map(function (gauge, index) {
            var formattedGauge = "".concat(gauge.balance, " of ").concat(gauge.trigger);
            var completed = Math.floor((gauge.balance / gauge.trigger) * 100);
            if (gauge.triggered_count > 0) {
                completed = 100;
            }
            switch (gauge.format) {
                case 'integer':
                    formattedGauge = gauge.triggered_count > 0 ? "".concat(gauge.trigger, " of ").concat(gauge.trigger) : "".concat(gauge.balance, " of ").concat(gauge.trigger);
                    break;
                case 'pence':
                    formattedGauge =
                        gauge.triggered_count > 0 ? "$".concat(gauge.trigger / 100, " of $").concat(gauge.trigger / 100) : "$".concat(gauge.balance / 100, " of $").concat(gauge.trigger / 100);
                    break;
                default:
                    formattedGauge = "".concat(completed, "%");
                    break;
            }
            return (_jsxs("div", { className: 'gauge', children: [_jsx(ProgressBar, { now: completed, className: "gauge_".concat(index, " ") }), _jsxs("p", { className: 'bar-label', children: [_jsx("span", { dangerouslySetInnerHTML: { __html: formattedGauge } }), " ", _jsx("span", { dangerouslySetInnerHTML: { __html: gauge.description } })] })] }, index));
        }) }));
};
export default Gauges;
