var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
export var defaultPageState = {
    currentSite: '',
    pageInfo: [],
    copyInfo: [],
    styleInfo: {},
    headerInfo: null,
    footerInfo: null,
    jurisdictionInfo: {},
};
// REDUCERS
// basic actions that affect this object only
var getPageDataReducer = function (state, action) {
    return state;
};
var resetReducer = function (state, action) {
    return __assign({}, defaultPageState);
};
var setPageDataReducer = function (state, action) {
    return __assign(__assign({}, state), { pageInfo: action.payload });
};
var setCurrentSiteReducer = function (state, action) {
    return __assign(__assign({}, state), { currentSite: action.payload });
};
var setCopyDataReducer = function (state, action) {
    return __assign(__assign({}, state), { copyInfo: action.payload });
};
var setStyleDataReducer = function (state, action) {
    return __assign(__assign({}, state), { styleInfo: action.payload });
};
var setHeaderDataReducer = function (state, action) {
    return __assign(__assign({}, state), { headerInfo: action.payload });
};
var setFooterDataReducer = function (state, action) {
    return __assign(__assign({}, state), { footerInfo: action.payload });
};
var setJurisdictionDataReducer = function (state, action) {
    return __assign(__assign({}, state), { jurisdictionInfo: action.payload });
};
export var pageDataSlice = createSlice({
    name: 'pageData',
    initialState: defaultPageState,
    reducers: {
        getPageData: getPageDataReducer,
        setPageData: setPageDataReducer,
        setCopyData: setCopyDataReducer,
        setStyleData: setStyleDataReducer,
        setHeaderData: setHeaderDataReducer,
        setFooterData: setFooterDataReducer,
        setJurisdictionData: setJurisdictionDataReducer,
        setCurrentSite: setCurrentSiteReducer,
        reset: resetReducer,
    },
});
//ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getPageData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, data, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        db = window.localStorage;
        res = db.getItem(promotionConfig.promotion + '_pages');
        data = JSON.parse(res);
        pages = JSON.parse(data.body);
        // Set Pages
        console.log(pages);
        dispatch(pageDataSlice.actions.setPageData(pages));
        dispatch(pageDataSlice.actions.setCurrentSite(payload.microsite_name));
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
var getCopyData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, response, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        console.log('Getting page data');
        db = window.localStorage;
        res = db.getItem(promotionConfig.promotion + '_copy');
        response = JSON.parse(res);
        pages = response;
        dispatch(pageDataSlice.actions.setCopyData(pages));
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
var getStyleData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, data, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        console.log('Getting style data');
        db = window.localStorage;
        res = db.getItem(promotionConfig.promotion + '_style');
        if (res) {
            data = JSON.parse(res);
            pages = JSON.parse(data.body);
            dispatch(pageDataSlice.actions.setStyleData(pages));
        }
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
var getHeaderData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, data, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        console.log('Getting header data');
        db = window.localStorage;
        res = db.getItem(promotionConfig.promotion + '_header');
        if (res) {
            data = JSON.parse(res);
            pages = data.body;
            dispatch(pageDataSlice.actions.setHeaderData(pages));
        }
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
var getFooterData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, data, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        console.log('Getting footer data');
        db = window.localStorage;
        res = db.getItem(promotionConfig.promotion + '_footer');
        if (res) {
            data = JSON.parse(res);
            pages = data.body;
            dispatch(pageDataSlice.actions.setFooterData(pages));
        }
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
var getJurisdictionData = function (dispatch, payload, promotionConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var db, res, data, pages;
    return __generator(this, function (_a) {
        dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));
        console.log('Getting jurisdiction data');
        db = window.localStorage;
        res = db.getItem('jurisdiction_data');
        data = JSON.parse(res);
        pages = JSON.parse(data.body);
        dispatch(pageDataSlice.actions.setJurisdictionData(pages));
        dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
        return [2 /*return*/];
    });
}); };
//actions that get exported to the views
export var pageDataActions = function (dispatch, promotionConfig) {
    return {
        getPageData: function (payload, promotionConfig) { return getPageData(dispatch, payload, promotionConfig); },
        getCopyData: function (payload, promotionConfig) { return getCopyData(dispatch, payload, promotionConfig); },
        getStyleData: function (payload, promotionConfig) { return getStyleData(dispatch, payload, promotionConfig); },
        getHeaderData: function (payload, promotionConfig) { return getHeaderData(dispatch, payload, promotionConfig); },
        getFooterData: function (payload, promotionConfig) { return getFooterData(dispatch, payload, promotionConfig); },
        getJurisdictionData: function (payload, promotionConfig) { return getJurisdictionData(dispatch, payload, promotionConfig); },
        reset: function (payload) { return dispatch(pageDataSlice.actions.reset(payload)); },
    };
};
