import { useCallback, useEffect, useState } from 'react';
var THRESHOLD = 1;
function useIsScrollComplete(_a) {
    var ref = _a.ref, querySelector = _a.querySelector, _b = _a.markAsComplete, markAsComplete = _b === void 0 ? true : _b;
    var _c = useState(false), isScrollComplete = _c[0], setIsScrollComplete = _c[1];
    var onScroll = useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        var _b = currentTarget, scrollHeight = _b.scrollHeight, clientHeight = _b.clientHeight, scrollTop = _b.scrollTop;
        if (Math.abs(scrollHeight - clientHeight - scrollTop) < THRESHOLD) {
            setIsScrollComplete(true);
        }
        else {
            setIsScrollComplete(false);
        }
    }, []);
    useEffect(function () {
        var element = ref.current;
        var targetElement = querySelector ? element === null || element === void 0 ? void 0 : element.querySelector(querySelector) : element;
        if (targetElement) {
            var scrollHeight = targetElement.scrollHeight, clientHeight = targetElement.clientHeight;
            if (scrollHeight === clientHeight) {
                // set scroll is complete if there is no scroll
                setIsScrollComplete(true);
            }
            targetElement.addEventListener('scroll', onScroll);
            if (isScrollComplete && markAsComplete) {
                targetElement.removeEventListener('scroll', onScroll);
            }
        }
        return function () {
            if (targetElement) {
                targetElement.removeEventListener('scroll', onScroll);
            }
        };
    }, [isScrollComplete, markAsComplete, onScroll, querySelector, ref]);
    return { isScrollComplete: isScrollComplete };
}
export default useIsScrollComplete;
